<script>
import { omit } from 'lodash';
import { loadScript } from '@/utils';
import { mapState } from 'vuex';

import countries from '@/services/countries.js';
const GOOGLE_MAPS_KEY = process.env.VUE_APP_GOOGLE_MAPS_KEY;

function extractAddressComponents(components, address, countryHasStates = false) {
  components.forEach((component) => {
    if (component.types.includes('street_number')) {
      address.line1 = component.long_name;
    }
    if (component.types.includes('route')) {
      address.line1 += ` ${component.long_name}`;
    }
    if (component.types.includes('locality')) {
      address.city = component.long_name;
    }
    if (countryHasStates && component.types.includes('administrative_area_level_1')) {
      address.state = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      address.zip = component.long_name;
    }
  });
  return address;
}

export default {
  name: 'AddressAutocomplete',
  data: () => ({
    countries,
    search: '',
    address: {
      country: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: ''
    },
    expandFields: false,
    googleMapsLoader: null,
    placesInstance: null,
    addressInput: null,
    isAutoFilled: false
  }),

  computed: {
    ...mapState('campaign', ['settings']),
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapState('publicOrgSettings', ['suggestedAddressCountry']),
    countriesFiltered() {
      return this.countries.filter((country) => country.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    countryHasStates() {
      const countriesWithStates = ['US', 'CA', 'AU'];
      return countriesWithStates.includes(this.address.country);
    }
  },

  watch: {
    address: {
      handler(newValue) {
        this.$emit('update', newValue);
      },
      deep: true
    }
  },

  methods: {
    // Google maps
    countryChanged(country) {
      this.address.country = country.code;
      if (!this.countryHasStates) {
        this.address.state = '';
      }

      if (this.placesInstance) {
        this.placesInstance.setComponentRestrictions({ country: country.code });
      }
    },

    loadGMaps() {
      if (!GOOGLE_MAPS_KEY) {
        console.warn('Google Maps API key not found');
        return;
      }
      const iframe = document.querySelector('iframe[name="double-checkout"]');
      const iframeHead = iframe.contentDocument.head;
      loadScript('https://unpkg.com/@googlemaps/js-api-loader@1.0.0/dist/index.min.js', { target: iframeHead }, () => {
        const Loader = iframe.contentWindow.google.maps.plugins.loader.Loader;
        this.googleMapsLoader = new Loader({
          apiKey: GOOGLE_MAPS_KEY,
          version: 'weekly',
          libraries: ['places']
        });
        this.addressInput = this.$refs['address-autocomplete-field'];
        if (this.addressInput) {
          this.googleMapsLoader.load().then(() => {
            const iframe = document.querySelector('iframe[name="double-checkout"]');
            const google = iframe.contentWindow.google;

            this.placesInstance = new google.maps.places.Autocomplete(this.addressInput.$el.querySelector('input'), {
              types: ['geocode'],
              fields: ['address_components']
            });

            google.maps.event.addListener(this.placesInstance, 'place_changed', () => {
              const object = this.placesInstance.getPlace(); // contains a Google object for selected address.
              extractAddressComponents(object.address_components, this.address, this.countryHasStates);
              this.expandFields = true;
            });

            google.maps.event.trigger(this.addressInput.$el.querySelector('input'), 'focus', {});
          });
        }
      });
    }
  },
  mounted() {
    const isBillingAddressEmpty = Object.values(omit(this.donation.billingAddress, ['country'])).every((v) => !v);
    if (!isBillingAddressEmpty) {
      this.address = this.donation.billingAddress;
      return (this.expandFields = true);
    }

    this.loadGMaps();

    if (!this.address.country) {
      this.address.country = this.suggestedAddressCountry || 'US';
    }

    // Hack to detect autofill
    this.$refs['address-autocomplete-field'].$el.addEventListener('animationstart', async (e) => {
      if (e.animationName === 'onAutoFillStart') {
        // copy value to address line 1
        await this.$nextTick();
        this.address.line1 = this.$refs['address-autocomplete-field'].value;
        this.expandFields = true;
      }
    });
  }
};
</script>

<template>
  <div class="flex flex-col gap-3">
    <app-select
      :disabled="!placesInstance && !expandFields"
      :title="!placesInstance && !expandFields ? 'Loading. Please wait...' : null"
      @input="countryChanged"
      @search="(query) => (search = query)"
      :filterable="false"
      :options="countriesFiltered"
      name="country"
      autocomplete="country"
      placeholder="Country"
      :value="countries.find((c) => c.code === address.country) || {}"
    />
    <!-- This is the address search field -->
    <formulate-input
      v-if="!expandFields"
      key="address-autocomplete"
      :validation="settings.optionalFields.address.required ? 'required' : false"
      name="Address"
      @focusout="(e) => (e.target.value ? (expandFields = true) : null)"
      ref="address-autocomplete-field"
      :placeholder="$t('payment.address')"
    />
    <formulate-input
      v-if="expandFields"
      v-model="address.line1"
      :validation="settings.optionalFields.address.required ? 'required' : false"
      type="text"
      name="address"
      :placeholder="$t('payment.address1')"
    />
    <transition name="expandx2">
      <div v-if="expandFields" class="flex flex-col gap-3">
        <formulate-input v-model="address.line2" type="text" name="address" class="w-full" :placeholder="$t('payment.address2')" />
        <div class="flex gap-3">
          <formulate-input
            v-model="address.city"
            type="text"
            name="city"
            class="w-full"
            :placeholder="$t('payment.city')"
            :validation="settings.optionalFields.address.required ? 'required' : false"
          />
          <formulate-input
            v-if="countryHasStates"
            v-model="address.state"
            type="text"
            name="state"
            class="w-full"
            :placeholder="$t('payment.state')"
            :validation="settings.optionalFields.address.required ? 'required' : false"
          />
          <formulate-input
            v-model="address.zip"
            type="text"
            name="zip"
            class="w-full"
            :placeholder="$t('payment.zip')"
            :validation="settings.optionalFields.address.required ? 'required' : false"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
