<script>
export default {
  name: 'DoubleLogo',
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator(value) {
        return ['dark', 'light', 'grayscale'].includes(value);
      }
    },
    isLink: {
      type: Boolean,
      default: true
    },
    securityBadge: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    fill() {
      if (this.theme === 'grayscale') {
        return 'var(--gray)';
      }

      const fill = {
        dark: '#2C3381',
        light: '#fff'
      };
      return fill[this.theme] || fill['dark'];
    }
  }
};
</script>

<template>
  <div class="double-logo flex items-center justify-center gap-1">
    <template v-if="securityBadge">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame" clip-path="url(#clip0_8442_6296)">
          <path
            id="Vector"
            d="M3.18845 2.5V3.75H6.40274V2.5C6.40274 1.63672 5.68354 0.9375 4.79559 0.9375C3.90765 0.9375 3.18845 1.63672 3.18845 2.5ZM2.22416 3.75V2.5C2.22416 1.11914 3.37528 0 4.79559 0C6.21591 0 7.36702 1.11914 7.36702 2.5V3.75H8.00988C8.71903 3.75 9.29559 4.31055 9.29559 5V8.75C9.29559 9.43945 8.71903 10 8.00988 10H1.58131C0.872156 10 0.295593 9.43945 0.295593 8.75V5C0.295593 4.31055 0.872156 3.75 1.58131 3.75H2.22416ZM1.25988 5V8.75C1.25988 8.92188 1.40452 9.0625 1.58131 9.0625H8.00988C8.18666 9.0625 8.33131 8.92188 8.33131 8.75V5C8.33131 4.82812 8.18666 4.6875 8.00988 4.6875H1.58131C1.40452 4.6875 1.25988 4.82812 1.25988 5Z"
            fill="#B1B1BF"
          />
        </g>
        <defs>
          <clipPath id="clip0_8442_6296">
            <rect width="9" height="10" fill="white" transform="translate(0.295593)" />
          </clipPath>
        </defs>
      </svg>
    </template>

    <span class="text-xs text-gray font-normal">Secured by</span>
    <span :is="isLink ? 'a' : 'span'" href="https://double.giving" target="_blank" class="mt-0.5">
      <svg
        class="double-logo"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="44"
        viewBox="0 0 250 74.35"
        style="enable-background: new 0 0 250 74.35"
        xml:space="preserve"
      >
        <g>
          <path
            :fill="fill"
            d="M203.54,50.22h-1.84c-1.08,0-1.96-0.59-2.64-1.76s-1.03-2.67-1.03-4.48V5.58c0-1.07-0.34-1.95-1.03-2.64
		c-0.69-0.68-1.57-1.03-2.64-1.03c-1.08,0-1.96,0.34-2.64,1.03c-0.68,0.69-1.03,1.57-1.03,2.64v38.4c0,2.64,0.46,4.98,1.39,7
		c0.93,2.03,2.23,3.63,3.89,4.81c1.66,1.17,3.57,1.76,5.72,1.76h0.15c1.46,0,2.66-0.34,3.59-1.03c0.93-0.69,1.39-1.57,1.39-2.64
		c0-1.07-0.31-1.95-0.92-2.64C205.32,50.57,204.52,50.22,203.54,50.22z"
          />
          <path
            :fill="fill"
            d="M36.67,1.93c-1.08,0-1.97,0.34-2.68,1.03c-0.71,0.69-1.06,1.59-1.06,2.71v17.86
		c-1.66-2.05-3.72-3.68-6.16-4.88c-2.45-1.2-5.11-1.8-8-1.8c-3.57,0-6.77,0.89-9.61,2.68c-2.84,1.79-5.07,4.21-6.71,7.26
		C0.82,29.85,0,33.31,0,37.17s0.89,7.33,2.68,10.41c1.79,3.08,4.2,5.52,7.26,7.3c3.06,1.79,6.49,2.68,10.3,2.68
		s7.24-0.89,10.27-2.68c3.03-1.78,5.44-4.22,7.22-7.3c1.78-3.08,2.68-6.55,2.68-10.41V5.67c0-1.12-0.34-2.03-1.03-2.71
		C38.7,2.27,37.8,1.93,36.67,1.93z M31.54,44.21c-1.12,2.1-2.67,3.75-4.62,4.95c-1.96,1.2-4.18,1.8-6.68,1.8
		c-2.45,0-4.66-0.6-6.64-1.8c-1.98-1.2-3.55-2.85-4.7-4.95c-1.15-2.1-1.72-4.45-1.72-7.04c0-2.64,0.58-4.99,1.72-7.04
		c1.15-2.05,2.71-3.68,4.7-4.88c1.98-1.2,4.19-1.8,6.64-1.8c2.49,0,4.72,0.6,6.68,1.8c1.95,1.2,3.5,2.82,4.62,4.88
		c1.13,2.05,1.69,4.4,1.69,7.04C33.23,39.76,32.66,42.11,31.54,44.21z"
          />
          <path
            :fill="fill"
            d="M143.43,2.83c-0.68,0.69-1.03,1.59-1.03,2.71v31.61c0,3.86,0.89,7.33,2.68,10.41c1.79,3.08,4.19,5.52,7.22,7.3
		c3.03,1.79,6.46,2.68,10.27,2.68c3.81,0,7.25-0.89,10.3-2.68c3.06-1.78,5.48-4.22,7.26-7.3c1.79-3.08,2.68-6.55,2.68-10.41
		s-0.82-7.32-2.46-10.38c-1.64-3.06-3.88-5.48-6.71-7.26c-2.84-1.79-6.04-2.68-9.61-2.68c-2.88,0-5.55,0.6-8,1.8
		c-2.45,1.2-4.5,2.83-6.16,4.88V5.54c0-1.12-0.35-2.03-1.06-2.71c-0.71-0.68-1.6-1.03-2.68-1.03C145.02,1.8,144.12,2.14,143.43,2.83
		z M149.59,37.16c0-2.64,0.56-4.99,1.69-7.04c1.12-2.05,2.67-3.68,4.62-4.88c1.96-1.2,4.18-1.8,6.68-1.8c2.45,0,4.66,0.6,6.64,1.8
		c1.98,1.2,3.55,2.82,4.7,4.88c1.15,2.05,1.72,4.4,1.72,7.04c0,2.59-0.58,4.94-1.72,7.04c-1.15,2.1-2.71,3.75-4.7,4.95
		c-1.98,1.2-4.19,1.8-6.64,1.8c-2.49,0-4.72-0.6-6.68-1.8c-1.95-1.2-3.5-2.85-4.62-4.95C150.15,42.09,149.59,39.75,149.59,37.16z"
          />
          <path
            :fill="fill"
            d="M68.73,16.56c-11.32,0-20.5,9.18-20.5,20.5c0,11.32,9.18,20.5,20.5,20.5s20.5-9.18,20.5-20.5
		C89.23,25.74,80.05,16.56,68.73,16.56z M68.73,50.08c-7.18,0-13.02-5.84-13.02-13.02c0-7.18,5.84-13.02,13.02-13.02
		s13.02,5.84,13.02,13.02C81.75,44.24,75.91,50.08,68.73,50.08z"
          />
          <path
            :fill="fill"
            d="M105.41,55.41c2.67,1.44,5.68,2.16,9.06,2.16c3.23,0,6.14-0.71,8.73-2.13c1.41-0.77,2.65-1.7,3.74-2.77v0.98
		c0,1.12,0.34,2.03,1.03,2.71c0.69,0.68,1.59,1.03,2.71,1.03c1.08,0,1.97-0.34,2.68-1.03c0.71-0.69,1.06-1.59,1.06-2.71V20.15
		c0-1.08-0.35-1.97-1.06-2.68c-0.71-0.71-1.6-1.06-2.68-1.06c-1.12,0-2.03,0.35-2.71,1.06c-0.68,0.71-1.03,1.6-1.03,2.68v21.29
		c0,1.81-0.49,3.41-1.47,4.8c-0.98,1.39-2.3,2.5-3.96,3.34c-1.66,0.83-3.52,1.25-5.57,1.25c-2.25,0-4.24-0.43-5.98-1.28
		c-1.74-0.85-3.12-2.16-4.14-3.92c-1.03-1.76-1.54-4.03-1.54-6.82V20.15c0-1.08-0.35-1.97-1.06-2.68c-0.71-0.71-1.6-1.06-2.68-1.06
		c-1.07,0-1.97,0.35-2.68,1.06c-0.71,0.71-1.06,1.6-1.06,2.68V38.8c0,4.06,0.77,7.48,2.31,10.27
		C100.64,51.86,102.74,53.97,105.41,55.41z"
          />
          <path
            :fill="theme === 'grayscale' ? fill : '#e9266c'"
            d="M85.49,65.06H51.97c-2.07,0-3.74,1.67-3.74,3.74v0c0,2.07,1.67,3.74,3.74,3.74h33.52
		c2.07,0,3.74-1.67,3.74-3.74v0C89.23,66.74,87.56,65.06,85.49,65.06z"
          />
          <path
            :fill="fill"
            d="M249.79,34.13c-1.42-9.93-9.98-17.59-20.29-17.59c-10.31,0-18.87,7.66-20.29,17.59
		c-0.14,0.95-0.21,1.92-0.21,2.91c0,0.99,0.07,1.96,0.21,2.91c1.42,9.93,9.98,17.59,20.29,17.59c5.27,0,10.28-2,14.11-5.62
		c0.43-0.4,0.83-0.82,1.22-1.26c1.83-2.06,0.68-5.34-2.04-5.83h0c-1.15-0.21-2.33,0.16-3.16,1c-0.41,0.41-0.79,0.79-0.93,0.92
		c-2.49,2.37-5.76,3.67-9.2,3.67c-6.38,0-11.72-4.48-13.06-10.46h17.61h8.51h4.53c1.6,0,2.91-1.29,2.91-2.88c0-0.01,0-0.02,0-0.03
		C250,36.06,249.93,35.08,249.79,34.13z M216.44,34.13c1.33-5.98,6.68-10.46,13.06-10.46c6.38,0,11.72,4.48,13.06,10.46H216.44z"
          />
        </g>
      </svg>
    </span>
  </div>
</template>
