<script>
import { mapState, mapMutations } from 'vuex';

const GENERAL_DESIGNATION_LABEL = 'Where needed most';
export default {
  name: 'FormDesignation',
  computed: {
    ...mapState('campaign', ['settings']),
    ...mapState('donation', {
      donation: (state) => state
    }),

    designation: {
      get() {
        return this.donation.designation || GENERAL_DESIGNATION_LABEL;
      },
      set(value) {
        const designation = value.name === GENERAL_DESIGNATION_LABEL ? null : value.name;
        this.SET_DESIGNATION(designation);
      }
    },
    designations() {
      return [
        { name: GENERAL_DESIGNATION_LABEL },
        // should maybe be filtered on the server side?
        ...this.settings.designations.filter((designation) => !designation.disabled)
      ];
    },
    showDesignationSection() {
      return this.settings.showDesignations && this.settings.designations.length;
    }
  },
  methods: {
    ...mapMutations('donation', ['SET_DESIGNATION']),
    findDefaultDesignation() {
      if (!this.showDesignationSection) {
        return;
      }
      const defaultDesignation = this.settings.designations.find((designation) => designation.default);

      if (defaultDesignation && !defaultDesignation.disabled) {
        this.designation = defaultDesignation;
      }
    }
  },

  created() {
    this.findDefaultDesignation();
  }
};
</script>

<template>
  <section v-if="showDesignationSection" class="donation-designations">
    <h4 class="body-text-extra-bold">{{ $t('designation') }}</h4>
    <app-select v-model="designation" :options="designations" :searchable="false" />
  </section>
</template>
