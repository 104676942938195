import request from '@/services/request';
// import axios from 'axios';

export const state = {
  form: {}
};

export const mutations = {
  SET_FUNDRAISER_SIGNUP(state, newValue) {
    state.form = newValue;
  }
};

export const actions = {
  async fundraiserSignup({ state, rootState }, { avatar }) {
    const form = state.form;
    delete form._avatar; // Formulate adds it automatically because it's inside a form

    const orgId = rootState.orgId;
    const bodyFormData = new FormData();
    bodyFormData.append('fundraiser', JSON.stringify(form));
    bodyFormData.append('avatar', avatar);

    const { data, error } = await request.fileUpload('post', `/public/fundraiser/${orgId}`, bodyFormData);

    if (error) {
      throw new Error(data);
    }

    return data;
  }
};
