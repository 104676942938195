<script>
import { mapState, mapGetters } from 'vuex';
// import AppProgressbar from '@/common/AppProgressbar.vue';
import { getCurrencySymbol } from '@/utils';
import AppProgressbar from '@/common/AppProgressbar.vue';

export default {
  name: 'FundraiserStats',
  components: {
    AppProgressbar
    // AppProgressbar
  },

  data: () => ({
    getCurrencySymbol
  }),
  computed: {
    ...mapState('fundraiser', {
      fundraiser: (state) => state
    }),
    ...mapState('donation', ['orgDefaultCurrency']),
    ...mapState('campaign', ['settings']),
    ...mapGetters('fundraiser', ['fundraiserFullName']),
    progressPercentage() {
      const percentage = (100 / this.fundraiser.goal) * this.fundraiser.raised;
      return Math.min(percentage, 100);
    },
    avatarPath() {
      if (!this.fundraiser.avatarUrl) {
        return false;
      }
      return `${this.fundraiser.avatarUrl}?${Date.now()}`;
    }
  }
};
</script>

<template>
  <div class="fundraiser-stats my-4">
    <div class="stats text-white flex">
      <div class="avatar">
        <img v-if="avatarPath" :src="avatarPath" alt="Fundraiser avatar" />
        <app-icon v-else type="svg" width="56" name="avatar-placeholder" />
      </div>
      <div class="flex-1 flex flex-col justify-between">
        <h2>{{ fundraiserFullName }}</h2>
        <div class="flex-col subtext-regular">
          <span>Has raised </span>
          <span>{{ getCurrencySymbol(orgDefaultCurrency) }}</span>
          <span class="font-bold">{{ fundraiser.raised | currency }}</span>
          <span class="text-off-white"> / {{ getCurrencySymbol(orgDefaultCurrency) }}{{ fundraiser.goal | number }}</span>
        </div>

        <app-progressbar :value="progressPercentage" />
      </div>
    </div>
  </div>
</template>
