<script>
// https://forum.vuejs.org/t/render-inside-iframe/6419
// https://jsfiddle.net/rzuno6kv/

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import { loadScript, loadStyleLink, sleep } from '@/utils';
import StripeService from '@/services/stripe';
// import sentryInitialize from '@/services/sentry';
import dtdInitialize from '@/services/double-the-donation';
import trackEvent from '@/services/event-tracking';
import store from '@/store';
import localization from '@/services/localization';
import '@/common';

import nunito from '!!raw-loader!@/assets/fonts/nunito.css';
import css from '!!raw-loader!sass-loader!@/assets/css/checkout/index.scss';
import CheckoutLoader from '@/common/CheckoutLoader.vue';
// import { unparam } from '@/utils';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

Vue.use(VueI18n);
const i18n = new VueI18n(localization);

Vue.prototype.$trackEvent = trackEvent;

export default {
  render(h) {
    return h('iframe', {
      on: { load: this.renderChildren },
      attrs: {
        src: 'about:blank',
        name: 'double-checkout',
        title: 'Donation Widget',
        allowpaymentrequest: 'true',
        allow: 'payment',
        frameborder: 0,
        tabIndex: -1
      },
      style: this.iframeStyles
    });
  },

  data: () => ({
    iframeStyles: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    }
  }),

  computed: {
    ...mapState('publicOrgSettings', {
      globalSettings: (state) => state.settings
    }),
    ...mapState('campaign', ['settings']),
    ...mapGetters('campaign', ['campaignLoaded'])
  },

  watch: {
    async campaignLoaded(loaded) {
      if (!loaded) {
        return;
      }

      if (this.settings?.optionalFields?.phoneNumber?.collect) {
        this.loadTelLibrary();
      }

      if (this.settings.customQuestions.some((q) => q.type === 'date')) {
        this.loadDatePickerLibrary();
      }
    }
  },

  methods: {
    ...mapMutations(['SET_TEL_LIBRARY_LOADED', 'SET_CHARIOT_LOADED', 'SET_DATE_PICKER_LIBRARY_LOADED']),
    ...mapActions(['setWidgetOpen']),

    async renderChildren() {
      const children = this.$slots.default;
      const body = this.$el.contentDocument.body;
      const el = document.createElement('DIV'); // we will mount or nested app to this element
      body.appendChild(el);

      // load iframe scripts and styles
      const iframeHead = this.$el.contentDocument.head;
      loadScript('https://unpkg.com/ionicons@5.0.0/dist/ionicons/ionicons.js', {
        target: iframeHead,
        type: 'module'
      });
      loadScript('https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_ADMIN_GANALYTICS_ID, {
        target: iframeHead,
        type: null,
        isAsync: true
      });

      if (this.globalSettings.chariotCid) {
        loadScript('https://cdn.givechariot.com/chariot-connect.umd.js', {
          target: iframeHead,
          isAsync: true
        }).then(async () => {
          await sleep(300);
          this.SET_CHARIOT_LOADED(true);
        });
      }

      dtdInitialize(this.globalSettings.doubleTheDonation);
      const currentVueInstance = this; // reference to current vue instance
      const WidgetIframe = new Vue({
        name: 'WidgetIframe',
        store,
        i18n,
        //freezing to prevent unnessessary Reactifiation of vNodes
        data: { children: Object.freeze(children) },
        render(h) {
          if (currentVueInstance.campaignLoaded) {
            return h('div', this.children);
          } else {
            return h(CheckoutLoader);
          }
        }
      });

      WidgetIframe.$mount(el); // mount into iframe
      this.WidgetIframe = WidgetIframe; // cache instance for later updates

      // apply styles
      this.applyCss();
    },
    applyCss() {
      const iframe = this.$el.contentDocument;
      const styleElement = document.createElement('style');
      styleElement.textContent = css + nunito;
      iframe.head.appendChild(styleElement);
    },
    closeOnEsc(event) {
      if (event.key === 'Escape') {
        this.setWidgetOpen(false);
      }
    },

    async loadTelLibrary() {
      const iframeHead = this.$el.contentDocument.head;

      await Promise.all([
        loadScript('https://unpkg.com/vue-tel-input@legacy', {
          target: iframeHead
        }).then(() => {
          Vue.use(this.$el.contentWindow['vue-tel-input']);
        }),
        // Overridden styles are in assets/css/checkout/vue-tel-input.scss
        loadStyleLink('https://unpkg.com/vue-tel-input/dist/vue-tel-input.css', {
          target: iframeHead,
          rel: 'stylesheet'
        })
      ]);

      this.SET_TEL_LIBRARY_LOADED(true);
    },
    async loadDatePickerLibrary() {
      const iframeHead = this.$el.contentDocument.head;

      await Promise.all([
        loadScript('https://unpkg.com/@shayaulman/vuejs-datepicker@1.6.3-fork.2/dist/vuejs-datepicker.min.js', {
          target: iframeHead,
          isAsync: true
        }).then(() => Vue.component('date-picker', this.$el.contentWindow.vuejsDatepicker)),

        loadScript('https://unpkg.com/@shayaulman/vuejs-datepicker@1.6.3-fork.2/dist/locale/translations/en.js', {
          target: iframeHead
        }),

        loadScript('https://unpkg.com/@shayaulman/vuejs-datepicker@1.6.3-fork.2/dist/locale/translations/he.js', {
          target: iframeHead
        })
      ]);

      await sleep(300);
      this.SET_DATE_PICKER_LIBRARY_LOADED(true);
    }
  },

  created() {
    this.$trackEvent({ action: 'opened' });
  },

  mounted() {
    const iframe = this.$el.contentDocument;
    iframe.addEventListener('keydown', (event) => this.closeOnEsc(event));
    document.addEventListener('keydown', (event) => this.closeOnEsc(event));
  },

  beforeUpdate() {
    if (!this.WidgetIframe) {
      return;
    }
    // freezing to prevent unnecessary reactifiation of vNodes
    this.WidgetIframe.children = Object.freeze(this.$slots.default);
  },

  destroyed() {
    StripeService.destroyStripeObject();
    this.$trackEvent({ action: 'closed' });
    this.SET_DATE_PICKER_LIBRARY_LOADED(false);
  }
};
</script>
