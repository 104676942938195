<script setup>
import { computed } from 'vue';
import { useState } from 'vuex-composition-helpers';

const { frequency } = useState('donation', ['frequency']);

const hint = computed(() => {
  const frequencies = {
    weekly: 'wk',
    monthly: 'mo',
    yearly: 'yr'
  };

  return frequencies[frequency?.value];
});
</script>

<template>
  <div v-if="hint" class="flex items-center font-medium">
    <span class="text-black-01 text-base leading-4.5">/</span>
    <span class="text-black-01 text-xs leading-normal"><span></span>{{ hint }}</span>
  </div>
</template>
