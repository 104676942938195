import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { fr, es, de } from '@braid/vue-formulate-i18n';
import ClickOutside from 'vue-click-outside';
import VueCurrencyFilter from 'vue-currency-filter';
import VueI18n from 'vue-i18n';

import { loadScript } from '@/utils';
import trackEvent from '@/services/event-tracking';
import events from '@/services/events';
import localization from '@/services/localization';
import { getSentryScope } from '@/services/sentry';
import autoDirDirective from '@/directives/auto-dir.js';

import filters from '@/filters';
import store from '@/store';
import App from '@/App.vue';
import FormWidget from '@/widgets/form/Widget.vue';
import GoalMeterWidget from '@/widgets/progress-bar/Widget.vue';
import '@/common';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/, 'chariot-connect'];

Vue.use(filters);
Vue.use(VueFormulate, {
  plugins: [es, fr, de]
});

Vue.use(VueI18n);
const i18n = new VueI18n(localization);

Vue.prototype.$trackEvent = trackEvent;
Vue.prototype.$doubleEvent = events;

const orgId = getOrgId();
if (!orgId) {
  throw new Error('Cannot find Double orgId');
}
store.commit('SET_ORG_ID', orgId);

store
  .dispatch('publicOrgSettings/fetchPublicOrgSettings')
  .then(async (settings) => {
    if (!settings) {
      throw new Error('Something went wrong');
    }

    if (settings.gAnalyticsId && !settings.parenTrackingEnabled && window.gtag) {
      window.gtag('config', settings.gAnalyticsId);
    }

    // directives & filters
    Vue.directive('click-outside', ClickOutside);
    Vue.directive('auto-dir', autoDirDirective);

    Vue.use(VueCurrencyFilter, {
      symbol: '',
      thousandsSeparator: ',',
      fractionCount: 2,
      fractionSeparator: '.',
      symbolPosition: 'front',
      avoidEmptyDecimals: '',
      symbolSpacing: false
    });

    /*
      Mounting on the body directly will remove the entire body
      More ideal would have been to mount as a direct child of the body
    */
    const body = await new Promise((resolve) => {
      if (document.body) {
        resolve(document.body);
      } else {
        document.addEventListener('DOMContentLoaded', () => resolve(document.body));
      }
    });
    const dpContainer = document.createElement('DIV');
    dpContainer.style.height = 0;
    dpContainer.style.width = 0;
    body.appendChild(dpContainer);

    new Vue({
      store,
      i18n,
      render: (h) => h(App)
    }).$mount(dpContainer);
  })
  .catch((e) => console.error(e, 'Cannot load The Double checkout, please contact customer support.'));

try {
  const scope = getSentryScope();

  Vue.config.errorHandler = function (err, vm, info) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    scope.setContext('donation', store.state);
    scope.captureException(err, { extra: { info, vm } });
  };
} catch (error) {
  console.log('Error initializing sentry: ', error);
}

function getOrgId() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.VUE_APP_DEV_ORGID;
  }

  const legacyScript = document.querySelector('script[src*="donsplus.com/embed/donsplus.js"]');
  if (legacyScript) {
    const orgId = ((legacyScript || {}).src || '').match(/\?org=([a-z0-9-]+)&?/i)[1];
    return orgId;
  }

  const donsplusScriptSelector = 'script[src*=".donsplus.com"]'; // embedded scripts before rebranding
  const doubleScriptSelector = 'script[src*=".double.giving"]';
  const script = document.querySelector(`${donsplusScriptSelector}, ${doubleScriptSelector}`); // TODO: make more robust since we might have more than one script

  try {
    const orgId = new URL(script.src).pathname.slice(1); // Remove leading slash
    return orgId;
  } catch {
    return null;
  }
}

loadScript('https://cdn.plaid.com/link/v2/stable/link-initialize.js', { target: document.head });

// Not currently in use (it's based on the official example)
// function Autocomplete(instance) {
//   instance.extend({
//     library: {
//       autocomplete: {
//         classification: 'text',
//         component: 'FormulateAutocomplete'
//       }
//     }
//   });
// }

function initializeForms() {
  const formContainers = document.querySelectorAll('#double--form, .double--donation-form-widget');
  formContainers.forEach((formContainer) => {
    if (!formContainer.__vue__) {
      // Prevent duplicate Vue instances
      const campaignSlug = formContainer.getAttribute('campaign');
      new Vue({
        store,
        i18n,
        render: (h) =>
          h(FormWidget, {
            props: {
              campaignSlug
            }
          })
      }).$mount(formContainer);
    }
  });
}

function initializeGoalMeters() {
  const goalMeterContainers = document.querySelectorAll('.double--progress-bar-widget');
  goalMeterContainers.forEach((goalMeterContainer) => {
    if (!goalMeterContainer.__vue__) {
      const campaignSlug = goalMeterContainer.getAttribute('campaign');
      new Vue({
        store,
        i18n,
        render: (h) =>
          h(GoalMeterWidget, {
            props: {
              campaignSlug
            }
          })
      }).$mount(goalMeterContainer);
    }
  });
}

function initializeWidgets() {
  initializeForms();
  initializeGoalMeters();
}

document.addEventListener('DOMContentLoaded', () => {
  initializeWidgets();

  // Watch for new elements in the DOM (for SPAs)
  // Todo: check for performance issues. maybe should be a back-office setting
  const observer = new MutationObserver(initializeWidgets);
  observer.observe(document.body, { childList: true, subtree: true });
});
