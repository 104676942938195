<script>
import { isEqual, get } from 'lodash';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';

import { GATEWAYS, CC_GATEWAYS, PAYMENT_METHODS, donationStatusIsAtLeast, widgetViews, sentryMessage, sleep } from '@/utils';

import store from '@/store';
import StripeElement from '@/components/StripeElement.vue';
import AddressAutocomplete from '@/components/AddressAutocomplete.vue';
import AppButton from '@/common/AppButton.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import BankConnectIllustration from '@/components/BankConnectIllustration.vue';

import CardknoxIfield from '@/components/CardknoxIfield.vue';
import StripeService from '@/services/stripe';
import GrowService from '@/services/grow';

const DONATION_FREQUENCY_LABELS = {
  single: 'One-time',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly'
};

export default {
  name: 'FormPaymentButtons',
  components: {
    StripeElement,
    CardknoxIfield,
    AddressAutocomplete,
    AppButton,
    FormLayout,
    BankConnectIllustration
  },
  props: {
    active: Boolean
  },

  data() {
    return {
      donateWithCompany: false,
      cardElementReady: false,
      cardNumberComplete: false,
      cardExpiryComplete: false,
      cardCvcComplete: false,
      accountNumberComplete: false,
      paymentState: 'idle', // 'idle', 'processing', 'complete', 'failed',
      cardError: '',
      elementsObject: null,
      donationDirty: true, // TODO: Can be initialized to true
      isElementDirty: false,
      cardDescription: '',
      paymentRequest: null,
      requestPaymentMethodEvent: null,
      canMakePayment: null,
      tel: '',
      telFieldDirty: false,
      GATEWAYS,
      CC_GATEWAYS,
      PAYMENT_METHODS
    };
  },

  computed: {
    ...mapState('donation', { donation: (state) => state }),
    ...mapState('validation', { validation: (state) => state }),
    ...mapState(['orgId', 'isWidgetOpen', 'telLibraryLoaded']),
    ...mapGetters('donation', ['amountPlusFees', 'persistentDonationData', 'isUsingElementFields']),
    ...mapGetters('validation', ['isDonationValid']),
    ...mapState('publicOrgSettings', ['suggestedAddressCountry']),
    ...mapState('campaign', ['settings']),
    ...mapState(['orgId', 'isWidgetOpen', 'telLibraryLoaded']),

    address: {
      get() {
        return this.donation.address;
      },
      set(value) {
        this.SET_ADDRESS(value);
      }
    },

    paymentMethodTitle() {
      const paymentMethodTitles = {
        [PAYMENT_METHODS.CARD]: this.$t('payment.creditCard'),
        [PAYMENT_METHODS.ACH]: this.$t('payment.bankTransfer'),
        [PAYMENT_METHODS.BANK_WALLET]: 'Bit'
      };
      return paymentMethodTitles[this.donation.paymentMethod];
    },

    entirePaymentForm() {
      const { donation, cardNumberComplete, cardExpiryComplete, cardCvcComplete, accountNumberComplete } = this;
      const elements =
        this.donation.paymentMethod === PAYMENT_METHODS.ACH ? [accountNumberComplete] : [cardNumberComplete, cardCvcComplete, cardExpiryComplete];
      return {
        phone: donation.donorDetails.phone,
        donorDetails: donation.donorDetails,
        billingAddress: donation.billingAddress,
        elements,
        donation,
        tel: this.tel
      };
    },
    donateButtonText() {
      switch (this.paymentState) {
        case 'processing':
          return this.$t('common.wait');
        case 'complete':
          return this.$t('confirmation.thanks'); // is state used?
        case 'failed':
          return this.$t('payment.tryAgain');
      }
      if (this.donation.gateway === GATEWAYS.STRIPE && this.donation.paymentMethod === PAYMENT_METHODS.ACH) {
        return this.$t('payment.plaidConnect');
      }

      if (this.donation.gateway === GATEWAYS.GROW) {
        return this.$t('common.continue');
      }

      const ctaTerm = this.settings?.ctaTerm?.toLowerCase();
      if (!ctaTerm) {
        return this.$t('payment.donate.donate');
      }
      return this.$t(`payment.donate.${ctaTerm}`);
    },
    donationAmountOrZero() {
      if (isNaN(this.amountPlusFees * 100)) {
        return 0;
      } else {
        return this.amountPlusFees;
      }
    },
    isTelFieldInvalid() {
      if (!this.telFieldDirty || !this.tel.number) {
        return false;
      }

      return this.telFieldDirty && !this.tel.valid;
    },
    telRequiredError() {
      if (!this.telLibraryLoaded || !this.settings.optionalFields?.phoneNumber?.required) {
        return false;
      }
      return this.telFieldDirty && !this.tel.number;
    },
    isCanadaEntity() {
      return this.donation.CCGateway.country === 'Canada';
    },
    collectAddress() {
      if (this.isCanadaEntity) return true;
      return this.settings.optionalFields?.address?.collect;
    },
    isAddressRequired() {
      if (this.isCanadaEntity) return true;
      return this.settings.optionalFields?.address?.required;
    }
  },

  watch: {
    tel(value) {
      this.SET_DONOR_DETAILS_PHONE(value.number);
      this.SET_IS_PHONE_VALID(value.valid);
    },
    // We must handle donation details being changed after the initial submission to the server
    persistentDonationData(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.donationDirty = true;
        this.paymentState = 'idle';
      }
    },

    entirePaymentForm: {
      async handler(value) {
        if (!this.$refs.form) {
          return;
        }

        const donorDetailsHasErrors = async () => {
          const telRequired = this.telLibraryLoaded && this.settings.optionalFields?.phoneNumber?.required;
          if (!telRequired) {
            return await this.$refs.form?.hasValidationErrors();
          }

          return (await this.$refs.form?.hasValidationErrors()) || !this.validation.isPhoneValid;
        };

        const elementsValues = Object.values(value.elements);
        const isAllElementsValid = !elementsValues.includes(false);

        const isFormValid = this.isUsingElementFields ? isAllElementsValid && !(await donorDetailsHasErrors()) : !(await donorDetailsHasErrors());
        this.SET_IS_PAYMENT_DETAILS_VALID(isFormValid);
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('donation', [
      'createDonation',
      'confirmPaymentStripeElements',
      'confirmDonation',
      'donateWithPaymentRequest',
      'confirmStripePaymentRequest',
      'donateWithCardknox',
      'donateWithCardknoxACH',
      'donateWithGrow'
    ]),
    ...mapMutations(['SET_WIDGET_VIEW', 'SET_SUCCESSFULLY_DONATED']),
    ...mapMutations('donation', [
      'SET_CARD_EXPIRY',
      'SET_DONOR_DETAILS',
      'SET_DONOR_DETAILS_EMAIL',
      'SET_DONOR_DETAILS_PHONE',
      'SET_BILLING_ADDRESS',
      'SET_PLAID_DATA',
      'SET_RECAPTCHA_TOKEN',
      'SET_DONATION_ID'
    ]),
    ...mapMutations('validation', ['SET_IS_PAYMENT_DETAILS_VALID', 'SET_IS_PHONE_VALID']),

    async donate() {
      const state = this.paymentState;
      this.triggerFormErrors();
      // TODO validate with validation module
      if (state === 'processing' || !this.isDonationValid) {
        sentryMessage('Donor clicked disabled donate button', 'warning', {
          name: 'Store',
          data: { validation: store.state.validation, donation: store.state.donation }
        });
        return;
      }
      // TODO: env variable
      const gameChangerId = '767e4400-eee5-11eb-b529-e11231bcf357';
      // const humanAidId = 'ab817660-631a-11eb-9938-8b8726037797';
      // const dafShelSruly = '22692e00-95f4-11ec-b301-2d1fe152986b';

      const orgIds = [gameChangerId];

      if (orgIds.includes(this.orgId)) {
        const recaptchaToken = await window.grecaptcha.execute('6LeIrKcgAAAAADxKeoOl9X3TWYt3mhOHv_mw1bVk', {
          action: 'donate'
        });
        this.SET_RECAPTCHA_TOKEN(recaptchaToken);
      }
      // TODO: Create *single* entry and exit point for all donation methods to avoid a lot of redundant code

      switch (this.donation.paymentMethod) {
        case PAYMENT_METHODS.CARD:
        case PAYMENT_METHODS.BANK_WALLET:
          return this.donateWithElements();
        case PAYMENT_METHODS.ACH:
          return this.donateWithACH();
      }
    },

    async donateWithElements() {
      this.paymentState = 'processing';
      switch (this.donation.CCGateway.provider) {
        case GATEWAYS.CARDKNOX:
          try {
            await this.donateWithCardknox();
            return this.paymentSucceeded();
          } catch (err) {
            this.paymentState = 'failed';
            this.cardError = err.message;
          }
          break;

        case GATEWAYS.STRIPE:
          try {
            // Step 1 - Create the payment intent
            if (this.donationDirty) {
              await this.createDonation();
              this.donationDirty = false;
            }
            // Step 2 - Confirm the payment intent
            if (get(this.donation, 'stripeIntent.status') !== 'succeeded') {
              await this.confirmPaymentStripeElements(this.$refs.cardNumber.element);
            }
            // Step 3 - Confirm the donation on our server
            const { status, error } = await this.confirmDonation();
            if (!error && donationStatusIsAtLeast(status, '1stChargeSuccess')) {
              this.paymentSucceeded();
            } else {
              this.paymentState = 'failed';
              // todo: case payment succeeded but donation failed on our server
              this.cardError = `Problem: status: ${status}, error: ${error}`;
            }
          } catch (err) {
            this.paymentState = 'failed';
            this.cardError = err.message;
            sentryMessage(`Donation with elements failed: ${err.message}`, 'info');
          }
          break;

        case GATEWAYS.GROW:
          try {
            const { renderPaymentOptions } = await GrowService.getGrowObject();

            // Set up event handlers before rendering payment options
            GrowService.setEventCallbacks({
              onSuccess: (response) => {
                console.log('Payment succeeded:', response);
                this.paymentSucceeded();
              },
              onFailure: (response) => {
                console.error('Payment failed:', response);
                this.paymentState = 'failed';
                this.cardError = response.message || 'Payment failed';
              },
              onError: (response) => {
                console.error('Payment error:', response);
                this.paymentState = 'failed';
                this.cardError = response.message || 'An error occurred';
              },
              onTimeout: (response) => {
                console.error('Payment timeout:', response);
                this.paymentState = 'failed';
                this.cardError = 'Payment request timed out';
              },
              onWalletChange: (state) => {
                if (state === 'close') {
                  this.paymentState = 'idle';
                }
                console.log('Wallet state changed:', state);
                // Handle wallet state changes if needed
              }
            });

            const token = await this.donateWithGrow();
            return renderPaymentOptions(token);
          } catch (err) {
            console.error(err);
            this.paymentState = 'failed';
            this.cardError = err.message;
          }
          break;

        default:
          throw new Error('Something went wrong');
      }
    },

    // ACH
    async donateWithACH() {
      if (this.paymentState === 'processing') {
        return;
      }
      this.paymentState = 'processing';
      switch (this.donation.CCGateway.provider) {
        case GATEWAYS.CARDKNOX:
          try {
            await this.donateWithCardknoxACH();
            return this.paymentSucceeded();
          } catch (err) {
            this.paymentState = 'failed';
            this.cardError = err.message;
          }
          break;

        case GATEWAYS.STRIPE:
          try {
            if (this.donationDirty) {
              await this.createDonation();
              this.donationDirty = false;
            }
            const { publicToken, metadata } = await this.confirmPaymentPlaid();

            this.SET_PLAID_DATA({ publicToken, metadata });

            const { status, error } = await this.confirmDonation();
            if (donationStatusIsAtLeast(status, '1stChargeSuccess')) {
              return this.paymentSucceeded();
            } else {
              this.paymentState = 'failed';
              this.cardError = `Problem: status: ${status}, error: ${error}`;
            }
          } catch (err) {
            // if (err !== 'null') {
            //   // user cancelled
            //   this.paymentState = 'idle';
            // } else {
            //   this.paymentState = 'failed';
            //   this.cardError = err.message;
            // }
            this.paymentState = 'failed';
            this.cardError = err.message;
          }
      }
    },

    async confirmPaymentPlaid() {
      return new Promise((resolve, reject) => {
        // const iframe = document.querySelector('iframe[name="double-checkout"]');
        // const Plaid = iframe.contentWindow.Plaid;
        if (!Plaid) {
          reject(new Error('Plaid is not loaded!'));
        }
        const config = {
          token: this.donation.plaidLinkToken.link_token,
          onSuccess: async (publicToken, metadata) => {
            resolve({ publicToken, metadata });
          },
          onExit: async (err) => {
            console.error(err);
            reject(new Error(err)); // TODO: refresh token. If null - user cancelled
          }
        };

        /* globals Plaid */
        const linkHandler = Plaid.create(config);
        linkHandler.open();
      });
    },

    // UI methods
    async createElements() {
      if (this.donation.CCGateway.provider !== CC_GATEWAYS.STRIPE) {
        return;
      }
      const stripe = await StripeService.getStripeObject();
      this.elementsObject = stripe.elements({
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito&display=swap'
          }
        ],
        locale: 'auto'
      });
    },

    triggerFormErrors() {
      // Trigger fake submission
      this.$refs.hiddenSubmitButton.click();
      this.isElementDirty = true;
      this.telFieldDirty = true;
      // Notify other components
      this.$emit('donation-attempt');
      // Scroll to first error

      this.$nextTick(() => {
        const customErrorMessage = document.querySelector('.section-has-error');
        const firstFieldElementWithError = document.querySelector('[data-is-showing-errors=true]');
        const elementToScrollTo = customErrorMessage || firstFieldElementWithError;
        if (elementToScrollTo) {
          elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },

    // Help methods
    paymentSucceeded() {
      this.paymentState = 'complete';
      this.SET_WIDGET_VIEW(widgetViews.CONFIRMATION_DONATION);
      this.SET_DONATION_ID(null);
      this.$doubleEvent('Double.donationCompleted');
      this.$trackEvent({
        action: 'success',
        label: DONATION_FREQUENCY_LABELS[this.donation.frequency] + ' donation',
        value: this.donationAmount
      });
      this.SET_SUCCESSFULLY_DONATED(true);
    },
    async setEmail(email) {
      if (await this.$refs.email?.hasValidationErrors()) {
        return;
      }
      this.SET_DONOR_DETAILS_EMAIL(email);
    }
  },

  async mounted() {
    // TEMP: emulate donation completed EVENT for Elazraki
    if (this.orgId === '6296ca90-32ba-11ef-b836-0d5f75516076' && this.donation.paymentMethod === PAYMENT_METHODS.ACH) {
      console.log('6296ca90-32ba-11ef-b836-0d5f75516076');
      this.$doubleEvent('Double.donationCompleted');
    }
    this.createElements();

    if (this.donation.gateway === GATEWAYS.GROW) {
      const grow = await GrowService.getGrowObject();
      grow.init();
      await sleep(1000);
      // grow.renderPaymentOptions('aefeffbcbaf9a47cfd9245c49dd1ec25%NTYxMzc4');
    }
  }
};
</script>

<template>
  <form-layout :title="paymentMethodTitle">
    <div ref="paymentDetails">
      <div>
        <formulate-form ref="form">
          <div class="my-8">
            <h4 class="body-text-extra-bold mb-2">Personal information</h4>
            <div class="flex flex-col gap-2">
              <formulate-input
                @input="setEmail"
                :value="donation.donorDetails.email"
                type="email"
                validation="^required|email"
                validation-name="Email"
                ref="email"
                :placeholder="$t('payment.email')"
                name="email"
                class="w-full"
              />
              <div class="flex gap-2">
                <formulate-input
                  v-model="donation.donorDetails.firstName"
                  type="text"
                  validation="required"
                  validation-name="First name"
                  :placeholder="$t('common.firstName')"
                  name="firstName"
                  autocomplete="given-name"
                  class="flex-1"
                />
                <formulate-input
                  v-model="donation.donorDetails.lastName"
                  type="text"
                  validation="required"
                  validation-name="Last name"
                  :placeholder="$t('common.lastName')"
                  name="lastName"
                  autocomplete="family-name"
                  class="flex-1"
                />
              </div>
              <!-- Loaded globally in widget-iframe.vue  -->
              <div>
                <!--
                  Tried to integrate it with Formulate, but it seems impossible since the tel lib doesn't expose
                  a slot for the input, so we can't bind to it the formulate properties
                -->
                <vue-tel-input
                  v-if="telLibraryLoaded"
                  :only-countries="donation.CCGateway.provider === CC_GATEWAYS.GROW ? ['IL'] : []"
                  :default-country="donation.CCGateway.provider === CC_GATEWAYS.GROW ? 'IL' : suggestedAddressCountry || 'US'"
                  :auto-default-country="false"
                  @blur="telFieldDirty = true"
                  @validate="tel = $event"
                  :class="{ invalid: isTelFieldInvalid || telRequiredError }"
                />
                <p v-if="telRequiredError" class="mt-1 px-1 text-red text-xxs">Phone number is required.</p>
                <p v-else-if="isTelFieldInvalid" class="mt-1 px-1 text-red text-xxs">"{{ tel.number }}" is not a valid phone number.</p>
              </div>

              <app-checkbox v-model="donateWithCompany" value="true">
                <div class="body-text bold">
                  {{ $t('payment.behalfCompany') }}
                </div>
              </app-checkbox>
              <transition name="expandx3">
                <formulate-input
                  v-if="donateWithCompany"
                  v-model="donation.donorDetails.companyName"
                  type="text"
                  validation="required"
                  :placeholder="$t('payment.companyName')"
                  validation-name="Company name"
                  name="companyName"
                  class="w-full"
                />
              </transition>
            </div>
          </div>

          <div v-if="donation.paymentMethod === PAYMENT_METHODS.CARD && isUsingElementFields" class="my-8">
            <h4 class="body-text-extra-bold mb-2 flex items-center gap-0.5">
              <span>Card information</span>
              <app-tooltip>
                <app-icon name="heart-lock" height="18" width="18" type="svg" />
                <template #content>
                  <p>Our checkout is secured by industry-standard 256 bit SSL encryption</p>
                </template>
              </app-tooltip>
            </h4>

            <div class="flex flex-col gap-2">
              <stripe-element
                v-if="donation.CCGateway.provider === CC_GATEWAYS.STRIPE && elementsObject"
                @state-changed="cardNumberComplete = $event === 'complete'"
                @ready="cardElementReady = $event"
                :elements-object="elementsObject"
                element-type="cardNumber"
                :make-dirty="isElementDirty"
                ref="cardNumber"
              />
              <cardknox-ifield
                v-else-if="donation.CCGateway.provider === CC_GATEWAYS.CARDKNOX"
                @state-changed="cardNumberComplete = $event === 'complete'"
                @ready="cardElementReady = $event"
                ifield-id="card-number"
                ref="cardknox-card-number"
                :label="this.$t('payment.cardNumber')"
                :make-dirty="isElementDirty"
              />
              <div class="flex gap-2">
                <template v-if="donation.CCGateway.provider === CC_GATEWAYS.STRIPE && elementsObject">
                  <stripe-element
                    class="flex-1"
                    @state-changed="cardExpiryComplete = $event === 'complete'"
                    :elements-object="elementsObject"
                    element-type="cardExpiry"
                    :make-dirty="isElementDirty"
                  />
                  <stripe-element
                    class="flex-1"
                    @state-changed="cardCvcComplete = $event === 'complete'"
                    :elements-object="elementsObject"
                    element-type="cardCvc"
                    :make-dirty="isElementDirty"
                  />
                </template>
                <template v-else-if="donation.CCGateway.provider === CC_GATEWAYS.CARDKNOX">
                  <div class="flex-1">
                    <content-loader v-if="!cardElementReady" />
                    <card-exp-field
                      v-else
                      @validate="
                        SET_CARD_EXPIRY($event.isValid ? $event.value : null);
                        $event.isValid ? (cardExpiryComplete = true) : (cardExpiryComplete = false);
                      "
                      :make-dirty="isElementDirty"
                    />
                  </div>

                  <cardknox-ifield
                    @state-changed="cardCvcComplete = $event === 'complete'"
                    ifield-id="cvv"
                    ref="cardknox-cvv"
                    label="CVV"
                    class="flex-1"
                    :make-dirty="isElementDirty"
                  />
                </template>
                <div class="flex-1">
                  <content-loader v-if="!cardElementReady" />
                  <formulate-input
                    v-else
                    v-model="donation.donorDetails.zip"
                    type="text"
                    validation="required"
                    validation-name="ZIP"
                    placeholder="ZIP"
                    name="zip"
                    maxlength="9"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="collectAddress" class="my-8">
            <h4 class="body-text-extra-bold mb-2">Billing information</h4>
            <div class="flex flex-col gap-2">
              <address-autocomplete @update="SET_BILLING_ADDRESS($event)" :address-required="isAddressRequired" />
            </div>
          </div>

          <div v-if="donation.gateway === GATEWAYS.STRIPE && donation.paymentMethod === PAYMENT_METHODS.ACH" class="p-7 plaid-fwd-msg rounded-2xl">
            <bank-connect-illustration />
            <h3 class="bold text-center">{{ $t('payment.plaidTitle') }}</h3>
            <div class="mt-1 body-text bold text-black-01 text-center">{{ $t('payment.plaidHelp') }}</div>
          </div>

          <div v-if="donation.gateway === GATEWAYS.CARDKNOX && donation.paymentMethod === PAYMENT_METHODS.ACH">
            <h4 class="body-text-extra-bold mb-2">Account information</h4>
            <div class="flex flex-col gap-2">
              <!-- Account name -->
              <formulate-input
                v-model="donation.accountName"
                type="text"
                validation="required"
                validation-name="Account name"
                placeholder="Account name"
                name="accountName"
                class="w-full"
              />
              <div class="flex gap-2">
                <cardknox-ifield
                  @state-changed="accountNumberComplete = $event === 'complete'"
                  ifield-id="ach"
                  ref="cardknox-account-number"
                  label="Account number"
                  :make-dirty="isElementDirty"
                />

                <formulate-input
                  v-model="donation.routingNumber"
                  type="text"
                  validation="required"
                  validation-name="Routing number"
                  placeholder="Routing number"
                  name="routingNumber"
                  class="w-full"
                />
              </div>
            </div>
          </div>
          <!-- a hidden button to trigger the display of the error with a fake  submission -->
          <button ref="hiddenSubmitButton" class="hidden-submit-button"></button>
        </formulate-form>
      </div>
    </div>
    <template #footer>
      <div v-if="paymentState === 'failed' && cardError" class="custom-error-message">Error: {{ cardError }}</div>
      <app-button @click="donate" id="donate-button" class="primary w-full flex justify-center items-center" :disabled="!isDonationValid">
        <h3 v-if="paymentState === 'processing'" class="flex items-center gap-1">
          <app-loader />
          <span> {{ $t('common.wait') }}</span>
        </h3>
        <h3 v-else class="flex gap-1">
          <span>{{ donateButtonText }}</span>
          <app-price
            v-if="donation.paymentMethod === PAYMENT_METHODS.CARD && paymentState !== 'failed' && donation.gateway !== GATEWAYS.GROW"
            :amount="donationAmountOrZero"
            :currency="donation.currency"
            :frequency="donation.frequency"
            show-frequency
          />
        </h3>
      </app-button>
    </template>
  </form-layout>
</template>
