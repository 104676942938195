import { splitName } from '@/utils';

export function extractData(paymentData) {
  const { payerEmail: email, payerName: name, payerPhone: phone } = paymentData;
  const cardDescription = cardDescriptionFormat(paymentData);
  const addressData = paymentData.paymentMethod?.billing_details?.address;
  const { firstName, lastName } = splitName(name);
  return {
    firstName,
    lastName,
    email,
    phone,
    address: {
      line1: addressData?.line1,
      line2: addressData?.line2,
      city: addressData?.city,
      state: addressData?.state,
      zip: addressData?.postal_code,
      country: addressData?.country
    },
    cardDescription
  };
}

function cardDescriptionFormat(paymentData) {
  const card = paymentData.paymentMethod?.card;
  if (!card) {
    return '';
  }
  return `${card.brand} ••••${card.last4}`;
}
