<script setup>
import { computed, ref } from 'vue';
import { useState, useGetters } from 'vuex-composition-helpers';
import GoalMeter from '@/components/GoalMeter.vue';
import FundraiserStats from '@/components/FundraiserStats.vue';

const { hasActiveGoalMeter } = useGetters('campaign', ['hasActiveGoalMeter']);
const { settings } = useState('campaign', ['settings']);
const { id: fundraiserId, message: fundraiserMessage, hideInfo } = useState('fundraiser', ['id', 'message', 'hideInfo']);

const description = computed(() => {
  if (fundraiserMessage.value && !hideInfo.value) {
    return fundraiserMessage.value;
  }
  return settings.value.description;
});

const description2 = computed(() => {
  if (fundraiserMessage.value && !hideInfo.value) {
    return '';
  }
  return settings.value.description2;
});

const showMore = ref(false);

const showGoalMeter = () => {
  return hasActiveGoalMeter.value;
};
const showFundraiser = () => {
  if (!settings.value.p2pEnabled || hideInfo.value) {
    return false;
  }

  return !!fundraiserId.value;
};
</script>

<template>
  <header class="bg-secondary pb-8 app-header px-6 text-white">
    <section class="pb-5">
      <div class="greeting">
        <h1 v-auto-dir>{{ settings.title }}</h1>
      </div>

      <transition name="expandx3">
        <fundraiser-stats v-if="showFundraiser()" />
        <goal-meter v-else-if="showGoalMeter()" />
      </transition>
      <p v-auto-dir class="body-text-medium header-message my-2 opacity-80">
        {{ description }}
      </p>
      <transition name="expandx2">
        <p v-if="showMore" v-auto-dir class="body-text-medium header-message my-2 opacity-80">
          {{ description2 }}
        </p>
      </transition>
      <button v-if="description2?.length > 0" class="small-text text-white underline" @click="showMore = !showMore">
        {{ showMore ? 'Show less' : 'Show more' }}
      </button>
    </section>
  </header>
</template>

<style lang="scss">
.greeting-container {
  margin-bottom: 4px;
  padding: 8px 16px;

  .greeting {
    display: flex;

    .hand-icon {
      width: 24px;
      height: 22px;
      fill: #fff;
      transform: matrix(0.87, -0.5, 0.5, 0.87, 0, 0) translateY(4px);
    }

    .title {
      margin-left: 3px;
      color: #fff;
      font-size: 26px;
      line-height: 30px;
      font-weight: 900;
    }
  }
}
</style>
