<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import store from '@/store';
import css from '!!raw-loader!sass-loader!@/assets/css/launcher.scss';
import nunito from '!!raw-loader!@/assets/fonts/nunito.css';

export default {
  name: 'LauncherIframe',

  render(h) {
    return h('iframe', {
      on: { load: this.renderChildren },
      attrs: {
        src: 'about:blank',
        name: 'double-checkout-launcher',
        title: 'Widget Launcher',
        frameborder: 0
      },
      style: this.iframeStyles
    });
  },

  computed: {
    ...mapState('publicOrgSettings', ['settings']),
    ...mapGetters('publicOrgSettings', ['isTextLauncher']),
    iframeStyles() {
      return {
        width: this.iframeWidth,
        height: this.text ? '50px' : '64px',
        left: this.settings.widgetPosition === 'left' ? '32px' : 'auto',
        right: this.settings.widgetPosition === 'right' ? '32px' : 'auto'
      };
    },
    text() {
      return !this.isTextLauncher ? '' : this.settings.ctaTerm;
    },
    iframeWidth() {
      if (this.text === 'Donate') {
        return '128px';
      }
      if (this.text === 'Contribute') {
        return '158px';
      }
      if (this.text === 'Support') {
        return '136px';
      }

      return '64px';
    }
  },

  methods: {
    renderChildren() {
      const children = this.$slots.default;
      const body = this.$el.contentDocument.body;
      const el = document.createElement('DIV'); // we will mount or nested app to this element
      body.appendChild(el);
      body.style.display = 'inline';
      body.style.margin = '0';

      const LauncherIframe = new Vue({
        name: 'LauncherIframe',
        store,
        //freezing to prevent unnessessary Reactifiation of vNodes
        data: { children: Object.freeze(children) },
        render(h) {
          return h('div', this.children);
        }
      });

      LauncherIframe.$mount(el); // mount into iframe

      this.LauncherIframe = LauncherIframe; // cache instance for later updates

      this.applyCss();
    },
    applyCss() {
      const iframe = this.$el.contentDocument;
      const styleElement = document.createElement('style');
      styleElement.textContent = css + nunito;
      iframe.head.appendChild(styleElement);
    }
  },

  beforeUpdate() {
    // freezing to prevent unnecessary reactifiation of vNodes
    if (!this.LauncherIframe) {
      return;
    }
    this.LauncherIframe.children = Object.freeze(this.$slots.default);
  }
};
</script>
