<script>
import dayjs from 'dayjs';
import { mapMutations, mapActions, mapState } from 'vuex';

import ToggleButton from '@/components/ToggleButton.vue';
import { handleNumberInput } from '@/utils';
import heartData from '@/assets/heart-lottie.json';

export default {
  name: 'FormOften',
  components: {
    ToggleButton
  },
  props: {
    active: Boolean,
    showError: Boolean,
    staticForm: Boolean,
    formCampaignSettings: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      dayjs,
      recurringType: 'By charges',
      handleNumberInput,
      heartData,
      hideHeartSvg: false
    };
  },
  computed: {
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapState('publicOrgSettings', ['settings']),
    ...mapState('campaign', {
      campaignSettings: (campaign) => campaign.settings
    }),
    ...mapState('campaign', {
      campaignSettings: (campaign) => campaign.settings
    }),
    // Since this component is currently also used for the static form which relies on the default campaign settings
    settingsComputed() {
      if (this.formCampaignSettings) {
        return this.formCampaignSettings;
      }
      return this.staticForm ? this.settings : this.campaignSettings;
    },
    frequencies() {
      return [
        { value: 'single', label: this.$t('donationFrequency.oneTime') },
        { value: 'weekly', label: this.$t('donationFrequency.weekly') },
        { value: 'monthly', label: this.$t('donationFrequency.monthly') },
        { value: 'yearly', label: this.$t('donationFrequency.yearly') }
      ];
    },
    frequenciesEnabled() {
      return this.frequencies.filter((frequency) => this.settingsComputed.frequenciesEnabled?.includes(frequency.value));
    },
    frequenciesRecurringEnabled() {
      return this.frequenciesEnabled.filter((frequency) => frequency.value !== 'single');
    },
    isCurrentFrequencyEnabled() {
      return this.frequenciesEnabled.map((frequency) => frequency.value).includes(this.donation.frequency);
    },
    recurringTypeString: {
      get() {
        return this.donation.recurringType || 'byDate';
      },
      set(value) {
        if (value) {
          this.SET_RECURRING_TYPE(value);
        }
      }
    },
    endDate: {
      get() {
        return this.donation.endDate;
      },
      set(value) {
        this.SET_END_DATE(value);
      }
    },
    numCharges: {
      get() {
        return this.donation.numCharges;
      },
      set(value) {
        this.SET_NUM_CHARGES(value);
      }
    },
    hasEndDate: {
      get() {
        return this.donation.hasEndDate;
      },
      set(value) {
        this.SET_HAS_END_DATE(value);
      }
    },
    frequency: {
      get() {
        return this.donation.frequency;
      },
      set(value) {
        this.SET_FREQUENCY_ACTIONS(value);
      }
    },
    // Triggers validation check
    recurringEnd() {
      const { frequency, recurringTypeString, endDate } = this;
      return { frequency, recurringTypeString, endDate };
    },
    minDate() {
      const momentStrings = {
        weekly: 'weeks',
        monthly: 'months',
        yearly: 'years'
      };
      if (this.donation.frequency !== 'single') {
        return dayjs().startOf('day').add(1, momentStrings[this.donation.frequency]);
      }
      return dayjs();
    },
    showToggle() {
      // Hide toggle if 3 recurring are enabled
      if (this.frequenciesEnabled.length === 4) {
        return true;
      }
      return this.frequenciesRecurringEnabled.length !== 3;
    }
  },
  watch: {
    recurringEnd() {
      if (this.hasEndDate) {
        setTimeout(async () => {
          const hasErrors = await this.$refs.recurringEndForm.hasValidationErrors();
          this.SET_IS_RECURRING_END_VALID(!hasErrors);
        });
      }
    },
    'settingsComputed.frequencyDefaultComputed': {
      immediate: true,
      handler(value) {
        if (!this.isCurrentFrequencyEnabled && value) {
          this.selectFrequency(value);
        }
      }
    }
  },
  methods: {
    ...mapMutations('donation', ['SET_AMOUNT', 'SET_RECURRING_TYPE', 'SET_HAS_END_DATE', 'SET_END_DATE', 'SET_NUM_CHARGES']),
    ...mapActions('donation', ['SET_FREQUENCY_ACTIONS']),
    ...mapMutations('validation', ['SET_IS_RECURRING_END_VALID']),
    selectFrequency(frequency) {
      this.SET_FREQUENCY_ACTIONS(frequency);
      if (frequency === 'single') {
        this.SET_HAS_END_DATE(false);
      }
    },
    handleSetEndDate(value) {
      // TODO
      if (value) {
        this.recurringTypeString = 'byCharges';
      }
    },

    complete() {
      this.hideHeartSvg = false;
    },
    showHeart(frequency) {
      if (this.staticForm && window.innerWidth < 375) {
        return false;
      }
      return frequency === this.settingsComputed.frequencyPromoted;
    }
  }
};
</script>

<template>
  <section
    ref="form-often"
    :class="{
      'section-has-error': showError && !frequency
    }"
    class="form-often mb-8"
  >
    <div v-if="showToggle" class="often-buttons buttons relative flex gap-1 bg-off-white">
      <div
        class="overlay absolute top-0 left-0 w-1/2 h-full flex items-center justify-center rounded-lg"
        :class="[{ 'bg-primary': isCurrentFrequencyEnabled }, { 'is-first-option': donation.frequency === frequenciesEnabled[0]?.value }]"
      ></div>
      <template v-if="frequenciesEnabled.length === 2">
        <toggle-button
          v-for="(frequency, i) in frequenciesEnabled"
          :label="frequency.label"
          :showHeart="showHeart(frequency.value)"
          :selected="donation.frequency === frequency.value"
          :frequency-promoted="settingsComputed.frequencyPromoted"
          :key="i"
          @click.native="selectFrequency(frequency.value)"
        />
      </template>
      <template v-else-if="frequenciesEnabled.length > 2">
        <toggle-button
          :label="frequencies[0].label"
          :selected="donation.frequency === frequencies[0].value"
          @click.native="selectFrequency(frequencies[0].value)"
        />
        <toggle-button
          label="Recurring"
          :selected="donation.frequency !== 'single'"
          show-heart
          @click.native="selectFrequency(frequenciesRecurringEnabled[0].value)"
        />
      </template>
    </div>

    <transition name="expand">
      <div v-if="frequenciesEnabled.length > 2 && frequency !== 'single'" class="mt-4.5">
        <h4>Frequency</h4>
        <app-select
          :value="frequenciesEnabled.find((f) => f.value === frequency)?.label"
          @input="selectFrequency($event.value)"
          label="label"
          :options="frequenciesEnabled.filter((f) => f.value !== 'single')"
          :searchable="false"
          name="frequency"
          id="frequency"
          placeholder="Select a frequency"
          class="flex-1 my-2"
        />
      </div>
    </transition>
    <formulate-form ref="recurringEndForm">
      <div v-if="frequency !== 'single' && !staticForm">
        <transition name="expandx3">
          <div v-if="frequency" class="checkbox-section">
            <app-checkbox v-model="hasEndDate" @input="handleSetEndDate">Set an end date</app-checkbox>
          </div>
        </transition>

        <div class="mt-2">
          <transition name="expand">
            <div v-if="hasEndDate" class="flex gap-2 end-date-fields">
              <app-select
                v-model="recurringType"
                :value="recurringTypeString"
                @input="SET_RECURRING_TYPE($event.value)"
                :options="[
                  { value: 'byCharges', name: 'By charges' },
                  { value: 'byDate', name: 'By date' }
                ]"
                :searchable="false"
                name="recurringType"
                id="end-date-type"
                placeholder="Select an option"
                class="flex-1"
              />
              <div v-if="recurringTypeString === 'byCharges'" class="flex-1">
                <formulate-input
                  v-model.number="numCharges"
                  validation="^required|min:2|max:120"
                  type="number"
                  validation-name="Number of charges"
                  max="120"
                  min="2"
                  placeholder="Amount of charges"
                  key="numberOfCharges"
                  name="numberOfCharges"
                  @keydown="handleNumberInput($event, 3)"
                />
              </div>
              <div v-else-if="recurringTypeString === 'byDate'" class="flex-1">
                <formulate-input
                  v-model="endDate"
                  ref="endDate"
                  :class="{ empty: endDate }"
                  type="date"
                  :validation="`required|after:${minDate.toISOString()}`"
                  :min="minDate.format('YYYY-MM-DD')"
                  validation-name="End date"
                  :validation-messages="{
                    after: () => `End date must be after ${minDate.subtract(1, 'day').format('YYYY-MM-DD')}`
                  }"
                  key="endDate"
                  name="chargeEndDate"
                />
                <!-- <ion-icon name="calendar"></ion-icon> -->
              </div>
            </div>
          </transition>
        </div>
      </div>
    </formulate-form>
    <span v-if="showError && !frequency" class="custom-error-message">{{ $t('donationFrequency.errorMessage') }}</span>
  </section>
</template>
