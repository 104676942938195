<script>
import { get } from 'lodash';

export default {
  name: 'AppAvatar',

  data: () => ({
    showLogo: false,
    showRemoveButton: false,
    showLogoImage: false,
    logoFile: null,
    shouldDeleteLogo: false,
    get
  }),

  methods: {
    removeLogo() {
      if (get(this.logoFile, 'files.length')) {
        return this.$refs.fileUploader.$el.querySelector('.formulate-file-remove').click?.();
      }
    },
    validateFileSize() {
      if (!this.logoFile?.files?.length) {
        return true;
      }
      return Array.prototype.every.call(this.logoFile.files, function (file) {
        return file?.file?.size <= 2097152; // 2MB
      });
    }
  }
};
</script>

<template>
  <!-- Component should ideally be a custom formulate -->
  <div class="app-avatar" :class="{ 'has-file': get(logoFile, 'fileList[0]') }">
    <div class="file-overlay"></div>

    <formulate-input
      @change="showLogo = false"
      @file-upload-complete="
        showRemoveButton = $event.complete;
        $emit('complete', $event);
      "
      v-show="!showLogoImage"
      v-model="logoFile"
      type="image"
      ref="fileUploader"
      name="_avatar"
      validation="^mime:image/jpeg,image/png|size"
      :validation-rules="{ size: validateFileSize }"
      :validation-messages="{ size: 'File size should be less than 2MB' }"
      @validation="$emit('validation', $event)"
    >
      <template #help>
        <div class="file-help flex flex-col justify-center">
          <div class="upload-icon" v-if="!get(logoFile, 'fileList[0]', null)">
            <svg class="flex items-center justify-center" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5 1.5C7.5 0.671573 6.82843 0 6 0C5.17157 0 4.5 0.671573 4.5 1.5V4.5H1.5C0.671573 4.5 0 5.17157 0 6C0 6.82843 0.671573 7.5 1.5 7.5H4.5V10.5C4.5 11.3284 5.17157 12 6 12C6.82843 12 7.5 11.3284 7.5 10.5V7.5H10.5C11.3284 7.5 12 6.82843 12 6C12 5.17157 11.3284 4.5 10.5 4.5H7.5V1.5Z"
                fill="var(--primary-color)"
              />
            </svg>
          </div>
          <div class="text-center subtext-note-regular text-blue-04 mt-1" v-if="!get(logoFile, 'fileList[0]', null)">Add a profile picture</div>
        </div>
        <div class="control-icons">
          <div @click="$refs.fileUploader.$el.querySelector('input').click()" class="control">
            <app-icon name="edit-pen" type="svg" class="icon" />
          </div>
          <div v-show="showRemoveButton" @click="$refs.fileUploader.$el.querySelector('.formulate-file-remove').click()" class="control">
            <ion-icon name="trash-outline" class="icon" />
          </div>
        </div>
      </template>
    </formulate-input>
  </div>
</template>
