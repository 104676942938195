<script>
import { toJewishDate, toHebrewJewishDate, formatJewishDateInHebrew } from 'jewish-date';
import { mapState } from 'vuex';

function hebrewMonthsForGregorianMonth(yearNumber, monthNumber) {
  const firstDay = new Date(yearNumber, monthNumber, 1);
  const year = firstDay.getFullYear();
  const month = firstDay.getMonth() + 1; // months are 0-indexed, so add 1
  const lastDay = new Date(year, month, 0); // day 0 of the next month gives the last day of the current month

  const firstDayJewish = toHebrewJewishDate(toJewishDate(firstDay));
  const lastDayJewish = toHebrewJewishDate(toJewishDate(lastDay));
  return {
    firstDay: {
      month: firstDayJewish.monthName,
      year: firstDayJewish.year
    },
    lastDay: {
      month: lastDayJewish.monthName,
      year: lastDayJewish.year
    }
  };
}

export default {
  data() {
    return {
      date: new Date()
    };
  },
  props: {
    reservedDates: Array,
    hebrewDates: Boolean
  },
  computed: {
    ...mapState('donation', {
      donation: (state) => state
    }),
    he() {
      const widgetIframe = document.querySelector('iframe[name="double-checkout"]');
      return widgetIframe.contentWindow.vdp_translation_he?.js;
    },
    language() {
      const widgetWindow = document.querySelector('iframe[name="double-checkout"]')?.contentWindow;
      if (this.hebrewDates) {
        return widgetWindow?.vdp_translation_he?.js;
      }

      return undefined;
    }
  },
  methods: {
    selectedValueFormatter(date) {
      if (this.hebrewDates) {
        const jewishDate = toJewishDate(date);
        return formatJewishDateInHebrew(jewishDate);
      } else {
        console.log(date.toLocaleDateString());
        return date.toLocaleDateString();
      }
    },
    formatDate(cell) {
      const date = new Date(cell.timestamp);
      if (this.hebrewDates) {
        const jewishDate = toJewishDate(date);
        const jewishDateInHebrew = toHebrewJewishDate(jewishDate);
        return {
          day: jewishDateInHebrew.day,
          month: jewishDateInHebrew.monthName
        };
      } else {
        return {
          day: date.getDate(),
          month: date.toLocaleString('default', { month: 'long' })
        };
      }
    },
    monthBtnContent(month, year) {
      const months = {
        ינואר: 0,
        פברואר: 1,
        מרץ: 2,
        אפריל: 3,
        מאי: 4,
        יוני: 5,
        יולי: 6,
        אוגוסט: 7,
        ספטמבר: 8,
        אוקטובר: 9,
        נובמבר: 10,
        דצמבר: 11
      };
      if (this.hebrewDates) {
        const { firstDay, lastDay } = hebrewMonthsForGregorianMonth(year, months[month]);
        if (firstDay.year === lastDay.year) {
          return `${firstDay.month} - ${lastDay.month} ${firstDay.year}`;
        }

        return `${firstDay.month} ${firstDay.year} - ${lastDay.month} ${lastDay.year}`;
      } else {
        return `${new Date(year, months[month], 1).toLocaleString('default', { month: 'long' })} ${year}`;
      }
    },
    isReservedDate(cell) {
      const d = new Date(cell.timestamp);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      // Custom logic for Meimasai
      const sederDescription = 'Seder sponsor';
      const masechtaDescription = 'Masechta sponsor';
      const sederQuestion = 'Please choose a Seder';
      const masechtaQuestion = 'Please choose a Masechta';

      if (this.donation.amountDescription === sederDescription) {
        const selectedSeder = this.donation.customQuestions.find((question) => question.question === sederQuestion)?.answer;
        return this.reservedDates.some((entry) => {
          const { seder } = entry.meta;
          return entry.date === formattedDate && seder === selectedSeder;
        });
      }

      if (this.donation.amountDescription === masechtaDescription) {
        const selectedMasechta = this.donation.customQuestions.find((question) => question.question === masechtaQuestion)?.answer;
        return this.reservedDates.some((entry) => {
          const { masechta } = entry.meta;
          return entry.date === formattedDate && masechta === selectedMasechta;
        });
      }

      return this.reservedDates.some((entry) => {
        const { amountDescription } = entry.meta;
        return entry.date === formattedDate && amountDescription === this.donation.amountDescription;
      });
    },
    handleClick(event, isReserved) {
      if (isReserved) {
        event.stopPropagation();
      }
    },
    selectDate(event) {
      const date = new Date(event).toISOString()?.split('T')[0];
      this.$emit('date-selected', date);
    }
  }
};
</script>

<template>
  <div class="date-picker">
    <date-picker
      v-bind="$attrs"
      v-on="$listeners"
      :language="language"
      full-month-name
      maximum-view="day"
      :format="hebrewDates ? selectedValueFormatter : undefined"
      @selected="selectDate"
    >
      <template #dayCellContent="{ cell }">
        <div
          :class="{ 'reserved-date': isReservedDate(cell) }"
          class="cell-content flex flex-col h-full w-full items-center justify-center"
          :title="isReservedDate(cell) ? 'Reserved Date' : 'Choose Date'"
          @click="handleClick($event, isReservedDate(cell))"
        >
          <!-- <span>{{ formatDate(cell).day }}</span> -->
          <span>{{ formatDate(cell).day }}</span>

          <span class="text-xxs">{{ formatDate(cell).month }}</span>
        </div>
      </template>
      <template v-if="hebrewDates" #monthBtnContent="{ data }">{{ monthBtnContent(data.currMonthName, data.currYearName) }}</template>
    </date-picker>
  </div>
</template>
