// Move all view logic from App.vue to here
import { Storage } from '@/utils';
import dayjs from 'dayjs';

export default {
  data: () => ({
    isFreshLoad: false
  }),

  computed: {
    themeColor() {
      return this.publicOrgSettings.themeColor;
    }
  },
  methods: {
    createDynamicKeyFrames() {
      const boxShadowValues = [`0 0 0 0 ${this.themeColor}20`, '0 0 0 80px rgba(204, 169, 44, 0)', '0 2px 32px 0 rgba(204,169,44, 0)'];
      const constantBoxShadow = 'rgba(0, 0, 0,  0.06) 0 1px 6px 0px, rgba(0, 0, 0, 0.16) 0 2px 32px 0';
      const keyFrames = `
      @-webkit-keyframes dp-puff-out {
          0% {
            -webkit-box-shadow: ${boxShadowValues[0]}, ${constantBoxShadow};
          }
          70% {
             -webkit-box-shadow: ${boxShadowValues[1]}, ${constantBoxShadow};
          }
          100% {
            -webkit-box-shadow:${boxShadowValues[2]}, ${constantBoxShadow};
          }
        }
        @keyframes dp-puff-out {
          0% {
            -moz-box-shadow: ${boxShadowValues[0]}, ${constantBoxShadow};
            box-shadow: ${boxShadowValues[0]}, ${constantBoxShadow};
          }
          70% {
            -moz-box-shadow: ${boxShadowValues[1]}, ${constantBoxShadow};
            box-shadow: ${boxShadowValues[1]}, ${constantBoxShadow};
          }
          100% {
            -moz-box-shadow: ${boxShadowValues[2]}, ${constantBoxShadow};
            box-shadow: ${boxShadowValues[2]}, ${constantBoxShadow};
          }
        }
      `;
      const style = document.createElement('style');

      style.innerHTML = keyFrames;
      document.head.appendChild(style);
    },
    handleFreshLoad() {
      // Show puff out only once in 24 hours
      const recentLoadTimestamp = Number(Storage.getItem('Double.recentLoadTimestamp'), 0);
      if (!recentLoadTimestamp) {
        this.isFreshLoad = true;
        return Storage.setItem('Double.recentLoadTimestamp', Date.now());
      }

      const today = dayjs();
      const isAtLeastADayAgo = today.diff(dayjs(recentLoadTimestamp), 'day') > 1;
      if (isAtLeastADayAgo) {
        Storage.setItem('Double.recentLoadTimestamp', Date.now());
        this.isFreshLoad = true;
      }
    }
  },

  created() {
    this.createDynamicKeyFrames();
  }
};
