<script setup>
import dayjs from 'dayjs';
import { defineProps, computed, ref } from 'vue';
import { useState } from 'vuex-composition-helpers';
import AnimatedNumber from 'animated-number-vue';

import request from '@/services/request';
import { getCurrencySymbol } from '@/utils';
import AppProgressbar from '@/common/AppProgressbar.vue';
import Iframe from '@/widgets/progress-bar/Iframe.vue';

const props = defineProps({
  campaignSlug: {
    type: String,
    require: false
  }
});

const campaign = ref(null); // Important to set to null, we use this to check if campaign settings are loaded or found at all
const showWidget = computed(() => {
  return !!campaign.value?.id;
});

const { orgId } = useState(['orgId']);
const fetchCampaign = async () => {
  const { data, error } = await request.get(`/public/campaign/${orgId.value}/${props.campaignSlug}`);
  if (error) {
    console.error('Error fetching campaign', error);
    return;
  }

  campaign.value = data;
};

const currencySymbol = computed(() => {
  const { orgDefaultCurrency } = useState('donation', ['orgDefaultCurrency']);
  return getCurrencySymbol(orgDefaultCurrency.value);
});

const goalMeter = computed(() => {
  return campaign?.value?.checkoutSettings?.goalMeter;
});

const progressPercentage = computed(() => {
  const percentage = (100 / goalMeter.value.amountTarget) * campaign.value.chargeTotal;
  return Math.min(percentage, 100);
});

const formatAnimatedNumber = (value) => {
  return value.toLocaleString();
};

const numOfDaysLeft = computed(() => {
  const endDate = goalMeter.value.endDate;
  const daysLeft = dayjs(dayjs(endDate).toISOString()).diff(dayjs(), 'days');
  return daysLeft;
});

fetchCampaign();
</script>

<template>
  <div class="double--goal-meter-widget-frame" v-if="showWidget">
    <Iframe>
      <div class="progress-bar-widget bg-white-transparent rounded-xl">
        <div class="p-5">
          <div>
            <div>
              <div>
                <span>{{ currencySymbol }}</span>
                <span class="font-bold font-nunito">
                  <animated-number :value="campaign.chargeTotal" :duration="1400" :round="true" :formatValue="(value) => value.toLocaleString()" />
                </span>
                <span class="font-nunito-sans font-medium"> Raised </span>
              </div>

              <div class="flex gap-2 items-center subtext-regular text-black-01">
                <div>
                  <span>Out of {{ currencySymbol }}</span>
                  <span class="font-bold">{{ goalMeter.amountTarget | number }}</span>
                </div>
                <div v-if="goalMeter.endDate" class="vl"></div>
                <div v-if="goalMeter.endDate">
                  <span>{{ Math.max(numOfDaysLeft, 0) }}</span> days left
                </div>
                <div class="vl"></div>

                <span>
                  <animated-number :value="campaign.donorCount" :duration="1400" :round="true" :formatValue="formatAnimatedNumber" />
                  Donors
                </span>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <app-progressbar :value="progressPercentage" :progress-color="campaign.checkoutSettings.themeColor" background-color="#091B270D" />
          </div>
        </div>
      </div>
    </Iframe>
  </div>
</template>
