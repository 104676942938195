<script setup>
import { useState, useMutations, useActions } from 'vuex-composition-helpers';
import currencies from '@/services/currencies';

const locales = [
  { value: 'en', label: 'EN' }
  // { value: 'fr', label: 'FR' },
  // { value: 'es', label: 'ES' },
  // { value: 'de', label: 'DE' }
];

const { currency } = useState('donation', ['currency']);
const { SET_CURRENCY } = useMutations('donation', ['SET_CURRENCY']);

const { setWidgetOpen } = useActions(['setWidgetOpen']);
const { settings: campaignSettings } = useState('campaign', ['settings']);

const enabledCurrencies = currencies.value.filter((c) => {
  const disabledCurrencies = campaignSettings.value?.disabledCurrencies;
  if (!Array.isArray(disabledCurrencies) || disabledCurrencies.length === 0) {
    return true;
  }

  return disabledCurrencies.indexOf(c.value) === -1;
});
</script>

<script>
export default {
  methods: {
    localeChanged(event) {
      this.$formulate.setLocale(event.target.value);
    }
  }
};
</script>

<template>
  <section class="sticky top-0 bg-secondary form-controls">
    <div class="container flex justify-between">
      <div class="flex gap-4">
        <div class="flex justify-center items-center language-dropdown">
          <!-- !TODO: need outline-icon-->
          <app-icon name="globe" type="svg" width="18" height="18" />
          <select @change="localeChanged" v-model="$i18n.locale" name="locales">
            <option v-for="locale in locales" :key="locale.value" :value="locale.value">
              {{ locale.label }}
            </option>
          </select>
        </div>
        <div class="flex justify-center items-center currency-dropdown">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g transform="translate(-1722 -256)">
              <g transform="translate(1519 227)">
                <g transform="translate(2.4 14.4)">
                  <g class="a" transform="translate(200.6 14.6)">
                    <circle class="c" cx="9" cy="9" r="9" />
                    <circle class="d" cx="9" cy="9" r="8.5" />
                  </g>
                </g>
              </g>
              <text class="b" transform="translate(1724 258)">
                <tspan x="3.7" y="11">$</tspan>
              </text>
            </g>
          </svg>
          <select v-model="currency" @change="(e) => SET_CURRENCY(e.target.value)" name="currencies">
            <option v-for="currency in enabledCurrencies" :key="currency.value" :value="currency.value">
              {{ currency.label }}
            </option>
          </select>
        </div>
      </div>
      <button @click="setWidgetOpen(false)" class="m-0 cursor-pointer">
        <icon-x class="text-white fill-current" />
      </button>
    </div>
  </section>
</template>
