<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'TotalDonation',

  computed: {
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapGetters('donation', ['totalDonation', 'chargeFees', 'totalFees']),
    ...mapState('campaign', ['settings']),

    coveringFees: {
      get() {
        return this.donation.payCoverFees;
      },
      set(value) {
        this.SET_PAY_COVER_FEES(value);
      }
    },
    frequency() {
      return this.donation.frequency;
    },
    isRecurring() {
      return this.frequency && this.frequency !== 'single';
    },
    showRecurringFees() {
      if (this.isRecurring) {
        return true;
      }
      if (this.donation.hasEndDate) {
        return this.donation.endDate || this.donation.numCharges;
      }
      return false;
    },
    paymentSummaryFormatted() {
      const payments = {
        weekly: this.$t('payment.weekPayment'),
        monthly: this.$t('payment.monthPayment'),
        yearly: this.$t('payment.yearPayment')
      };
      return payments[this.frequency];
    },
    totalDonationText() {
      const ctaTerm = this.settings?.ctaTerm?.toLowerCase();
      if (!ctaTerm) {
        return this.$t('payment.total.donate');
      }
      return this.$t(`payment.total.${ctaTerm}`);
    }
  },
  methods: {
    ...mapMutations('donation', ['SET_PAY_COVER_FEES'])
  }
};
</script>

<template>
  <div class="total-donation" :class="donation.payCoverFees ? 'bg-primary-opacity' : 'bg-off-white'">
    <transition name="expandx3">
      <div v-if="donation.amount && !settings.hideCoverFees" class="body-text-regular text-black-01 mb-2">
        <app-checkbox v-model="coveringFees">
          {{ $t('payment.coverFees') }}
          <app-price :amount="donation.hasEndDate ? chargeFees : totalFees" show-frequency />
        </app-checkbox>
        <!-- <heart-checkbox v-model="coveringFees">
          {{ $t('payment.coverFees') }}
          <app-price :amount="donation.hasEndDate ? chargeFees : totalFees" show-frequency />
        </heart-checkbox> -->
      </div>
    </transition>

    <div v-if="!donation.hasEndDate" class="flex items-center justify-between">
      <span class="body-text-extra-bold">{{ totalDonationText }}:</span>
      <app-price :amount="totalDonation" :show-frequency="isRecurring" />
    </div>

    <div v-else-if="isRecurring && donation.hasEndDate">
      <div class="flex justify-between">
        <span class="body-text-extra-bold">{{ paymentSummaryFormatted }}:</span>
        <app-price :amount="donation.amount + (donation.payCoverFees ? chargeFees : 0)" show-frequency />
      </div>
      <div class="flex justify-between subtext-small-text-semibold">
        <span>{{ totalDonationText }}:</span>
        <app-price :amount="totalDonation" />
      </div>
    </div>
  </div>
</template>
