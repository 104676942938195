// All the functions are copied from the following package: https://github.com/wuori/vue-credit-card-validation

// This function has slight changes from the original source
export const validateCardExpiry = (month, year) => {
  if (!month) {
    return false;
  }

  if (!year) {
    ({ month, year } = cardExpiryVal(month));
  }

  // Allow passing an object
  if (typeof month === 'object' && 'month' in month) {
    ({ month, year } = month);
  }

  if (!month || !year) {
    return false;
  }

  month = month.toString().trim();
  year = year.toString().trim();

  if (!/^\d+$/.test(month)) {
    return false;
  }
  if (!/^\d+$/.test(year)) {
    return false;
  }
  if (!(1 <= month && month <= 12)) {
    return false;
  }

  if (year.length === 2) {
    if (year < 70) {
      year = `20${year}`;
    } else {
      year = `19${year}`;
    }
  }

  if (year.length !== 4) {
    return false;
  }

  let expiry = new Date(year, month);
  let currentTime = new Date();

  // Months start from 0 in JavaScript
  expiry.setMonth(expiry.getMonth() - 1);

  // The cc expires at the end of the month,
  // so we need to make the expiry the first day
  // of the month after
  expiry.setMonth(expiry.getMonth() + 1, 1);

  // Format the return value as MMYY
  month = month.padStart(2, '0'); // Ensure month is 2 digits
  year = year.slice(-2); // Get the last 2 digits of the year

  return {
    isValid: expiry > currentTime,
    value: `${month}${year}`
  };
};

export const restrictNumeric = (e) => {
  // Key event is for a browser shortcut
  if (e.metaKey || e.ctrlKey) {
    return true;
  }

  // If keycode is a space
  if (e.which === 32) {
    return false;
  }

  // If keycode is a special char (WebKit)
  if (e.which === 0) {
    return true;
  }

  // If char is a special char (Firefox)
  if (e.which < 33) {
    return true;
  }

  let input = String.fromCharCode(e.which);

  // Char is a number or a space
  return /[\d\s]/.test(input) ? true : e.preventDefault();
};

export const formatExpiry = (e) => {
  // Only format if input is a number
  let digit = String.fromCharCode(e.which);
  if (!/^\d+$/.test(digit)) {
    return;
  }

  let target = e.currentTarget;
  let val = target.value + digit;

  if (/^\d$/.test(val) && !['0', '1'].includes(val)) {
    e.preventDefault();
    return setTimeout(() => (target.value = `0${val} / `));
  } else if (/^\d\d$/.test(val)) {
    e.preventDefault();
    return setTimeout(function () {
      // Split for months where we have the second digit > 2 (past 12) and turn
      // that into (m1)(m2) => 0(m1) / (m2)
      let m1 = parseInt(val[0], 10);
      let m2 = parseInt(val[1], 10);
      if (m2 > 2 && m1 !== 0) {
        return (target.value = `0${m1} / ${m2}`);
      } else {
        return (target.value = `${val} / `);
      }
    });
  }
};

export const formatForwardSlashAndSpace = (e) => {
  let which = String.fromCharCode(e.which);
  if (which !== '/' && which !== ' ') {
    return;
  }

  let target = e.currentTarget;
  let val = target.value;

  if (/^\d$/.test(val) && val !== '0') {
    return (target.value = `0${val} / `);
  }
};
export const formatForwardExpiry = (e) => {
  let digit = String.fromCharCode(e.which);
  if (!/^\d+$/.test(digit)) {
    return;
  }

  let target = e.currentTarget;
  let val = target.value;

  if (/^\d\d$/.test(val)) {
    return (target.value = `${val} / `);
  }
};

export const formatBackExpiry = (e) => {
  let target = e.currentTarget;
  let value = target.value;

  // Return unless backspacing
  if (e.which !== 8) {
    return;
  }

  // Return if focus isn't at the end of the text
  if (target.selectionStart != null && target.selectionStart !== value.length) {
    return;
  }

  // Remove the trailing space + last digit
  if (/\d\s\/\s$/.test(value)) {
    e.preventDefault();
    return setTimeout(() => (target.value = value.replace(/\d\s\/\s$/, '')));
  }
};

export const reFormatExpiry = (e) => {
  let target = e.currentTarget;
  return setTimeout(function () {
    let value = target.value;
    value = replaceFullWidthChars(value);
    value = validationFormatExpiry(value);
    return safeVal(value, target, e);
  });
};

// helpers
const cardExpiryVal = (value) => {
  let [month, year] = Array.from(value.split(/[\s/]+/, 2));

  // Allow for year shortcut
  if ((year != null ? year.length : undefined) === 2 && /^\d+$/.test(year)) {
    let prefix = new Date().getFullYear();
    prefix = prefix.toString().slice(0, 2);
    year = prefix + year;
  }

  month = parseInt(month, 10);
  year = parseInt(year, 10);

  return { month, year };
};
const validationFormatExpiry = (expiry) => {
  let parts = expiry.match(/^\D*(\d{1,2})(\D+)?(\d{1,4})?/);
  if (!parts) {
    return '';
  }

  let mon = parts[1] || '';
  let sep = parts[2] || '';
  let year = parts[3] || '';

  if (year.length > 0) {
    sep = ' / ';
  } else if (sep === ' /') {
    mon = mon.substring(0, 1);
    sep = '';
  } else if (mon.length === 2 || sep.length > 0) {
    sep = ' / ';
  } else if (mon.length === 1 && !['0', '1'].includes(mon)) {
    mon = `0${mon}`;
    sep = ' / ';
  }

  return mon + sep + year;
};
const replaceFullWidthChars = (str) => {
  if (str == null) {
    str = '';
  }
  let fullWidth = '\uff10\uff11\uff12\uff13\uff14\uff15\uff16\uff17\uff18\uff19';
  let halfWidth = '0123456789';

  let value = '';
  let chars = str.split('');

  // Avoid using reserved word `char`
  // Avoid using for-in loop to prevent iteration over prototype chain properties
  for (let i = 0; i < chars.length; i++) {
    let idx = fullWidth.indexOf(chars[i]);
    if (idx > -1) {
      chars[i] = halfWidth[idx];
    }
    value += chars[i];
  }

  return value;
};
const safeVal = (value, target, e) => {
  if (e.inputType === 'deleteContentBackward') {
    return;
  }
  let cursor;
  try {
    cursor = target.selectionStart;
  } catch (error) {
    cursor = null;
  }
  let last = target.value;
  target.value = value;
  value = target.value;
  if (cursor !== null && document.activeElement == target) {
    if (cursor === last.length) {
      cursor = target.value.length;
    }

    // This hack looks for scenarios where we are changing an input's value such
    // that "X| " is replaced with " |X" (where "|" is the cursor). In those
    // scenarios, we want " X|".
    //
    // For example:
    // 1. Input field has value "4444| "
    // 2. User types "1"
    // 3. Input field has value "44441| "
    // 4. Reformatter changes it to "4444 |1"
    // 5. By incrementing the cursor, we make it "4444 1|"
    //
    // This is awful, and ideally doesn't go here, but given the current design
    // of the system there does not appear to be a better solution.
    //
    // Note that we can't just detect when the cursor-1 is " ", because that
    // would incorrectly increment the cursor when backspacing, e.g. pressing
    // backspace in this scenario: "4444 1|234 5".
    if (last !== value) {
      let prevPair = last.slice(cursor - 1, +cursor + 1 || undefined);
      let currPair = target.value.slice(cursor - 1, +cursor + 1 || undefined);
      let digit = value[cursor];
      if (/\d/.test(digit) && prevPair === `${digit} ` && currPair === ` ${digit}`) {
        cursor = cursor + 1;
      }
    }

    target.selectionStart = cursor;
    return (target.selectionEnd = cursor);
  }
};
