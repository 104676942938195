import store from '@/store';

const THROTTLE_TIME_MS = 3000;
const WIDGET_ACTIONS = {
  success: 'Double Successful Donation',
  error: 'Error Donation',
  opened: 'Double Widget Opened',
  closed: 'Double Widget Closed'
};

let lastEventAction = null;

export default function (data) {
  const { gAnalyticsId, parentTrackingEnabled } = store.state.publicOrgSettings.settings;
  const { website } = store.state.publicOrgSettings.orgDetails; // URL should be normalized whenever saved to the database

  // debounce event tracking of same action
  if (lastEventAction === data.action) {
    return;
  }
  lastEventAction = data.action;

  setTimeout(() => {
    lastEventAction = null;
  }, THROTTLE_TIME_MS);

  if (!gAnalyticsId && !parentTrackingEnabled) {
    return;
  }

  // Create a Universal Analytics event
  const event = {
    name: 'double',
    action: WIDGET_ACTIONS[data.action],
    parameters: {
      category: 'Double Widget Events',
      ...(data.label ? { label: data.label } : {}), // optional
      ...(data.value ? { value: data.value } : {}) // optional
    }
  };

  if (gAnalyticsId && !parentTrackingEnabled && window.gtag) {
    window.gtag('event', event.action, event.parameters);
  } else if (parentTrackingEnabled && window.gtag) {
    window.parent.postMessage(event, website);
  }
}
