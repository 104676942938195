import { get, pick, cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import request from '@/services/request';
import StripeService from '@/services/stripe';
import CardknoxService from '@/services/cardknox';

import { GATEWAYS, PAYMENT_METHODS, getCurrencySymbol } from '@/utils';
import { currencies } from '@/services/currencies';
import { getSentryScope } from '@/services/sentry';
const DEFAULT_CURRENCY = 'usd';
// Should be defined on the server, but added here as a fallback
const DEFAULT_FEES = {
  fixedAmount: { cc: 0.3, plaid: 0.3 },
  percentageAmount: { cc: 2.9, plaid: 0.8 }
};

export const state = {
  amount: null,
  amountDescription: null,
  gateway: null, // actual gateway used
  CCGateway: null, // cc gateway based on conditions
  paymentMethod: null, // PAYMENT_METHODS
  cardExpiry: null, // cardknox card
  accountName: null, // cardknox ach
  routingNumber: null, // cardknox ach
  campaignId: null,
  currency: DEFAULT_CURRENCY,
  orgDefaultCurrency: null,
  entityId: null,
  frequency: null, // 'single' | 'weekly' | 'monthly' | 'yearly'
  recurringType: null, // 'byCharges', 'byDate'
  hasEndDate: false,
  startDate: null,
  endDate: null,
  numCharges: null,
  payCoverFees: false,
  customQuestions: [],
  comment: null,
  anonymous: false,
  fundraiserId: null,
  designation: null,
  isDedicating: false,
  dedication: {},
  donorDetails: {},
  billingAddress: {},
  stripeIntent: {},
  chariotIntent: {},
  cardknoxTokens: {},
  donationId: null,
  plaidLinkToken: null,
  plaidPublicToken: null,
  plaidData: null,
  recaptchaToken: null,
  donationDraftId: null,
  utmParameters: {},
  sourceUrl: null
};

export const mutations = {
  SET_DESIGNATION(state, newValue) {
    state.designation = newValue;
  },
  SET_AMOUNT(state, newValue) {
    if (!newValue) {
      return;
    }
    state.amount = Number(newValue.toFixed(2));
  },
  SET_AMOUNT_DESCRIPTION(state, newValue) {
    state.amountDescription = newValue;
  },
  SET_GATEWAY(state, newValue) {
    state.gateway = newValue;
  },
  SET_CC_GATEWAY(state, newValue) {
    state.CCGateway = newValue;
  },
  SET_PAYMENT_METHOD(state, newValue) {
    if (!Object.values(PAYMENT_METHODS).includes(newValue)) {
      throw new Error('Invalid payment method!');
    }
    state.paymentMethod = newValue;
  },
  SET_CAMPAIGN_ID(state, newValue) {
    state.campaignId = newValue;
  },
  SET_CURRENCY(state, newValue) {
    const isSupportedCurrency = currencies.value.some((currency) => currency.value === newValue);
    state.currency = isSupportedCurrency ? newValue : DEFAULT_CURRENCY;
  },
  SET_CARD_EXPIRY(state, newValue) {
    state.cardExpiry = newValue;
  },
  SET_ORG_DEFAULT_CURRENCY(state, newValue) {
    state.orgDefaultCurrency = newValue;
  },
  SET_ENTITY_ID(state, newValue) {
    state.entityId = newValue;
  },
  SET_FREQUENCY(state, newValue) {
    state.frequency = newValue;
  },
  SET_RECURRING_TYPE(state, newValue) {
    state.recurringType = newValue;
  },
  SET_START_DATE(state, newValue) {
    state.startDate = newValue;
  },
  SET_HAS_END_DATE(state, newValue) {
    state.hasEndDate = newValue;
  },
  SET_END_DATE(state, newValue) {
    state.endDate = newValue;
    state.numCharges = calculateNumChargesByEndDate(state.endDate, momentStrings[state.frequency]);
  },
  SET_NUM_CHARGES(state, newValue) {
    state.numCharges = newValue;
    state.endDate = calculateEndDateByCharges(state.numCharges, momentStrings[state.frequency]);
  },
  SET_PAY_COVER_FEES(state, newValue) {
    state.payCoverFees = newValue;
  },
  SET_CUSTOM_QUESTIONS(state, newValue) {
    state.customQuestions = newValue;
  },
  SET_COMMENT(state, newValue) {
    state.comment = newValue;
  },
  SET_FUNDRAISER_ID(state, newValue) {
    state.fundraiserId = newValue;
  },
  SET_IS_DONATING_ANONYMOUSLY(state, newValue) {
    state.anonymous = newValue;
  },
  SET_IS_DEDICATING(state, newValue) {
    state.isDedicating = newValue;
  },
  SET_DEDICATION(state, newValue) {
    state.dedication = newValue;
  },

  RESET_STATE(state) {
    const s = initialStateCopy;
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_DONOR_DETAILS(state, newValue) {
    getSentryScope().setUser(newValue);
    state.donorDetails = newValue;
  },
  SET_BILLING_ADDRESS(state, newValue) {
    state.billingAddress = newValue;
  },
  SET_DONOR_DETAILS_EMAIL(state, newValue) {
    state.donorDetails.email = newValue;
  },
  SET_DONOR_DETAILS_PHONE(state, newValue) {
    state.donorDetails.phone = newValue;
  },
  SET_STRIPE_INTENT(state, newValue) {
    state.stripeIntent = newValue;
  },
  SET_CHARIOT_INTENT(state, newValue) {
    state.chariotIntent = newValue;
  },
  SET_CARDKNOX_TOKENS(state, newValue) {
    state.cardknoxTokens = newValue;
  },
  SET_DONATION_ID(state, newValue) {
    state.donationId = newValue;
  },
  SET_PLAID_LINK_TOKEN(state, newValue) {
    state.plaidLinkToken = newValue;
  },
  SET_PLAID_DATA(state, newValue) {
    state.plaidData = newValue;
  },
  SET_RECAPTCHA_TOKEN(state, newValue) {
    state.recaptchaToken = newValue;
  },
  SET_DRAFT_ID(state, newValue) {
    state.donationDraftId = newValue;
  },
  SET_UTM_PARAMETERS(state, newValue) {
    state.utmParameters = newValue;
  },
  SET_SOURCE_URL(state, newValue) {
    state.sourceUrl = newValue;
  }
};

export const getters = {
  persistentDonationData(state) {
    return pick(state, [
      'amount',
      'frequency',
      'recurringType',
      'hasEndDate',
      'startDate',
      'endDate',
      'numCharges',
      'payCoverFees',
      'anonymous',
      'isDedicating',
      'dedication',
      'donorDetails.email'
    ]);
  },
  totalAmount(state) {
    if (state.frequency === 'single' || !state.hasEndDate) {
      return state.amount;
    }
    let numCharges;
    if (state.recurringType === 'byCharges') {
      numCharges = state.numCharges;
    } else if (state.recurringType === 'byDate') {
      if (state.endDate) {
        numCharges = calculateNumChargesByEndDate(state.endDate, momentStrings[state.frequency]);
        // end-date is not set yet
      } else {
        numCharges = 0;
      }
    }
    const totalAmount = state.amount * numCharges;
    if (totalAmount < 0) {
      return 0;
    }
    return totalAmount;
  },
  chargeFees(state, _, rootState) {
    const amount = state.amount;
    // We now have a single fee option, currently we still use cc
    const { fixedAmount: fixedFees, percentageAmount: percentageFees } =
      get(rootState.publicOrgSettings, 'settings.fees') || get(rootState.publicOrgSettings, 'settings.fees') || DEFAULT_FEES;

    // https://stackoverflow.com/a/37077952/10679649 (see also: https://stackoverflow.com/a/42984392/10679649)
    const totalIncludingFees = Number((amount + fixedFees.cc) / (1 - percentageFees.cc / 100));

    // Chariot supports only round numbers, so we round up the fees and not the amount
    if (state.paymentMethod === 'chariot') {
      return Math.ceil(totalIncludingFees) - amount;
    }

    return totalIncludingFees - amount;
  },
  totalFees(state, getters) {
    const chargeFees = getters.chargeFees;
    if (state.frequency === 'single' || !state.hasEndDate) {
      return chargeFees;
    } else {
      let numCharges;
      if (state.recurringType === 'byCharges') {
        numCharges = state.numCharges;
      } else if (state.recurringType === 'byDate') {
        if (state.endDate) {
          numCharges = calculateNumChargesByEndDate(state.endDate, momentStrings[state.frequency]);
          // end-date is not set yet
        } else {
          numCharges = 0;
        }
      }
      return chargeFees * numCharges;
    }
  },
  isCCDonation(state) {
    return [PAYMENT_METHODS.CARD, PAYMENT_METHODS.GPAY, PAYMENT_METHODS.APPLE_PAY].includes(state.paymentMethod);
  },
  totalDonation(state, getters) {
    if (!getters.totalAmount) {
      return 0;
    }

    if (state.payCoverFees) {
      return getters.totalAmount + getters.totalFees;
    }
    return getters.totalAmount;
  },
  amount(state) {
    return state.amount;
  },
  amountPlusFees(state, getters) {
    return state.amount + (state.payCoverFees ? getters.chargeFees : 0);
  },
  currencySymbol({ currency }) {
    return getCurrencySymbol(currency);
  },
  isRecurring(state) {
    return state.frequency !== 'single';
  },
  isOneTime(state) {
    return state.frequency === 'single';
  },
  donorFirstName(state) {
    return state.donorDetails.firstName;
  },
  isUsingElementFields(state) {
    if (state.CCGateway.provider === GATEWAYS.STRIPE) {
      return state.paymentMethod === PAYMENT_METHODS.CARD;
    }

    if (state.CCGateway.provider === GATEWAYS.CARDKNOX) {
      return state.paymentMethod === PAYMENT_METHODS.CARD || state.paymentMethod === PAYMENT_METHODS.ACH;
    }
    return false;
  },
  getGatewayByCurrency: (_state, _getters, rootState) => (currency) => {
    const entityForCurrency = rootState.publicOrgSettings.gateways?.find((gateway) => gateway.currency === currency);
    if (entityForCurrency) {
      return entityForCurrency;
    }

    const primaryEntity = rootState.publicOrgSettings.gateways?.find((gateway) => gateway.isPrimary);
    if (!primaryEntity) {
      throw new Error('No gateway found!');
    }

    return primaryEntity;
  }
};

export const actions = {
  SET_FREQUENCY_ACTIONS({ commit }, newValue) {
    const isValidValue = ['single', 'weekly', 'monthly', 'yearly'].includes(newValue);
    if (!isValidValue) {
      return;
    }
    commit('SET_FREQUENCY', newValue);
    if (newValue === 'single') {
      commit('SET_START_DATE', null);
      commit('SET_END_DATE', null);
      commit('SET_NUM_CHARGES', null);
      commit('SET_HAS_END_DATE', null);
    } else {
      commit('SET_START_DATE', dayjs().toISOString());
    }
  },
  SET_CURRENCY_ACTIONS({ commit, getters }, newValue) {
    const isSupportedCurrency = currencies.value.some((currency) => currency.value === newValue);
    state.currency = isSupportedCurrency ? newValue : DEFAULT_CURRENCY;
    commit('SET_CURRENCY', isSupportedCurrency ? newValue : DEFAULT_CURRENCY);

    // Todo: make more robust
    const gateway = getters.getGatewayByCurrency(newValue);
    commit('SET_CC_GATEWAY', gateway);
    commit('SET_GATEWAY', gateway.provider);
    commit('SET_ENTITY_ID', gateway.entityId);
  },
  /** gets the intentId & donationId to be used for confirming payment details */
  async createDonation({ state, rootState, getters, commit }) {
    const origDonation = cloneDeep(state);
    const fees = {
      chargeFees: getters.chargeFees,
      feesList: get(rootState.publicOrgSettings, 'settings.fees') || []
    };
    const donation = completeDonationDetails(origDonation, fees);
    const { data, error } = await request.post(`/public/donation/${rootState.orgId}/`, { donation });
    if (!error) {
      const { plaidLinkToken, donationId, client_secret: clientSecret } = data;

      switch (true) {
        case [PAYMENT_METHODS.CARD, PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.GPAY].includes(state.paymentMethod):
          commit('SET_STRIPE_INTENT', { clientSecret });
          commit('SET_DONATION_ID', donationId);
          break;
        case state.paymentMethod === PAYMENT_METHODS.ACH:
          commit('SET_PLAID_LINK_TOKEN', plaidLinkToken);
          commit('SET_DONATION_ID', donationId);
          break;
      }
    } else {
      throw new Error(data);
    }
  },

  async donateWithCardknox({ rootState, getters, commit }) {
    const cardknox = await CardknoxService.getCardknoxObject();

    const getTokens = () => {
      return new Promise((resolve, reject) => {
        cardknox.getTokens(() => {
          try {
            const widgetIframe = document.querySelector('iframe[name="double-checkout"]');
            const cardToken = widgetIframe.contentDocument.querySelector("[data-ifields-id='card-number-token']").value;
            const cvvToken = widgetIframe.contentDocument.querySelector("[data-ifields-id='cvv-token']").value;

            resolve({ cardToken, cvvToken });
          } catch (error) {
            reject(error);
          }
        });
      });
    };

    try {
      const { cardToken, cvvToken } = await getTokens();
      commit('SET_CARDKNOX_TOKENS', { cardToken, cvvToken });

      const fees = {
        chargeFees: getters.chargeFees,
        feesList: get(rootState.publicOrgSettings, 'settings.fees') || []
      };
      const origDonation = cloneDeep(state);
      const donation = completeDonationDetails(origDonation, fees);

      const { data, error } = await request.post(`/public/donation/${rootState.orgId}/`, {
        donation,
        gateway: GATEWAYS.CARDKNOX
      });

      if (!error) {
        return data;
      }

      throw new Error(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async donateWithCardknoxACH({ rootState, getters, commit }) {
    const cardknox = await CardknoxService.getCardknoxObject();

    const getTokens = () => {
      return new Promise((resolve, reject) => {
        cardknox.getTokens(() => {
          try {
            const widgetIframe = document.querySelector('iframe[name="double-checkout"]');
            const achToken = widgetIframe.contentDocument.querySelector("[data-ifields-id='ach-token']").value;
            resolve({ achToken });
          } catch (error) {
            reject(error);
          }
        });
      });
    };

    try {
      const { achToken } = await getTokens();
      commit('SET_CARDKNOX_TOKENS', { achToken });

      const fees = {
        chargeFees: getters.chargeFees,
        feesList: get(rootState.publicOrgSettings, 'settings.fees') || []
      };
      const origDonation = cloneDeep(state);
      const donation = completeDonationDetails(origDonation, fees);

      const { data, error } = await request.post(`/public/donation/${rootState.orgId}/`, {
        donation,
        gateway: GATEWAYS.CARDKNOX
      });

      if (!error) {
        return data;
      }

      throw new Error(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async donateWithGrow({ state, getters, rootState, commit }) {
    const fees = {
      chargeFees: getters.chargeFees,
      feesList: get(rootState.publicOrgSettings, 'settings.fees') || []
    };

    const origDonation = cloneDeep(state);
    const donation = completeDonationDetails(origDonation, fees);

    const { data, error } = await request.post(`/public/donation/${rootState.orgId}/`, { donation });
    const { donationId, token } = data;
    commit('SET_DONATION_ID', donationId);
    if (!error) {
      return token;
    } else {
      throw new Error(data);
    }
  },

  async donateWithChariot({ getters, rootState }) {
    const fees = {
      chargeFees: getters.chargeFees,
      feesList: get(rootState.publicOrgSettings, 'settings.fees') || []
    };
    const origDonation = cloneDeep(state);
    const donation = completeDonationDetails(origDonation, fees);

    const { data, error } = await request.post(`/public/donation/${rootState.orgId}/`, { donation });

    if (!error) {
      return data;
    } else {
      throw new Error(data);
    }
  },

  async confirmPaymentStripeElements({ getters, commit }, stripeElement) {
    return confirmPaymentStripe({ getters, commit }, stripeElement);
  },

  async confirmStripePaymentRequest({ getters, commit }, paymentRequestId) {
    return confirmPaymentStripe({ getters, commit }, null, paymentRequestId);
  },

  async confirmDonation({ state, getters, rootState }) {
    const donation = state;
    if (!donation.donationId || !get(donation, 'stripeIntent.status') === 'succeeded') {
      throw new Error('Calling confirmDonation without successful stripeIntent & donationId');
    }

    const { data, error } = await request.post(`/public/donation/${rootState.orgId}/${state.donationId}/confirm`, {
      gateway: GATEWAYS.STRIPE,
      paymentMethod: state.paymentMethod,
      data: getters.isCCDonation ? donation.stripeIntent : donation.plaidData
    });

    if (error) {
      throw new Error(data);
    }
    return data;
  }
};

const initialStateCopy = JSON.parse(JSON.stringify(state));
const momentStrings = { weekly: 'weeks', monthly: 'months', yearly: 'years' };

function completeDonationDetails(donation, fees) {
  const chargeFees = donation.payCoverFees ? fees.chargeFees : 0;
  const now = dayjs().toISOString();

  // Fees
  donation.coverFeesAmount = Number(chargeFees.toFixed(2));
  donation.feesList = fees.feesList;

  if (donation.frequency === 'single') {
    // reset dates in case it was set
    donation.startDate = null;
    donation.endDate = null;
    donation.numCharges = null;
  } else if (donation.frequency !== 'single') {
    donation.amount = Number(donation.amount.toFixed(2));
    donation.startDate = now;
    if (!donation.numCharges) {
      // Reset end date
      donation.endDate = null;
    }
  }
  return donation;
}

function calculateEndDateByCharges(numCharges, frequency) {
  if (!numCharges) {
    return null;
  }
  return dayjs().add(numCharges, frequency).toISOString();
}

function calculateNumChargesByEndDate(endDate, frequency) {
  if (!endDate) {
    return null;
  }
  return dayjs(dayjs(endDate).endOf('day').toISOString()).diff(dayjs(), frequency) + 1; // always charge at least 1
}

async function confirmPaymentStripe({ commit }, card, paymentRequestId = null) {
  const donation = state;
  let stripe;
  if (paymentRequestId) {
    // Using payment request outside iframe
    stripe = await StripeService.getStripeObject(true);
  } else {
    // for elements
    stripe = await StripeService.getStripeObject();
  }

  const confirm = donation.frequency !== 'single' ? stripe.confirmCardSetup : stripe.confirmCardPayment;
  // will get either paymentIntent or setupIntent (or error)
  const { paymentIntent, setupIntent, error } = await confirm(donation.stripeIntent.clientSecret, {
    payment_method: paymentRequestId || {
      card,
      billing_details: {
        address: {
          postal_code: get(donation.donorDetails, 'zip') // TODO: more?
        }
      }
    }
  });
  getSentryScope().addBreadcrumb({
    category: 'stripe',
    message: 'confirmPaymentStripe response',
    data: { paymentIntent, setupIntent, error }
  });
  if (paymentIntent || setupIntent) {
    commit('SET_STRIPE_INTENT', paymentIntent || setupIntent);
  }
  if (error) {
    throw new Error(error.message);
  }
}
