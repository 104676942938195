<script>
import Vue from 'vue';
import store from '@/store';
import { mapState } from 'vuex';
import css from '!!raw-loader!sass-loader!@/assets/css/toaster.scss';
import nunito from '!!raw-loader!@/assets/fonts/nunito.css';

export default {
  name: 'LauncherIframe',
  props: {
    showToaster: Boolean
  },
  data: () => ({
    iframeStyles: {
      width: '400px', // initial state and fallback
      margin: 0
    }
  }),
  render(h) {
    return h('iframe', {
      on: { load: this.renderChildren },
      attrs: {
        src: 'about:blank',
        name: 'double-toaster',
        title: 'Double Toaster',
        frameborder: 0
      },
      style: this.iframeStyles
    });
  },

  computed: {
    ...mapState('publicOrgSettings', ['settings'])
  },

  methods: {
    renderChildren() {
      const children = this.$slots.default;
      const body = this.$el.contentDocument.body;
      const el = document.createElement('DIV'); // we will mount or nested app to this element
      body.appendChild(el);
      body.style.margin = 0;

      const ToasterIframe = new Vue({
        name: 'ToasterIframe',
        store,
        // freezing to prevent unnecessary reactifiation of vNodes
        data: { children: Object.freeze(children) },
        render(h) {
          return h('div', this.children);
        }
      });

      ToasterIframe.$mount(el); // mount into iframe

      this.ToasterIframe = ToasterIframe; // cache instance for later updates

      this.applyCss();
    },

    applyCss() {
      const iframe = this.$el.contentDocument;
      const styleElement = document.createElement('style');
      styleElement.textContent = css + nunito;
      iframe.head.appendChild(styleElement);
    },
    getToasterWidth() {
      // get computed toaster width and add 10px
      return;
    },
    async constructIframeWidth() {
      // This is a hack to get the iframe width to be the same as the toaster width
      const iframeParent = this.$el.parentElement;
      // Hide toaster iframe parent while we calculate it's computed width
      iframeParent.style.opacity = 0;
      // calculate width
      const toasterComputedWidth = await new Promise((resolve) =>
        setTimeout(() => {
          const iframe = this.$el.contentDocument;
          const toasterElement = iframe.querySelector('.widget-toaster');
          if (!toasterElement) {
            return 600;
          }
          const elementWidth = toasterElement.offsetWidth;
          return resolve(elementWidth);
        }, 50)
      );
      // Add 10%
      const frameWidth = toasterComputedWidth + toasterComputedWidth / 10;
      const windowWith = window.innerWidth;
      this.iframeStyles.width = Math.min(frameWidth, windowWith - 30) + 'px';
      // Show toaster iframe parent
      iframeParent.style.opacity = 1;
    }
  },

  beforeUpdate() {
    // freezing to prevent unnecessary reactifiation of vNodes
    if (!this.ToasterIframe) {
      return;
    }
    this.ToasterIframe.children = Object.freeze(this.$slots.default);
  },

  async mounted() {
    this.constructIframeWidth();
  }
};
</script>
