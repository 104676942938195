<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import request from '@/services/request';
import ADatePicker from '@/components/DatePicker.vue';

export default {
  name: 'CustomQuestions',
  props: {
    active: Boolean,
    fieldsDirty: Boolean
  },
  components: {
    ADatePicker
  },
  data() {
    return {
      reservedDates: [],
      touched: {}
    };
  },

  computed: {
    ...mapGetters('validation', ['isCustomQuestionsValid']),
    ...mapState(['orgId', 'datePickerLibraryLoaded']),
    ...mapState('validation', ['isCustomQuestionsValid']),
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapState('campaign', ['id']),
    customQuestions() {
      return this.donation.customQuestions || [];
    },
    customQuestionsComputed() {
      return this.donation.customQuestions.filter((customQuestion) => {
        const { ifAmount, ifAmountDescription } = customQuestion;
        const { amount, amountDescription } = this.donation;

        if (!ifAmount && !ifAmountDescription) {
          return true;
        }

        return (ifAmount && ifAmount === amount) || (ifAmountDescription && ifAmountDescription === amountDescription);
      });
    },
    customQuestionsAndAmount() {
      return [this.customQuestionsComputed, this.donation.amount];
    }
  },

  watch: {
    customQuestions: {
      handler(value) {
        this.SET_CUSTOM_QUESTIONS(value);
      },
      deep: true,
      immediate: true
    },
    'donation.amount': {
      handler() {
        this.resetAllAnswers();
      }
    },
    customQuestionsAndAmount: {
      handler() {
        this.SET_IS_CUSTOM_QUESTIONS_VALID(this.checkValidity());
      },
      deep: true,
      immediate: true
    },
    fieldsDirty(value) {
      if (value) {
        this.touched = Object.keys(this.touched).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});
        this.$refs.hiddenSubmitButton?.click();
      }
    }
  },

  methods: {
    ...mapMutations('donation', ['SET_CUSTOM_QUESTIONS']),
    ...mapMutations('validation', ['SET_IS_CUSTOM_QUESTIONS_VALID']),
    currentQuestion(question) {
      return this.customQuestions.find((customQuestion) => customQuestion.question === question);
    },
    resetAllAnswers() {
      this.customQuestions
        .filter((customQuestion) => customQuestion.ifAmount || customQuestion.ifAmountDescription)
        .forEach((customQuestion) => {
          if (customQuestion.type === 'checkbox') {
            return (customQuestion.answer = customQuestion.checkedByDefault ? true : false);
          }
          customQuestion.answer = '';
        });
    },
    checkValidity() {
      return this.customQuestionsComputed.filter((question) => question.required).every((question) => question.answer);
    },
    isTouched(question) {
      return !!this.touched[question];
    }
  },
  async created() {
    if (this.donation.customQuestions.some((q) => q.type === 'date')) {
      const { data } = await request(`/public/reserved-dates/${this.orgId}/${this.id}`);
      this.reservedDates = data;
    }
    this.touched = this.customQuestions.reduce((acc, question) => {
      acc[question.question] = false;
      return acc;
    }, {});
  }
};
</script>

<template>
  <div class="custom-question" v-if="customQuestions.length">
    <formulate-form>
      <transition-group name="expandx2" :duration="300">
        <div v-for="(customQuestion, index) in customQuestionsComputed" :key="index" :class="{ 'mt-8': index !== 0 }">
          <h4 class="body-text-extra-bold" v-if="customQuestion.type !== 'checkbox'">{{ customQuestion.question }}</h4>
          <div class="question-answer">
            <formulate-input
              v-if="customQuestion.type === 'shortText'"
              v-model="currentQuestion(customQuestion.question).answer"
              :validation="customQuestion.required ? 'required' : ''"
              type="text"
              placeholder="Your message"
            />
            <formulate-input
              v-else-if="customQuestion.type === 'longText'"
              v-model="currentQuestion(customQuestion.question).answer"
              :validation="customQuestion.required ? 'required' : ''"
              type="textarea"
              placeholder="Your message"
            />
            <div v-else-if="customQuestion.type === 'enum'">
              <app-select
                :key="customQuestion.question"
                v-model="currentQuestion(customQuestion.question).answer"
                @open="touched[currentQuestion(customQuestion.question).question] = true"
                :searchable="false"
                :options="customQuestion.answerSelections"
                :multiple="customQuestion.allowMultiSelect"
                :placeholder="$t('customQuestion.placeholder')"
              />
              <p
                v-if="customQuestion.required && !currentQuestion(customQuestion.question).answer && isTouched(customQuestion.question)"
                class="text-red text-xxs mt-1"
              >
                Please choose an optiong
              </p>
            </div>
            <template v-else-if="customQuestion.type === 'checkbox'">
              <app-checkbox v-model="currentQuestion(customQuestion.question).answer" type="checkbox" :validation="customQuestion.required ? 'required' : ''">{{
                customQuestion.question
              }}</app-checkbox>
              <p
                v-if="customQuestion.required && !currentQuestion(customQuestion.question).answer && isTouched(customQuestion.question)"
                class="text-red text-xxs mt-1"
              >
                Please press the checkbox
              </p>
            </template>
            <template v-else-if="customQuestion.type === 'date' && datePickerLibraryLoaded">
              <a-date-picker
                placeholder="Select a date"
                :reserved-dates="reservedDates"
                :hebrew-dates="customQuestion.dateType === 'hebrew'"
                @date-selected="currentQuestion(customQuestion.question).answer = $event"
              />
              <p
                v-if="customQuestion.required && !currentQuestion(customQuestion.question).answer && isTouched(customQuestion.question)"
                class="text-red text-xxs mt-1"
              >
                Please select a date
              </p>
            </template>
          </div>
        </div>
      </transition-group>
      <button ref="hiddenSubmitButton" class="hidden"></button>
    </formulate-form>
  </div>
</template>
