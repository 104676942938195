<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { useActions } from 'vuex-composition-helpers';
import LottieAnimation from 'lottie-web-vue';

import { widgetViews } from '@/utils';
import balloonsData from '@/assets/balloons-lottie-bg.json';
import FormLayout from '@/layouts/FormLayout.vue';
import DtdMatchPlugin from '@/components/double-the-donation/DtdMatchPlugin';

export default {
  name: 'ThankYou',
  components: {
    FormLayout,
    DtdMatchPlugin,
    LottieAnimation
  },

  data() {
    return {
      widgetViews,
      balloonsData
    };
  },

  computed: {
    ...mapState(['widgetView']),
    ...mapGetters('donation', ['amountPlusFees', 'donorFirstName', 'currencySymbol', 'isRecurring']),
    ...mapGetters('fundraiser', ['fundraiserFullName']),
    ...mapState('donation', ['hasEndDate', 'donationId']),
    ...mapState('publicOrgSettings', {
      globalSettings: (state) => state.settings,
      orgDetails: (state) => state.orgDetails
    }),
    ...mapState('campaign', ['settings', 'slug']),

    ...mapState('fundraiser', {
      fundraiser: (state) => state
    }),
    ...mapState('fundraiserSignup', {
      fundraiserSignup: (state) => state
    }),
    thankYouMessageFormatted() {
      if (this.isFundraiserSignup) {
        return this.$t('fundraiser.signupConfirmation');
      }

      return this.settings?.thankYouMessage?.replace('<%sum%>', '');
    },
    isDonationViaFundraiser() {
      const isDonation = this.donationId;
      return isDonation && this.fundraiser.id;
    },
    firstName() {
      if (this.isFundraiserSignup) {
        return this.fundraiserSignup.form.firstName;
      }

      return this.donorFirstName;
    },
    donationAmountFormatted() {
      return this.amountPlusFees ? this.$options.filters.currency(this.amountPlusFees, this.currencySymbol) : '';
    },
    showFrequency() {
      // Show frequency for recurring donations without end date
      return this.isRecurring && !this.hasEndDate;
    },
    twitterShareLink() {
      const params = this.socialMediaShareParams('twitter');
      // &via=double
      return `${params.url}text=${params.text}&url=${params.websiteUrl}?campaign=${this.slug}`;
    },
    facebookShareLink() {
      const params = this.socialMediaShareParams('facebook');
      return `${params.url}t=${params.text}&u=${params.websiteUrl}?campaign=${this.slug}`;
    },
    whatsappShareLink() {
      const params = this.socialMediaShareParams('whatsapp');
      return `${params.url}text=${params.text} ${params.websiteUrl}?campaign=${this.slug}`;
    },
    dtdIntegrationEnabled() {
      const dtd = this.globalSettings.doubleTheDonation;
      return dtd && dtd.enabled && dtd.publicKey;
    },
    isFundraiserSignup() {
      return this.widgetView === widgetViews.CONFIRMATION_FUNDRAISER;
    }
  },

  methods: {
    ...mapMutations(['SET_WIDGET_VIEW']),
    socialMediaShareParams(platformName) {
      const urlList = {
        twitter: '//twitter.com/intent/tweet?',
        facebook: '//www.facebook.com/sharer/sharer.php?',
        whatsapp: '//wa.me/?'
      };

      if (!urlList[platformName]) {
        return console.warn('Platform not found!');
      }

      const websiteUrl = encodeURIComponent(`${this.orgDetails.website}`);
      const text = encodeURIComponent(`I have just donated ${this.donationAmountFormatted} for ${this.orgDetails.name}`);
      const urlParams = {
        url: urlList[platformName],
        websiteUrl,
        text
      };

      return urlParams;
    }
  },

  async mounted() {
    // Scroll to top
    const iframe = document.querySelector('iframe[name="double-checkout"]');
    iframe.contentWindow.scrollTo(0, 0);

    if (this.dtdIntegrationEnabled && window.doublethedonation) {
      window.doublethedonation.plugin.set_donation_identifier(this.donationId);
    }
  }
};
</script>

<script setup>
const { setWidgetOpen } = useActions(['setWidgetOpen']);
</script>

<template>
  <form-layout hide-header class="thank-you">
    <div class="close-button">
      <button @click="setWidgetOpen(false)" class="cursor-pointer">
        <icon-x class="text-primary fill-current" />
      </button>
    </div>
    <section>
      <lottie-animation id="lottie" class="animation lottie mb-12 mx-auto" :animation-data="balloonsData" :auto-play="true" :loop="true" :speed="1" />
      <div class="text-center">
        <h1 class="special-title text-primary" data-cy="thank-you-title">{{ $t('confirmation.thanks') }}{{ firstName ? `, ${firstName}` : '' }}!</h1>
        <h2 v-if="!isFundraiserSignup" class="font-bold w-72 mx-auto mt-2 text-black-00">
          Your
          <app-price :amount="amountPlusFees" :show-frequency-suffix="isRecurring" />
          donation has been received
        </h2>
      </div>
      <div class="mt-3 text-center text-black-01">
        <p class="body-text font-bold px-12">{{ thankYouMessageFormatted }}</p>
      </div>
      <dtd-match-plugin v-if="dtdIntegrationEnabled && !isFundraiserSignup" />
    </section>

    <!-- <button @click="SET_WIDGET_VIEW(widgetViews.DONATION_FORM)" v-if="isFundraiserSignup" class="back-button" data-cy="back-to-form">
        <span> {{ $t('fundraiser.backDonationForm') }} </span>
      </button> -->

    <template #footer>
      <div class="share">
        <h3 class="bold text-center">
          {{ $t('confirmation.share') }}
        </h3>
        <p class="body-text-semibold mx-12.5 text-center text-black-01">
          {{ $t('confirmation.shareDescription') }}
        </p>
        <div class="flex justify-center gap-2 mt-4">
          <a class="share-link" :href="facebookShareLink" target="_blank">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <!-- <a class="share-link" href>
              <ion-icon name="logo-instagram"></ion-icon>
            </a>-->
          <a class="share-link" :href="twitterShareLink" target="_blank">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.892 8.46079L20.3386 -0.00878906H18.5746L12.106 7.34373L6.9433 -0.00878906H0.987305L8.79597 11.1106L0.987305 19.9911H2.7513L9.57797 12.225L15.0313 19.9911H20.9873M3.38797 1.29279H6.09797L18.5733 18.7535H15.8626"
              />
            </svg>
          </a>
          <a class="share-link" :href="whatsappShareLink" target="_blank">
            <ion-icon name="logo-whatsapp"></ion-icon>
          </a>
        </div>
      </div>
    </template>
  </form-layout>
</template>
