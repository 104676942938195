<script>
import store from '@/store';

export default {
  name: 'DtdSearch',
  data() {
    return {
      dtdCompanySelected: false
    };
  },
  methods: {
    extractDtdData() {
      if (!window.doublethedonation) return;

      const companyID = document.querySelector('input[name="doubledonation_company_id"]').value;
      return { companyID };
    },
    test() {
      // Initialize the following variable to be the doublethedonation_company_id
      // if a streamlined search is used and a company was found. Otherwise, set it to null.
      const companyID = document.querySelector('input[name="doubledonation_company_id"]').value;
      var dtd_selected_company = companyID;
      window.DDCONF = {
        API_KEY: '',
        COMPANY: dtd_selected_company
      };
      if (window.doublethedonation) {
        // Don't break your page if our plugin doesn't load for any reason
        window.doublethedonation.plugin.load_config();
        window.doublethedonation.plugin.set_donation_identifier('TEST_IDENTIFIER'); // Replace with actual identifier or an empty string.
        window.doublethedonation.plugin.set_donation_campaign('TEST_CAMPAIGN'); // Replace with actual campaign.
        if (dtd_selected_company) {
          window.doublethedonation.plugin.set_company(dtd_selected_company);
        } else {
          var domain = window.doublethedonation.integrations.core.strip_domain('test@test.com'); // Replace with actual email.
          window.doublethedonation.plugin.email_domain(domain); // Checks for a company based on the email address.
        }
      }
    }
  },
  created() {
    const dtdPk = 'sdf23'; // TODO
    if (dtdPk) {
      // custom event sent out by dtde
      window.addEventListener('doublethedonation_company_id', ({ detail }) => {
        const companyID = detail;
        if (companyID) {
          const dtdInfo = {};
          store.commit('SET_DTD_INFO', dtdInfo);
          this.dtdCompanySelected = true;
          return;
        }
        this.dtdCompanySelected = false;
        store.commit('SET_DTD_INFO', null);
      });
    }
  }
};
</script>

<template>
  <div class="dtd-search">
    <h5 v-if="!dtdCompanySelected" class="dtd-title">See if your company will match your donation!</h5>
    <div id="dd-company-name-input" :class="{ 'dtd-company-selected': dtdCompanySelected }"></div>
    <button @click="test">TEST</button>
  </div>
</template>
