import { BrowserClient, defaultStackParser, getDefaultIntegrations, makeFetchTransport, Scope, captureConsoleIntegration } from '@sentry/browser';

let _scope = null;

/** @returns {Scope} */
export function getSentryScope() {
  if (_scope) {
    return _scope;
  }
  const integrations = getDefaultIntegrations({}).filter((defaultIntegration) => {
    return !['BrowserApiErrors', /* 'Breadcrumbs', */ 'GlobalHandlers'].includes(defaultIntegration.name);
  });

  const client = new BrowserClient({
    dsn: process.env.VUE_APP_SENTRY_URL,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [...integrations, captureConsoleIntegration()],
    release: process.env.VUE_APP_RELEASE,
    normalizeDepth: 10
  });

  _scope = new Scope();
  _scope.setClient(client);

  client.init();

  return _scope;
}
