import { debounce } from 'lodash';
import { mapState, mapMutations, mapActions } from 'vuex';

import request from '@/services/request';
import { unparam } from '@/utils';

const vm = {
  computed: {
    ...mapState(['orgId', 'successfullyDonated']),
    ...mapState('publicOrgSettings', ['settings']),
    ...mapState('donation', {
      donation: (state) => state
    }),

    fields() {
      return {
        email: this.donation.donorDetails?.email,
        firstName: this.donation.donorDetails?.firstName,
        lastName: this.donation.donorDetails?.lastName,
        amount: this.donation.amount,
        frequency: this.donation.frequency,
        currency: this.donation.currency,
        hasEndDate: this.donation.hasEndDate,
        startDate: this.donation.startDate,
        endDate: this.donation.endDate,
        numCharges: this.donation.numCharges,
        recurringType: this.donation.recurringType,
        paymentMethod: this.donation.paymentMethod,
        address: this.donation.billingAddress,
        phone: this.donation.donorDetails?.phone
      };
    }
  },
  watch: {
    fields: {
      async handler(fields) {
        if (!fields.email) return;

        if (!this.settings?.donationDraftsEnabled) return;

        this.saveDonationDraft();
      },
      deep: true
    }
  },
  methods: {
    // leave methods object, we assign a method later
    ...mapMutations('donation', [
      'SET_END_DATE',
      'SET_AMOUNT',
      'SET_FREQUENCY',
      'SET_CURRENCY',
      'SET_PAYMENT_METHOD',
      'SET_DONOR_DETAILS',
      'SET_BILLING_ADDRESS',
      'SET_RECURRING_TYPE',
      'SET_HAS_END_DATE',
      'SET_DRAFT_ID'
    ]),
    ...mapActions('donation', ['SET_FREQUENCY_ACTIONS']),
    async getDonationDraft(id) {
      const { data: donationDraft } = await request.get(`public/donation-draft/${id}`);
      if (!donationDraft) {
        return;
      }

      this.SET_DRAFT_ID(id);
      const { frequency, amount, currency, paymentMethod } = donationDraft.data;

      if (!donationDraft.data) {
        return;
      }
      this.SET_FREQUENCY_ACTIONS(frequency);
      this.SET_AMOUNT(amount);
      this.SET_CURRENCY(currency);
      this.SET_PAYMENT_METHOD(paymentMethod);
      this.SET_DONOR_DETAILS({
        email: donationDraft.data.email,
        firstName: donationDraft.data.firstName,
        lastName: donationDraft.data.lastName,
        phone: donationDraft.data.phone
      });
      this.SET_BILLING_ADDRESS(donationDraft.data.address);

      if (donationDraft.data.frequency !== 'single') {
        this.SET_END_DATE(donationDraft.data.endDate);
        this.SET_RECURRING_TYPE(donationDraft.data.recurringType);
        this.SET_HAS_END_DATE(donationDraft.data.hasEndDate);
        this.SET_START_DATE(donationDraft.data.startDate);
      }
    }
  },
  mounted() {
    try {
      const { 'double-draft': doubleDraftId } = unparam(window.location.href);
      if (doubleDraftId) {
        this.getDonationDraft(doubleDraftId);
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export default vm;

async function saveDonationDraft() {
  // Never create a draft after a successful donation
  if (this.successfullyDonated) {
    return;
  }
  request.post(`public/donation-draft/${this.orgId}`, { data: this.fields });
}

vm.methods.saveDonationDraft = debounce(saveDonationDraft, 2000);
