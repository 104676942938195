<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { currencies } from '@/services/currencies';
import EntityCallOut from '@/components/EntityCallOut.vue';

export default {
  components: {
    EntityCallOut
  },
  data() {
    return {
      locales: [
        { value: 'en', label: 'EN' }
        // { value: 'fr', label: 'FR' },
        // { value: 'es', label: 'ES' },
        // { value: 'de', label: 'DE' }
      ],
      preferences: {
        locale: '',
        currency: ''
      }
    };
  },
  computed: {
    ...mapState(['showLocalizationModal']),
    ...mapState('donation', {
      donation: (state) => state
    }),

    ...mapState('campaign', {
      campaignSettings: (campaign) => campaign.settings
    }),
    ...mapState('publicOrgSettings', {
      isMultiEntity: (state) => state.isMultiEntity
    }),
    ...mapGetters('donation', ['getGatewayByCurrency']),
    // can't import computed to script setup
    // selectedCurrency: {
    //   get() {
    //     return this.donation.currency;
    //   },
    //   set(newValue) {
    //     this.SET_CURRENCY_ACTIONS(newValue);
    //   }
    // },
    enabledCurrencies() {
      return currencies.value.filter((c) => {
        const disabledCurrencies = this.campaignSettings?.disabledCurrencies;
        if (!Array.isArray(disabledCurrencies) || disabledCurrencies.length === 0) {
          return true;
        }

        return disabledCurrencies.indexOf(c.value) === -1;
      });
    },
    selectedGateway() {
      return this.getGatewayByCurrency(this.preferences.currency);
    },
    isUpdated() {
      return this.preferences.locale !== this.$i18n.locale || this.preferences.currency !== this.donation.currency;
    }
  },

  methods: {
    ...mapActions(['setWidgetOpen']),
    ...mapActions('donation', ['SET_CURRENCY_ACTIONS']),
    ...mapMutations(['SET_SHOW_LOCALIZATION_MODAL']),
    // localeChanged(event) {
    //   this.$formulate.setLocale(event.target.value);
    // },
    async save() {
      this.SET_CURRENCY_ACTIONS(this.preferences.currency);
      this.$i18n.locale = this.preferences.locale;

      this.SET_SHOW_LOCALIZATION_MODAL(false);
    }
  },

  created() {
    this.preferences = {
      locale: this.$i18n.locale,
      currency: this.donation.currency
    };
  }
};
</script>

<template>
  <app-popover v-if="preferences.locale" :open="showLocalizationModal" :title="$t('footer.localizationSettings')" @close="SET_SHOW_LOCALIZATION_MODAL(false)">
    <div class="flex flex-col gap-5 mb-5">
      <div>
        <div class="my-2 flex items-center gap-1">
          <app-icon name="globe" type="svg" width="18" height="18" class="text-black fill-current" />
          <label class="font-bold text-lg">{{ $t('footer.language') }}</label>
        </div>
        <select v-model="preferences.locale" name="locales">
          <option v-for="locale in locales" :key="locale.value" :value="locale.value">
            {{ locale.label }}
          </option>
        </select>
      </div>

      <div>
        <p v-if="isMultiEntity" class="text-black">
          Changing the currency may change the entity to which your donation will be made and consequently the receipt you'll receive.
        </p>

        <div class="my-2 flex items-center gap-1">
          <app-icon name="currency" type="svg" width="18" height="18" class="text-black fill-current items-start" />
          <label class="font-bold text-lg">{{ $t('footer.currency') }}</label>
        </div>
        <select v-model="preferences.currency" name="currencies">
          <option v-for="currency in enabledCurrencies" :key="currency.value" :value="currency.value">
            {{ currency.label }}
          </option>
        </select>
        <entity-call-out :gateway="selectedGateway" />
      </div>
    </div>

    <app-cta-button @click="save" :disabled="!isUpdated">Save</app-cta-button>
  </app-popover>
</template>
