<script>
import { mapState, mapMutations } from 'vuex';
import autosize from 'autosize';

export default {
  name: 'FormAdditionalOptions',
  props: {
    fieldsDirty: Boolean
  },
  data() {
    return {
      additionalOptionsExpanded: false,
      donationComment: '',
      isCommenting: false
    };
  },

  computed: {
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapState('campaign', ['settings']),
    isDedicatingDonation: {
      get() {
        return this.donation.isDedicating;
      },
      set(value) {
        this.SET_IS_DEDICATING(value);
      }
    },
    dedicationForm: {
      get() {
        return this.donation.dedication;
      },
      set(value) {
        this.SET_DEDICATION(value);
      }
    },
    isDonatingAnonymously: {
      get() {
        return this.donation.anonymous;
      },
      set(value) {
        this.SET_IS_DONATING_ANONYMOUSLY(value);
      }
    },
    // Custom
    IS_MEIMASAI_DEFAULT_CAMPAIGN() {
      return this.settings.id === 'f4b200a0-bf71-11ee-bd71-99b6de87b26d';
    },
    dedicationFieldsLabels() {
      if (this.IS_MEIMASAI_DEFAULT_CAMPAIGN) {
        return {
          fullName: 'Honoree name',
          email: 'Honoree email',
          message: 'Message to honoree'
        };
      }

      return {
        fullName: this.$t('additionalOptions.dedicateeFullName'),
        email: this.$t('additionalOptions.dedicateeEmail'),
        message: this.$t('additionalOptions.message')
      };
    }
  },

  watch: {
    donationComment(value) {
      if (value && this.isCommenting) {
        this.SET_COMMENT(value);
      }
    },
    isCommenting(value) {
      if (value && this.donationComment) {
        return this.SET_COMMENT(this.donationComment);
      }

      this.SET_COMMENT(null);
    },
    async dedicationForm() {
      if (!this.$refs.dedicationForm) {
        return;
      }
      const hasErrors = await this.$refs.dedicationForm.hasValidationErrors();
      this.SET_IS_DEDICATION_VALID(!hasErrors);
    },

    fieldsDirty(value) {
      const isDeticating = this.isDedicatingDonation;
      if (isDeticating && value) {
        this.$refs.hiddenSubmitButton.click();
      }
    }
  },

  methods: {
    ...mapMutations('donation', ['SET_COMMENT', 'SET_IS_DONATING_ANONYMOUSLY', 'SET_IS_DEDICATING', 'SET_DEDICATION']),
    ...mapMutations('validation', ['SET_IS_DEDICATION_VALID']),
    resize(ref, update = false) {
      const textarea = this.$refs[ref].$el.querySelector('textarea');
      if (update) {
        return autosize.update(textarea);
      }

      autosize(textarea);
    },

    async commentToggleChanged() {
      if (this.isCommenting) {
        await this.$nextTick();
        this.$refs.comment.$el.querySelector('textarea').focus();
      }
    }
  },
  created() {
    if (this.settings.additionalOptionsExpanded) {
      this.additionalOptionsExpanded = true;
    }
  }
};
</script>

<template>
  <div ref="additional-options">
    <div
      @click="additionalOptionsExpanded = !additionalOptionsExpanded"
      class="flex items-center gap-2 cursor-pointer"
      :title="additionalOptionsExpanded ? 'Collapse additional options' : 'Expand additional options'"
    >
      <svg v-if="!additionalOptionsExpanded" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vector">
          <path
            id="Union"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 2C7.5 1.17157 6.82843 0.5 6 0.5C5.17157 0.5 4.5 1.17157 4.5 2V5H1.5C0.671573 5 0 5.67157 0 6.5C0 7.32843 0.671573 8 1.5 8H4.5V11C4.5 11.8284 5.17157 12.5 6 12.5C6.82843 12.5 7.5 11.8284 7.5 11V8H10.5C11.3284 8 12 7.32843 12 6.5C12 5.67157 11.3284 5 10.5 5H7.5V2Z"
            fill="var(--primary-color)"
          />
        </g>
      </svg>
      <svg v-else width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 6.5C12 7.32843 11.3284 8 10.5 8H1.5C0.671573 8 0 7.32843 0 6.5C0 5.67157 0.671573 5 1.5 5H10.5C11.3284 5 12 5.67157 12 6.5Z"
          fill="var(--primary-color)"
        />
      </svg>
      <!-- <ion-icon name="add" /> -->
      <!-- <ion-icon name="remove" v-if="additionalOptionsExpanded" /> -->
      <h3 class="bold">{{ $t('additionalOptions.title') }}</h3>
    </div>
    <transition name="expandx2">
      <div v-if="additionalOptionsExpanded" class="options">
        <div v-if="!settings.hideComment">
          <app-checkbox v-model="isCommenting" size="18.01" @input="commentToggleChanged">{{ $t('additionalOptions.comment') }}</app-checkbox>

          <transition name="expandx2">
            <formulate-input
              @input.native="resize('comment')"
              @focusin.native="resize('comment', true)"
              @focusout.native="resize('comment', true)"
              v-show="isCommenting"
              v-model="donationComment"
              maxlength="150"
              validation="max:150,length"
              class="ml-3 mt-3"
              type="textarea"
              ref="comment"
              id="comment"
              name="comment"
              :placeholder="$t('additionalOptions.commentPlaceholder')"
              :help="`${donationComment ? donationComment.length : '0'} / 150 Characters`"
            />
          </transition>
        </div>
        <div>
          <app-checkbox v-model="isDedicatingDonation" size="18.01">{{
            IS_MEIMASAI_DEFAULT_CAMPAIGN ? 'Gift this donation' : $t('additionalOptions.dedicate')
          }}</app-checkbox>
          <transition name="expandx2">
            <div v-if="isDedicatingDonation" class="donor-fields ml-3 mt-3">
              <formulate-form ref="dedicationForm" class="flex flex-col gap-2">
                <formulate-input
                  type="text"
                  v-model="dedicationForm.fullName"
                  validation="required"
                  name="fullName"
                  :placeholder="dedicationFieldsLabels.fullName"
                  :validation-name="dedicationFieldsLabels.fullName"
                />
                <formulate-input
                  type="email"
                  v-model="dedicationForm.email"
                  validation="^required|email"
                  name="email"
                  :validation-name="dedicationFieldsLabels.email"
                  :placeholder="dedicationFieldsLabels.email"
                />
                <div class="message-field">
                  <formulate-input
                    v-model="dedicationForm.message"
                    @input.native="resize('message')"
                    @focusin.native="resize('message')"
                    @focusout.native="resize('message')"
                    id="message"
                    ref="message"
                    type="textarea"
                    name="message"
                    maxlength="150"
                    validation="max:150,length"
                    :placeholder="dedicationFieldsLabels.message"
                    :help="`${dedicationForm.message ? dedicationForm.message.length : '0'} / 150`"
                  />
                </div>
                <!-- a hidden button to trigger the display of the error with a fake  submission -->
                <button ref="hiddenSubmitButton" class="hidden"></button>
              </formulate-form>
            </div>
          </transition>
        </div>

        <div>
          <app-checkbox v-model="isDonatingAnonymously">{{ $t('additionalOptions.anonymous') }}</app-checkbox>
        </div>
      </div>
    </transition>
  </div>
</template>
