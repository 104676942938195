<script>
import dayjs from 'dayjs';
import AnimatedNumber from 'animated-number-vue';
import { mapState } from 'vuex';

import { getCurrencySymbol } from '@/utils';
import AppProgressbar from '@/common/AppProgressbar.vue';

export default {
  name: 'GoalMeter',
  components: {
    AnimatedNumber,
    AppProgressbar
  },
  data() {
    return {
      progressDuration: 1400
    };
  },
  computed: {
    ...mapState('donation', ['orgDefaultCurrency']),
    ...mapState('campaign', ['id', 'settings', 'chargeTotal', 'donorCount']),
    goalMeter() {
      return this.settings.goalMeter;
    },

    numOfDaysLeft() {
      const endDate = this.goalMeter.endDate;
      const daysLeft = dayjs(dayjs(endDate).toISOString()).diff(dayjs(), 'days');

      return daysLeft;
    },
    progressPercentage() {
      const percentage = (100 / this.goalMeter.amountTarget) * this.chargeTotal;
      return Math.min(percentage, 100);
    },
    currencySymbol() {
      const symbol = getCurrencySymbol(this.orgDefaultCurrency);
      return symbol;
    }
  },
  methods: {
    formatAnimatedNumber(value) {
      return value.toLocaleString();
    }
  },

  mounted() {
    setTimeout(() => {
      this.progressDuration = 500;
    }, 3000);
  }
};
</script>

<template>
  <section class="goal-meter my-4">
    <div class="stats">
      <div class="main">
        <div class="goal-meter-info raised">
          <div class="body-text-extra-bold">
            <span>{{ currencySymbol }}</span>
            <span class="font-bold font-nunito">
              <animated-number :value="chargeTotal" :duration="progressDuration" :round="true" :formatValue="formatAnimatedNumber" />
            </span>
            <span class="font-nunito-sans font-medium">
              {{ $t('goalMeter.raised') }}
            </span>
          </div>

          <div class="flex gap-2 items-center subtext-regular">
            <div>
              <span>{{ $t('goalMeter.outOf') }} {{ currencySymbol }}</span>
              <span class="font-bold">{{ goalMeter.amountTarget | number }}</span>
            </div>
            <div v-if="goalMeter.endDate" class="vl"></div>
            <div v-if="goalMeter.endDate">
              <span>{{ Math.max(numOfDaysLeft, 0) }}</span> days left
            </div>
            <div class="vl"></div>

            <span>
              <animated-number :value="donorCount" :duration="1400" :round="true" :formatValue="formatAnimatedNumber" />
              {{ $t('goalMeter.donors') }}
            </span>
          </div>
        </div>
      </div>
      <div class="progress-bar">
        <app-progressbar :value="progressPercentage" />
      </div>
    </div>
  </section>
</template>
