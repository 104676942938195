<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { widgetViews, getCurrencySymbol } from '@/utils';
import FormLayout from '@/layouts/FormLayout.vue';

export default {
  name: 'DonorWall',
  components: {
    FormLayout
  },

  data() {
    return {
      widgetViews,
      getCurrencySymbol,
      isLoading: false
    };
  },

  computed: {
    ...mapState('donorWall', ['donations', 'totalDonations']),
    ...mapState('campaign', ['settings']),
    ...mapGetters('campaign', ['hasActiveGoalMeter']),
    isEverythingFetched() {
      if (!this.donations) {
        return;
      }
      return this.donations.length >= this.totalDonations;
    },
    heightStyle() {
      const headerHeight = this.hasActiveGoalMeter ? 224 : 119;
      return `height: calc(100vh - ${headerHeight}px)`;
    },
    donateText() {
      const ctaTerm = this.settings?.ctaTerm?.toLowerCase();
      if (!ctaTerm) {
        return this.$t('donorWall.cta.donate');
      }
      return this.$t(`donorWall.cta.${ctaTerm}`);
    }
  },

  methods: {
    ...mapMutations(['SET_WIDGET_VIEW']),
    ...mapActions('donorWall', ['fetchDonations']),
    async fetchRecentDonations() {
      if (this.isLoading || this.isEverythingFetched) {
        return;
      }
      this.isLoading = true;
      try {
        const currentScrollTop = this.$refs.donationsContainer.scrollTop;
        await this.fetchDonations();
        this.$refs.donationsContainer.scrollTop = currentScrollTop;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    donorName(donation) {
      if (donation.donor) {
        if (this.settings.donorWallNameFormat === 'firstLetterSurname') {
          return `${donation.donor.firstName} ${donation.donor.lastName[0]}.`;
        }
        return donation.donor.name;
      }
      return 'Anonymous';
    },
    donationAmount(donation) {
      const { frequency, totalAmount, currency } = donation;
      const amountFormatted = this.getCurrencySymbol(currency) + totalAmount.toLocaleString('en'); // TODO
      if (frequency === 'single') {
        return amountFormatted;
      }
      return `$${totalAmount.toLocaleString('en')} /${frequency}`;
    }
  }
};
</script>

<template>
  <form-layout show-header>
    <div :style="heightStyle" class="donor-wall slide-up">
      <div ref="donationsContainer" class="donations-container">
        <h1 class="donations-title">Recent Donors</h1>
        <div v-for="donation in donations" :key="donation.id" class="donation-card">
          <span class="donor">
            <app-icon type="svg" name="person" class="person-icon" />
            <span class="name">{{ donorName(donation) }}</span>
          </span>
          <span class="amount">{{ donationAmount(donation) }}</span>
        </div>
        <button v-if="!isEverythingFetched" @click="fetchRecentDonations" :disabled="isLoading" class="text-center view-more-button">View more</button>
      </div>
      <footer class="footer">
        <button @click="SET_WIDGET_VIEW(widgetViews.DONATION_FORM)" class="donate-button">{{ donateText }}</button>
        <double-logo :is-link="true" />
      </footer>
    </div>
  </form-layout>
</template>
