<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'WidgetLauncher',
  computed: {
    ...mapState('publicOrgSettings', ['settings']),
    ...mapState(['isWidgetOpen', 'orgId']),
    ...mapGetters('publicOrgSettings', ['isTextLauncher']),
    text() {
      return !this.isTextLauncher ? '' : this.settings.ctaTerm;
    }
  },

  methods: {
    ...mapActions(['setWidgetOpen'])
  }
};
</script>

<template>
  <div
    @click="setWidgetOpen(!isWidgetOpen)"
    class="dp--btn"
    :class="[isWidgetOpen ? 'dp--open' : 'dp--close', text ? 'text-button' : '']"
    :style="`background-color: ${settings.themeColor};`"
  >
    <div v-if="!isWidgetOpen" class="icon-container">
      <svg class="heart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          :fill="settings.themeTextColor"
          d="M256,448a32,32,0,0,1-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63,114.52,98.46,64,159.08,64c44.08,0,74.61,24.83,92.39,45.51a6,6,0,0,0,9.06,0C278.31,88.81,308.84,64,352.92,64,413.54,64,463.37,114.52,464,176.64c.54,54.21-18.63,104.26-58.61,153-18.77,22.87-52.8,59.45-131.39,112.8A32,32,0,0,1,256,448Z"
        />
      </svg>
      <span v-if="text" class="text" :style="`color: ${settings.themeTextColor};`">{{ text }}</span>
    </div>
    <div v-else class="icon-container">
      <close-icon />
      <span class="text" v-if="text">{{ text }}</span>
    </div>
  </div>
</template>
