<script>
import { isEqual, get } from 'lodash';

import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';

import { CC_GATEWAYS, GATEWAYS, PAYMENT_METHODS, donationStatusIsAtLeast, sleep, widgetViews } from '@/utils';
import { extractData } from '@/services/payment-request';
import AppButton from '@/common/AppButton.vue';
import StripeService from '@/services/stripe';
import { getSentryScope } from '@/services/sentry';

const DONATION_FREQUENCY_LABELS = {
  single: 'One-time',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly'
};

const PAYMENT_REQUEST_METHODS = {
  'apple-pay': PAYMENT_METHODS.APPLE_PAY,
  'google-pay': PAYMENT_METHODS.GPAY,
  'https://google.com/pay': PAYMENT_METHODS.GPAY
};

export default {
  name: 'FormPaymentButtons',
  components: {
    AppButton
  },

  data() {
    return {
      error: null,
      donationDirty: true, // Is local donation data different from the data on the server?
      paymentRequest: null,
      canMakePayment: null,
      loadingWallets: true,
      GATEWAYS,
      PAYMENT_METHODS
    };
  },

  computed: {
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapGetters('donation', ['amountPlusFees', 'totalDonation', 'isOneTime', 'persistentDonationData']),
    ...mapGetters('validation', ['isFirstStepValid']),
    ...mapState('publicOrgSettings', {
      chariotCid: (state) => state.settings.chariotCid,
      fees: (state) => state.settings.fees
    }),
    ...mapState('campaign', ['settings']),
    ...mapState(['orgId', 'isWidgetOpen', 'chariotLoaded']),

    totalDonationOrZero() {
      if (isNaN(this.totalDonation * 100)) {
        return 0;
      } else {
        return this.totalDonation;
      }
    },
    isGooglePayAvailable() {
      return this.canMakePayment?.googlePay;
    },
    isApplePayAvailable() {
      return this.canMakePayment?.applePay;
    }
  },

  watch: {
    // We must handle donation details being changed after the initial submission to the server
    persistentDonationData(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.donationDirty = true;
      }
    },

    totalDonation() {
      if (!this.paymentRequest) {
        return;
      }
      this.paymentRequest.update({
        total: {
          label: 'Your donation',
          amount: Math.round(this.totalDonationOrZero * 100)
        }
      });
    },
    async chariotLoaded(value) {
      if (!value) return;

      await sleep(100);
      const iframe = document.querySelector('iframe[name="double-checkout"]');
      const chariot = iframe.contentWindow.document.querySelector('#chariot');

      chariot.onDonationRequest(async () => {
        await sleep(5); // wait for the cover fees to be updated, no access here to $nextTick
        return { amount: Math.ceil(this.totalDonationOrZero * 100) };
      });

      chariot.addEventListener('CHARIOT_SUCCESS', this.chariotSucceeded);
      chariot.addEventListener('CHARIOT_EXIT', (e) => {
        getSentryScope().captureMessage(`CHARIOT_EXIT: Reason: ${e.detail?.reason}. Description: ${e.detail?.description}`, 'info');
      });
    }
  },

  methods: {
    ...mapActions('donation', ['createDonation', 'confirmDonation', 'confirmStripePaymentRequest', 'donateWithChariot']),
    ...mapMutations(['SET_WIDGET_VIEW', 'SET_SUCCESSFULLY_DONATED']),
    ...mapMutations('donation', [
      'SET_AMOUNT',
      'SET_PAY_COVER_FEES',
      'SET_DONOR_DETAILS',
      'SET_BILLING_ADDRESS',
      'SET_GATEWAY',
      'SET_PAYMENT_METHOD',
      'SET_RECAPTCHA_TOKEN',
      'SET_CHARIOT_INTENT'
    ]),
    ...mapMutations('validation', ['SET_IS_PAYMENT_DETAILS_VALID']),

    async donateWithPaymentRequest(paymentMethodEvent) {
      this.SET_PAYMENT_METHOD(PAYMENT_REQUEST_METHODS[paymentMethodEvent.methodName]);

      try {
        const { firstName, lastName, email, address, phone } = extractData(paymentMethodEvent);
        this.SET_DONOR_DETAILS({ firstName, lastName, email, phone });
        this.SET_BILLING_ADDRESS(address);
        await this.$nextTick(); // wait for SET_DONOR_DETAILS to update
        // Step 1 - Create the payment intent
        if (this.donationDirty) {
          await this.createDonation();
          this.donationDirty = false;
        }
        // Step 2 - Confirm the payment intent
        if (get(this.donation, 'stripeIntent.status') !== 'succeeded') {
          await this.confirmStripePaymentRequest(paymentMethodEvent.paymentMethod.id);
        }
        // Step 3 - Confirm the donation on our server
        const { status, error } = await this.confirmDonation();
        if (!error && donationStatusIsAtLeast(status, '1stChargeSuccess')) {
          this.paymentSucceeded();
        }
        paymentMethodEvent.complete('success');
      } catch (error) {
        paymentMethodEvent.complete('fail');
        this.error = error.message || 'An error occurred';
        getSentryScope().captureMessage(`Payment request failed: ${error}`, 'info');
      }
    },

    async createStripeRequest() {
      if (this.donation.CCGateway !== CC_GATEWAYS.STRIPE) {
        return;
      }

      // Important: Create Stripe instance from outside the iframe
      const stripe = await StripeService.getStripeObject(true);

      this.paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: this.donation.currency,
        total: {
          label: 'Your donation',
          amount: Math.round(this.totalDonationOrZero * 100)
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true
      });

      this.paymentRequest.on('paymentmethod', this.donateWithPaymentRequest);

      this.canMakePayment = await this.paymentRequest.canMakePayment();
      this.loadingWallets = false;
    },

    onPaymentRequestClicked() {
      if (!this.isGooglePayAvailable && !this.isApplePayAvailable) {
        return;
      }

      this.SET_GATEWAY(GATEWAYS.STRIPE);
      const paymentMethod = this.isApplePayAvailable ? PAYMENT_METHODS.APPLE_PAY : PAYMENT_METHODS.GPAY;
      this.selectPaymentMethod(paymentMethod);
    },
    selectPaymentMethod(methodName) {
      this.$emit('payment-method-selected');
      if (!this.isFirstStepValid) {
        return;
      }

      this.SET_PAYMENT_METHOD(methodName);
      if (methodName === PAYMENT_METHODS.CARD || methodName === PAYMENT_METHODS.ACH) {
        this.SET_WIDGET_VIEW(widgetViews.CREDIT_CARD_FORM);
      } else if (methodName === PAYMENT_METHODS.APPLE_PAY || methodName === PAYMENT_METHODS.GPAY) {
        this.paymentRequest.show();
      }
    },

    // Help methods

    async chariotSucceeded({ detail }) {
      const grantIntent = detail.grantIntent;
      const user = detail.user;
      if (!grantIntent) {
        // error handling
        throw new Error('No grantIntent found');
      }

      const hasAmountChanged = grantIntent.amount / 100 !== this.amountPlusFees;

      if (hasAmountChanged) {
        this.SET_AMOUNT(grantIntent.amount / 100);
        this.SET_PAY_COVER_FEES(false);
      }

      this.SET_CHARIOT_INTENT(detail);
      this.SET_DONOR_DETAILS({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      });
      this.SET_BILLING_ADDRESS({
        city: user.address.city,
        country: user.address.country,
        line1: user.address.line1,
        line2: user.address.line2,
        zip: user.address.postalCode,
        state: user.address.state
      });

      try {
        await this.donateWithChariot({ workflowSessionId: detail.workflowSessionId, chariotAmount: grantIntent.amount });
        this.paymentSucceeded();
      } catch (error) {
        this.error = error.message || 'An error occurred';
        getSentryScope().captureMessage(`Chariot donation failed: ${error}`, 'info');
      }
    },
    paymentSucceeded() {
      this.SET_WIDGET_VIEW(widgetViews.CONFIRMATION_DONATION);
      this.$trackEvent({
        action: 'success',
        label: DONATION_FREQUENCY_LABELS[this.donation.frequency] + ' donation',
        value: this.totalDonation
      });
      this.SET_SUCCESSFULLY_DONATED(true);
    }
  },

  mounted() {
    this.createStripeRequest(); // Move to created?
  }
};
</script>

<template>
  <div class="form-payment-buttons">
    <div class="payment-buttons buttons flex flex-col gap-2.5">
      <template v-if="donation.CCGateway === GATEWAYS.STRIPE">
        <button
          v-if="loadingWallets || isGooglePayAvailable || isApplePayAvailable"
          class="payment-request-button relative"
          :class="{ loading: loadingWallets }"
          @click="onPaymentRequestClicked"
        >
          <content-loader v-if="loadingWallets" class="absolute" />
          <app-icon v-else-if="isGooglePayAvailable" type="svg" name="g-pay" height="24" class="flex google-pay-button" />
          <div v-else-if="isApplePayAvailable" id="ckoApplePay" class="apple-pay-button apple-pay-button-text-donate" lang="en"></div>
        </button>
      </template>

      <div class="flex gap-3 w-full">
        <app-button
          @click="
            selectPaymentMethod(PAYMENT_METHODS.CARD);
            SET_GATEWAY(donation.CCGateway);
          "
          :disabled="!this.isFirstStepValid"
          class="payment-method flex items-center justify-center flex-1 gap-1"
          data-cy="card-button"
        >
          <ion-icon name="card-outline"></ion-icon>
          <h3>{{ $t('payment.card') }}</h3>
        </app-button>
        <app-button
          v-if="donation.CCGateway === GATEWAYS.STRIPE || donation.CCGateway === GATEWAYS.CARDKNOX"
          class="payment-method bank-button flex items-center justify-center flex-1 gap-1"
          :disabled="!this.isFirstStepValid"
          @click="
            selectPaymentMethod(PAYMENT_METHODS.ACH);
            SET_GATEWAY(donation.CCGateway);
          "
        >
          <app-icon type="svg" name="bank" />
          <h3>{{ $t('payment.bank') }}</h3>
        </app-button>
      </div>
      <transition name="expand">
        <div
          v-if="chariotCid"
          v-show="isOneTime && donation.currency === 'usd'"
          @click="SET_GATEWAY('chariot')"
          class="chariot-wrapper rounded-lg overflow-hidden"
        >
          <chariot-connect :cid="chariotCid" id="chariot" class="h-11" theme="LightBlueTheme"></chariot-connect>
        </div>
      </transition>
    </div>
    <p v-if="error" class="text-sm text-red mt-1">{{ error }}</p>
  </div>
</template>
