<script>
export default {
  computed: {
    locale() {
      return this.$i18n.locale.toUpperCase();
    }
  }
};
</script>

<script setup>
import { getCurrencySymbol, widgetViews } from '@/utils';
import { computed } from 'vue';
import { useActions, useMutations, useState } from 'vuex-composition-helpers';

const { widgetView } = useState(['widgetView']);
const { currency } = useState('donation', ['currency']);
const { setWidgetOpen } = useActions(['setWidgetOpen']);
const { SET_SHOW_LOCALIZATION_MODAL } = useMutations(['SET_SHOW_LOCALIZATION_MODAL']);
const showLocalizationButton = computed(() => [widgetViews.DONATION_FORM.name, widgetViews.DONOR_WALL.name].includes(widgetView.value.name));
</script>

<template>
  <section class="sticky top-0 bg-secondary form-controls">
    <div class="container flex justify-between">
      <div class="flex gap-4">
        <div class="flex justify-center items-center language-dropdown">
          <!-- !TODO: need outline-icon-->
          <transition name="expand">
            <button
              v-if="showLocalizationButton"
              @click="SET_SHOW_LOCALIZATION_MODAL(true)"
              title="Localization settings"
              class="header-button p-1.5 text-white flex items-center gap-2 rounded"
            >
              <app-icon name="globe" type="svg" width="18" height="18" class="text-white fill-current" />
              <span class="flex items-center gap-1 text-sm font-nunito-sans font-semibold">
                <span>{{ locale }}</span>
                <span>•</span>
                <span class="font-nunito">{{ getCurrencySymbol(currency) }}</span>
              </span>
            </button>
          </transition>
        </div>
      </div>
      <button @click="setWidgetOpen(false)" class="header-button m-0 cursor-pointer p-1.5 rounded">
        <app-icon type="svg" name="exit" height="18" width="18" class="text-white fill-current" />
      </button>
    </div>
  </section>
</template>
