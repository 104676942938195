import { loadScript } from '@/utils';
import store from '@/store';

const SOFTWARE_NAME = 'Double';

let cardknoxObject = null;

const IFRAME_SELECTOR = 'iframe[name="double-checkout"]';

export default {
  async getCardknoxObject() {
    if (cardknoxObject) {
      return cardknoxObject;
    }

    const widgetIframe = document.querySelector(IFRAME_SELECTOR);
    const iframeHead = widgetIframe.contentDocument.head;
    // Load on parent website
    loadScript('https://cdn.cardknox.com/ifields/2.15.2405.1601/ifields.min.js', { target: document.head });
    // Load in iframe
    return loadScript('https://cdn.cardknox.com/ifields/2.15.2405.1601/ifields.min.js', { target: iframeHead }).then(() => {
      // All these methods are available on the iframe contentWindow, but we rather return them here on the time they're loaded
      cardknoxObject = {
        getTokens: widgetIframe.contentWindow.getTokens,
        setIfieldStyle: widgetIframe.contentWindow.setIfieldStyle,
        enableAutoFormatting: widgetIframe.contentWindow.enableAutoFormatting,
        addIfieldKeyPressCallback: widgetIframe.contentWindow.addIfieldKeyPressCallback,
        addIfieldCallback: widgetIframe.contentWindow.addIfieldCallback,
        focusIfield: widgetIframe.contentWindow.focusIfield
      };

      const cardknoxData = store.state.publicOrgSettings.cardknoxData;
      widgetIframe.contentWindow.setAccount(cardknoxData.iFieldsKey, SOFTWARE_NAME, '1.0.0');

      return cardknoxObject;
    });
  }
};
