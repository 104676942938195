// XXX: beware of circular dependency: store/donation > stripe > store
import store from '@/store';
import { loadScript } from '@/utils';

let stripeObject = null;
let stripeTopLevelObject = null;

const IFRAME_SELECTOR = 'iframe[name="double-checkout"]';

export default {
  async getStripeObject(topLevel) {
    if (topLevel && stripeTopLevelObject) {
      return stripeTopLevelObject;
    } else if (!topLevel && stripeObject) {
      // There is requirement to use the same Stripe instance for creating elements and payment confirmation
      return stripeObject;
    }

    const stripeUserId = store.state.donation.CCGateway.keys.stripe_user_id;
    // have orgPublicSettings been fetched yet?
    if (!stripeUserId) {
      // throw new Error('stripe object not initialized');
    }
    if (topLevel) {
      // Purpose of loading Stripe outside the widget (in addition to inside) if for enabling Apple Pay since Apple considers our iframe unsecure (src="about:blank")
      return loadScript('https://js.stripe.com/v3/', { target: document.head }).then(() => {
        const Stripe = window.Stripe;
        stripeTopLevelObject = new Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
          stripeAccount: stripeUserId,
          locale: 'auto'
        });

        return stripeTopLevelObject;
      });
    } else {
      const widgetIframe = document.querySelector('iframe[name="double-checkout"]');
      const iframeHead = widgetIframe.contentDocument.head;

      return loadScript('https://js.stripe.com/v3/', { target: iframeHead }).then(() => {
        const Stripe = widgetIframe.contentWindow.Stripe;
        stripeObject = new Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
          stripeAccount: stripeUserId,
          locale: 'auto'
        });

        return stripeObject;
      });
    }
  },

  destroyStripeObject() {
    stripeObject = null;
  },

  platformStripe() {
    const widgetIframe = document.querySelector(IFRAME_SELECTOR);
    const Stripe = widgetIframe.contentWindow.Stripe;

    return new Stripe(process.env.VUE_APP_STRIPE_API_KEY, { locale: 'auto' });
  }
};
