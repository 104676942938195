<script>
export default {
  name: 'DtdMatchPlugin',
  methods: {
    pluginInit() {
      const iframe = document.querySelector('iframe[name="double-checkout"]');
      const doubleTheDonation = iframe.contentWindow.doublethedonation;
      if (!doubleTheDonation) {
        return;
      }

      doubleTheDonation.plugin.load_plugin();
    }
  },
  mounted() {
    this.pluginInit();
    // In case thank you page is opened directly DTD might not be loaded yet
    // TODO: Handle it in a better way
    // setTimeout(() => {
    //   this.pluginInit();
    // }, 1500);
  }
};
</script>

<template>
  <div id="dd-container"></div>
</template>
