/*
Special note:
As the donation modules can't be used as a source of valid data, we need a seperate validation module.
Ideally all of these should have been handled at the component level (by vue-formulate),
but as the form is made up of multiple dynamic components, it will introduce a new layer of complexity
so for now at least we should handle all the validation stuff here.
!TODO: the main validator function should also return detailed errors
*/

import store from '@/store';

import { MAX_AMOUNT, MIN_AMOUNT, sentryMessage } from '@/utils';

export const state = {
  isRecurringEndValid: false,
  isCustomQuestionsValid: true, // will be set to false in custom-questions.vue if needed
  isDedicationFormValid: false,
  isPaymentDetailsValid: false,
  isPhoneValid: false
};

export const mutations = {
  SET_IS_RECURRING_END_VALID(state, newValue) {
    state.isRecurringEndValid = newValue;
  },
  SET_IS_CUSTOM_QUESTIONS_VALID(state, newValue) {
    state.isCustomQuestionsValid = newValue;
  },
  SET_IS_DEDICATION_VALID(state, newValue) {
    state.isDedicationFormValid = newValue;
  },
  SET_IS_PAYMENT_DETAILS_VALID(state, newValue) {
    state.isPaymentDetailsValid = newValue;
  },
  SET_IS_PHONE_VALID(state, newValue) {
    state.isPhoneValid = newValue;
  }
};

export const getters = {
  // Step 1
  isAmountValid(state, getters, rootState) {
    const amount = rootState.donation.amount;
    const isValid = !isNaN(amount) && amount >= MIN_AMOUNT && amount <= MAX_AMOUNT;
    return isValid;
  },

  // Step 2
  isOftenValid(state, _, rootState) {
    const donation = rootState.donation;
    const oftenValue = donation.frequency;
    const isFrequencyValid = ['single', 'weekly', 'monthly', 'yearly'].includes(oftenValue);
    if (!isFrequencyValid) {
      return false;
    }
    const hasEndDate = donation.hasEndDate;
    if (hasEndDate && !state.isRecurringEndValid) {
      return false;
    }

    return true;
  },

  // Step 3
  isAdditionalDetailsValid(state, getters, rootState) {
    const donation = rootState.donation;
    if (donation.isDedicating && !state.isDedicationFormValid) {
      return false;
    }
    return true;
  },

  // Step 4
  isPaymentDetailsValid(state) {
    return state.isPaymentDetailsValid;
  },

  // Getters
  isFirstStepValid(state, getters) {
    return getters.isAmountValid && getters.isOftenValid && getters.isAdditionalDetailsValid && state.isCustomQuestionsValid;
  },

  isDonationValid(_, getters) {
    // Validate only the essential fields from the first step and all fields from the second step.
    // This ensures that any bug allowing a user to bypass required fields in the first step
    // does not prevent them from completing the donation.
    if (!getters.isFirstStepValid) {
      sentryMessage(`Donor reached 2'nd step with invalid 1'st step!?`, 'warning', {
        name: 'Store',
        data: { validation: store.state.validation, donation: store.state.donation }
      });
    }
    return getters.isAmountValid && getters.isOftenValid && getters.isPaymentDetailsValid;
  }
};
