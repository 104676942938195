const number = (value) => (isNaN(value) ? '' : Number(value).toLocaleString('en'));
const capitalize = (value) => {
  if (!value) {
    return '';
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export default {
  install(app) {
    app.filter('number', number);
    app.filter('capitalize', capitalize);
  }
};
