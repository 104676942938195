<script>
export default {
  data: () => ({
    body: null
  }),
  props: {
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<template>
  <div>
    <transition name="overlay">
      <div class="full-overlay" v-if="open"></div>
    </transition>
    <section :class="{ open }" class="app-popover bg-white w-full p-6">
      <div :class="{ open }" class="bg-white w-full">
        <div class="flex justify-between items-center mb-5">
          <h3>{{ title }}</h3>
          <button title="Close" class="block ml-auto cursor-pointer" @click="$emit('close')">
            <app-icon type="svg" name="exit" height="18" width="18" class="text-black fill-current" />
          </button>
        </div>
      </div>
      <slot />
    </section>
  </div>
</template>
