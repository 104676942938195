import { getSentryScope } from '@/services/sentry';
import request from '../../services/request';
import { CC_GATEWAYS } from '@/utils';

export const state = {
  // default settings from the default campaign that we use for all elements that still don't have their own settings
  settings: {},
  orgDetails: {},
  stripeUserId: null,
  stripePublishableKey: null,
  cardknoxData: {},
  suggestedAddressCountry: 'US',
  chariotLoaded: false,
  chariotInstance: null
};

export const mutations = {
  SET_DEFAULT_SETTINGS(state, newValue) {
    state.settings = newValue;
  },
  SET_ORG_DETAILS(state, newValue) {
    getSentryScope().setTag('org', newValue.name);
    state.orgDetails = newValue;
  },
  SET_STRIPE_USER_ID(state, newValue) {
    state.stripeUserId = newValue;
  },
  SET_STRIPE_PUBLISHABLE_KEY(state, newValue) {
    state.stripePublishableKey = newValue;
  },
  SET_CARDKNOX_DATA(state, newValue) {
    state.cardknoxData = newValue;
  },
  SET_SUGGESTED_ADDRESS_COUNTRY(state, newValue) {
    state.suggestedAddressCountry = newValue;
  }
};

export const getters = {
  isTextLauncher(state) {
    if (!state.settings.displayOverlayButton) {
      return false;
    }
    return state.settings.buttonType === 'text';
  }
};

export const actions = {
  init({ dispatch }) {
    dispatch('fetchPublicOrgSettings');
  },

  async fetchPublicOrgSettings({ commit, rootState }) {
    try {
      const { data, error } = await request.get(`/public/org-settings/${rootState.orgId}`);
      if (error) {
        throw new Error(data);
      }

      commit('SET_DEFAULT_SETTINGS', data);
      commit('SET_ORG_DETAILS', { name: data.name, website: data.website });
      // TODO: handle if missing any gateway connection
      const cardGateway = data.cardGateway || CC_GATEWAYS.STRIPE;

      commit('donation/SET_CC_GATEWAY', cardGateway, { root: true }); // For initial elements mounting

      switch (true) {
        case cardGateway === CC_GATEWAYS.STRIPE:
          commit('SET_STRIPE_USER_ID', data.stripe_user_id);
          commit('SET_STRIPE_PUBLISHABLE_KEY', data.stripe_publishable_key);
          break;

        case cardGateway === CC_GATEWAYS.CARDKNOX:
          commit('SET_CARDKNOX_DATA', data.cardknoxData);
          break;

        default:
          throw new Error('Unknown gateway!');
      }

      commit('SET_SUGGESTED_ADDRESS_COUNTRY', data.meta?.country);
      const DEFAULT_CURRENCY = 'USD';
      const currency = data.meta?.currency || DEFAULT_CURRENCY;
      commit('donation/SET_CURRENCY', currency, { root: true });
      const { orgDefaultCurrency } = data.meta;
      commit('donation/SET_ORG_DEFAULT_CURRENCY', orgDefaultCurrency, { root: true });

      return data;
    } catch (err) {
      console.error(err);
    }
  }
};
