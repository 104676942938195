<script>
// Dear developer, before you start to mess around with this file, PLEASE talk to me: shayaulman@gmail.com
import { mapState, mapMutations, mapGetters } from 'vuex';
import StripeService from '@/services/stripe';

import { GATEWAYS, widgetViews } from '@/utils';
import FormLayout from '@/layouts/FormLayout.vue';
import FormMainDetails from '@/components/FormMainDetails.vue';
import FormOften from '@/components/FormOften.vue';
import FormAdditionalOptions from '@/components/FormAdditionalOptions.vue';
import FormPaymentButtons from '@/components/FormPaymentButtons.vue';
import TotalDonation from '@/components/TotalDonation.vue';

// import StandWithIsraelFooter from '@/components/StandWithIsraelFooter.vue';

export default {
  name: 'Form',
  props: {
    firstView: Boolean
  },
  components: {
    FormLayout,
    FormMainDetails,
    FormOften,
    FormAdditionalOptions,
    FormPaymentButtons,
    TotalDonation
    // StandWithIsraelFooter
  },
  data() {
    return {
      widgetViews,
      stepsThatWereActive: {
        1: true
      },
      showErrors: {},
      areFieldsDirty: false,
      registerOutsideClick: false
    };
  },

  computed: {
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapState('campaign', ['settings']),
    ...mapGetters('campaign', ['hasActiveGoalMeter']),

    frequency() {
      return this.donation.frequency;
    },
    hideFrequencyToggle() {
      return this.settings.frequenciesEnabled?.length < 2;
    }
  },

  watch: {
    frequency: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          this.$set(this.showErrors, 1, false);
          this.$set(this.stepsThatWereActive, 2, true);
          await this.$nextTick();
        }
      }
    },
    'donation.amount': {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          this.$set(this.stepsThatWereActive, 3, true);
          await this.$nextTick();
        }
      }
    }
  },

  methods: {
    ...mapMutations(['SET_WIDGET_VIEW']),
    handleAmountClick() {
      this.$set(this.stepsThatWereActive, 1, true);
      if (!this.stepsThatWereActive[2] && !this.showErrors[2]) {
        return;
      }
    },
    handleOftenClick() {
      this.$set(this.stepsThatWereActive, 2, true);
    },
    handlePaymentSelectionClick() {
      this.$set(this.stepsThatWereActive, 3, true);
    },
    handleMainDetailsOutsideClick(event) {
      if (!this.registerOutsideClick) {
        return;
      }
      // Yes, I know, this is very fragile, do you have a better (but a complete one) solution?
      let afterSectionLayer = 420;
      if (this.hasActiveGoalMeter) {
        afterSectionLayer = 530;
      }
      const shouldDisplayError = !this.donation.amount && event.screenY > afterSectionLayer;
      if (shouldDisplayError) {
        this.displayError(1, 'formAmount');
      }
    },
    handleOftenOutsideClick(event) {
      if (!this.registerOutsideClick) {
        return;
      }
      const shouldDisplayError = !this.oftenSelected && !this.showErrors[1] && event.layerY > 430;
      if (shouldDisplayError) {
        this.displayError(2, 'formOften');
      }
    },

    displayError(stepNumber) {
      this.$set(this.showErrors, stepNumber, true);
    },
    makeFieldsDirty() {
      this.areFieldsDirty = true;
      setTimeout(() => {
        this.areFieldsDirty = false; // kinda hacky, but... (thanks tabNine for the comment)
      });
    },
    makeStepsDirty(stepsArray) {
      stepsArray.forEach((step) => {
        this.$set(this.showErrors, step, true);
      });
      this.makeFieldsDirty();
    }
  },
  created() {
    // Load Stripe in advance to avoid delay on Credit Card screen in case the user selects it
    if (this.donation.CCGateway === GATEWAYS.STRIPE) {
      StripeService.getStripeObject();
    }
  }
};
</script>

<template>
  <form-layout show-header>
    <section ref="form" class="app-form">
      <div class="form-container">
        <div class="form-content">
          <form-often
            v-if="!hideFrequencyToggle"
            v-click-outside="handleOftenOutsideClick"
            @click.native="handleOftenClick"
            :class="[{ active: stepsThatWereActive[1] }]"
            :active="stepsThatWereActive[1]"
            :show-error="showErrors[1]"
            ref="formOften"
          />

          <form-main-details
            v-click-outside="handleMainDetailsOutsideClick"
            @click.native="handleAmountClick"
            :class="[{ active: stepsThatWereActive[2] }]"
            :active="stepsThatWereActive[2]"
            :show-error="showErrors[2]"
            :fields-dirty="areFieldsDirty"
            ref="formAmount"
          />
          <section>
            <form-additional-options :fields-dirty="areFieldsDirty" ref="additionalOptions" />
          </section>
        </div>
      </div>
    </section>

    <template #footer>
      <total-donation />
      <form-payment-buttons @donation-attempt="makeFieldsDirty" @payment-method-selected="makeStepsDirty([1, 2, 3])" ref="paymentSection" />
      <button
        v-if="settings.p2pEnabled"
        @click="SET_WIDGET_VIEW(widgetViews.FUNDRAISER_SIGNUP)"
        class="mx-auto flex mt-5 cursor-pointer underline text-black-01"
        data-cy="fundraiser-cta"
      >
        {{ $t('fundraiser.cta') }}
      </button>
    </template>
  </form-layout>
</template>
