<script>
import Vue from 'vue';
import store from '@/store';
import css from '!!raw-loader!sass-loader!./widget.scss';
import globalCss from '!!raw-loader!sass-loader!@/assets/css/checkout/global.scss';
import nunito from '!!raw-loader!@/assets/fonts/nunito.css';
import appProgressBarCss from '!!raw-loader!sass-loader!@/assets/css/checkout/app-progress-bar.scss';
import utilitiesCss from '!!raw-loader!sass-loader!@/assets/css/checkout/utilities.scss';

export default {
  name: 'GoalWidgetIframe',

  render(h) {
    return h('iframe', {
      on: { load: this.renderChildren },
      attrs: {
        src: 'about:blank',
        name: 'double-goal-mete-widget',
        title: 'Progress bar Widget',
        frameborder: 0
      },
      style: {
        width: '100%',
        maxHeight: '104px'
      }
    });
  },

  methods: {
    renderChildren() {
      const children = this.$slots.default;
      const body = this.$el.contentDocument.body;
      const el = document.createElement('DIV'); // we will mount or nested app to this element
      body.appendChild(el);
      body.style.display = 'inline';
      body.style.margin = '0';

      const GoalMeterWidget = new Vue({
        name: 'GoalMeterWidget',
        store,
        //freezing to prevent unnessessary Reactifiation of vNodes
        data: { children: Object.freeze(children) },
        render(h) {
          return h('div', this.children);
        }
      });

      GoalMeterWidget.$mount(el); // mount into iframe

      this.GoalMeterWidget = GoalMeterWidget; // cache instance for later updates

      this.applyCss();
    },
    applyCss() {
      const iframe = this.$el.contentDocument;
      const styleElement = document.createElement('style');
      styleElement.textContent = css + globalCss + appProgressBarCss + utilitiesCss + nunito;
      iframe.head.appendChild(styleElement);
    }
  },

  beforeUpdate() {
    // freezing to prevent unnecessary reactifiation of vNodes
    if (!this.GoalMeterWidget) {
      return;
    }
    this.GoalMeterWidget.children = Object.freeze(this.$slots.default);
  }
};
</script>
