<script>
export default {
  name: 'DoubleLogo',
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator(value) {
        return ['dark', 'light', 'grayscale'].includes(value);
      }
    },
    isLink: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    fill() {
      if (this.theme === 'grayscale') {
        return 'var(--gray)';
      }

      const fill = {
        dark: '#2C3381',
        light: '#fff'
      };
      return fill[this.theme] || fill['dark'];
    }
  }
};
</script>

<template>
  <div class="double-logo">
    <span :is="isLink ? 'a' : 'span'" href="https://double.giving" target="_blank">
      <!-- <svg x="0px" y="0px" width="90px" height="16px" viewBox="0 0 150 42.837">
        <g>
          <path
            fill="#2C3381"
            d="M95.147,14.144c-1.248-0.734-2.667-1.102-4.257-1.102c-1.57,0-2.979,0.367-4.227,1.102   c-1.248,0.735-2.234,1.735-2.959,3.004s-1.097,2.698-1.116,4.287v13.001c0,0.442,0.145,0.809,0.437,1.103   c0.292,0.29,0.659,0.437,1.102,0.437c0.464,0,0.836-0.146,1.118-0.437c0.282-0.294,0.423-0.66,0.423-1.103v-7.385   c0.703,0.845,1.555,1.515,2.552,2.007c0.996,0.494,2.087,0.74,3.274,0.74c1.47,0,2.788-0.367,3.956-1.103   c1.166-0.733,2.088-1.729,2.762-2.989c0.674-1.257,1.012-2.682,1.012-4.271c0-1.589-0.367-3.019-1.103-4.287   C97.387,15.879,96.395,14.879,95.147,14.144 M95.556,24.319c-0.473,0.855-1.112,1.529-1.918,2.022   c-0.805,0.493-1.72,0.739-2.747,0.739c-1.006,0-1.917-0.246-2.731-0.739c-0.815-0.493-1.455-1.167-1.917-2.022   c-0.465-0.855-0.695-1.816-0.695-2.884c0-1.087,0.23-2.058,0.695-2.912c0.462-0.856,1.102-1.53,1.917-2.023   c0.814-0.493,1.726-0.74,2.731-0.74c1.027,0,1.942,0.247,2.747,0.74c0.806,0.493,1.445,1.167,1.918,2.023   c0.472,0.854,0.709,1.825,0.709,2.912C96.265,22.502,96.027,23.463,95.556,24.319"
          />
          <path
            fill="#2C3381"
            d="M107.76,26.541h-0.756c-0.442,0-0.806-0.242-1.087-0.726c-0.281-0.482-0.424-1.097-0.424-1.842V8.417   c0-0.442-0.14-0.805-0.421-1.087c-0.283-0.281-0.644-0.422-1.086-0.422c-0.444,0-0.808,0.141-1.088,0.422   c-0.282,0.282-0.423,0.645-0.423,1.087v15.556c0,1.088,0.19,2.049,0.574,2.883c0.381,0.836,0.916,1.495,1.6,1.979   c0.685,0.483,1.469,0.725,2.354,0.725h0.062c0.603,0,1.095-0.141,1.479-0.423c0.381-0.282,0.574-0.645,0.574-1.088   c0-0.441-0.127-0.804-0.378-1.087C108.488,26.68,108.16,26.541,107.76,26.541"
          />
          <path
            fill="#2C3381"
            d="M141.417,22.718c-0.383-0.613-1.012-1.152-1.886-1.614c-0.877-0.464-2.07-0.846-3.579-1.148   c-1.087-0.221-1.913-0.447-2.475-0.679c-0.564-0.23-0.942-0.493-1.133-0.784c-0.191-0.293-0.287-0.62-0.287-0.982   c0-0.583,0.307-1.026,0.922-1.328c0.612-0.303,1.361-0.452,2.248-0.452c0.765,0,1.438,0.09,2.023,0.271   c0.583,0.181,1.127,0.513,1.63,0.996c0.302,0.281,0.674,0.464,1.117,0.543c0.442,0.081,0.824,0.011,1.146-0.211   c0.322-0.242,0.493-0.538,0.514-0.89c0.02-0.354-0.11-0.69-0.393-1.013c-0.604-0.744-1.419-1.329-2.445-1.751   s-2.203-0.634-3.532-0.634c-1.025,0-2.014,0.166-2.959,0.498s-1.722,0.835-2.325,1.51c-0.604,0.674-0.905,1.524-0.905,2.552   c0,1.166,0.459,2.152,1.374,2.958c0.916,0.805,2.39,1.409,4.423,1.812c1.569,0.281,2.651,0.645,3.246,1.086   c0.592,0.444,0.879,0.967,0.859,1.57c-0.021,0.604-0.337,1.097-0.951,1.479c-0.614,0.382-1.505,0.573-2.672,0.573   c-0.925,0-1.766-0.166-2.521-0.498s-1.303-0.688-1.646-1.071c-0.282-0.303-0.618-0.478-1.012-0.528s-0.79,0.086-1.191,0.406   c-0.343,0.264-0.539,0.601-0.59,1.013c-0.05,0.412,0.076,0.78,0.379,1.102c0.663,0.664,1.593,1.213,2.792,1.646   c1.197,0.433,2.5,0.648,3.909,0.648c1.429,0,2.622-0.222,3.578-0.664c0.955-0.442,1.681-1.037,2.174-1.781   c0.492-0.745,0.739-1.579,0.739-2.506C141.99,24.042,141.799,23.333,141.417,22.718"
          />
          <path
            fill="#2C3381"
            d="M78.771,22.732c-0.384-0.612-1.012-1.152-1.887-1.614c-0.877-0.464-2.069-0.847-3.578-1.148   c-1.087-0.22-1.912-0.447-2.476-0.679c-0.564-0.23-0.94-0.493-1.132-0.785c-0.192-0.292-0.288-0.619-0.288-0.981   c0-0.583,0.307-1.026,0.921-1.328s1.363-0.453,2.25-0.453c0.765,0,1.438,0.092,2.022,0.271c0.583,0.182,1.127,0.514,1.63,0.997   c0.303,0.281,0.675,0.464,1.117,0.543c0.442,0.081,0.825,0.011,1.146-0.211c0.323-0.241,0.494-0.539,0.515-0.891   c0.021-0.353-0.111-0.688-0.393-1.012c-0.604-0.744-1.419-1.329-2.444-1.751c-1.027-0.423-2.204-0.634-3.534-0.634   c-1.026,0-2.013,0.166-2.958,0.498c-0.947,0.332-1.722,0.835-2.325,1.511c-0.603,0.674-0.905,1.523-0.905,2.55   c0,1.168,0.457,2.153,1.373,2.959c0.916,0.805,2.391,1.409,4.423,1.812c1.57,0.281,2.651,0.645,3.246,1.087   c0.594,0.443,0.88,0.966,0.86,1.57c-0.021,0.604-0.338,1.096-0.951,1.479c-0.614,0.383-1.505,0.573-2.672,0.573   c-0.926,0-1.767-0.166-2.521-0.498c-0.756-0.331-1.304-0.688-1.646-1.071c-0.281-0.303-0.617-0.478-1.011-0.528   c-0.393-0.05-0.791,0.086-1.191,0.407c-0.344,0.263-0.539,0.6-0.59,1.012c-0.05,0.413,0.076,0.781,0.378,1.102   c0.664,0.664,1.594,1.213,2.793,1.646c1.196,0.434,2.499,0.649,3.909,0.649c1.43,0,2.622-0.222,3.577-0.664   c0.956-0.443,1.682-1.037,2.174-1.781c0.493-0.745,0.74-1.58,0.74-2.506C79.344,24.055,79.151,23.346,78.771,22.732"
          />
          <path
            fill="#2C3381"
            d="M23.105,6.862c-0.444,0-0.811,0.14-1.102,0.422c-0.293,0.282-0.438,0.654-0.438,1.117v7.396   c-0.686-0.845-1.53-1.514-2.537-2.008c-1.007-0.493-2.104-0.738-3.29-0.738c-1.47,0-2.789,0.366-3.956,1.102   s-2.088,1.731-2.762,2.988C8.347,18.4,8.01,19.824,8.01,21.414s0.367,3.019,1.102,4.287c0.735,1.269,1.73,2.27,2.99,3.004   c1.257,0.734,2.671,1.102,4.241,1.102c1.569,0,2.978-0.367,4.227-1.102c1.248-0.734,2.239-1.735,2.975-3.004   c0.734-1.269,1.102-2.697,1.102-4.287V8.401c0-0.463-0.141-0.835-0.423-1.117S23.567,6.862,23.105,6.862 M20.992,24.311   c-0.463,0.866-1.098,1.545-1.901,2.039c-0.806,0.493-1.722,0.739-2.748,0.739c-1.007,0-1.917-0.246-2.732-0.739   c-0.815-0.494-1.46-1.173-1.933-2.039c-0.473-0.865-0.71-1.831-0.71-2.897c0-1.087,0.237-2.054,0.71-2.898   c0.473-0.846,1.117-1.515,1.933-2.008s1.726-0.74,2.732-0.74c1.026,0,1.942,0.247,2.748,0.74c0.804,0.493,1.438,1.162,1.901,2.008   c0.463,0.845,0.693,1.812,0.693,2.898C21.686,22.48,21.455,23.446,20.992,24.311"
          />
          <path
            fill="#2C3381"
            d="M59.788,13.756c-1.097-0.594-2.338-0.891-3.728-0.891c-1.328,0-2.526,0.292-3.593,0.876   c-0.581,0.318-1.093,0.699-1.54,1.14v-0.405c0-0.462-0.141-0.834-0.422-1.116c-0.282-0.282-0.655-0.423-1.118-0.423   c-0.442,0-0.811,0.141-1.102,0.423c-0.292,0.282-0.438,0.654-0.438,1.116v13.796c0,0.443,0.146,0.812,0.438,1.103   c0.291,0.292,0.659,0.438,1.102,0.438c0.463,0,0.836-0.146,1.118-0.438c0.281-0.291,0.422-0.659,0.422-1.103v-8.764   c0-0.744,0.201-1.404,0.604-1.978c0.401-0.574,0.946-1.031,1.63-1.373c0.685-0.343,1.449-0.515,2.295-0.515   c0.925,0,1.745,0.177,2.46,0.529c0.715,0.352,1.283,0.89,1.706,1.615c0.424,0.724,0.634,1.66,0.634,2.807v7.678   c0,0.443,0.146,0.812,0.438,1.103c0.292,0.292,0.659,0.438,1.103,0.438c0.442,0,0.811-0.146,1.103-0.438   c0.291-0.291,0.438-0.659,0.438-1.103v-7.678c0-1.67-0.316-3.079-0.952-4.226C61.752,15.22,60.886,14.35,59.788,13.756"
          />
          <path
            fill="#E9266C"
            d="M43.2,32.894H29.402c-0.85,0-1.54,0.688-1.54,1.539c0,0.85,0.69,1.54,1.54,1.54H43.2   c0.85,0,1.539-0.69,1.539-1.54C44.739,33.583,44.05,32.894,43.2,32.894"
          />
          <path
            fill="#2C3381"
            d="M36.301,12.936c-4.66,0-8.438,3.777-8.438,8.438c0,4.66,3.778,8.438,8.438,8.438   c4.661,0,8.438-3.778,8.438-8.438C44.739,16.713,40.962,12.936,36.301,12.936 M36.301,26.733c-2.954,0-5.359-2.403-5.359-5.358   s2.405-5.359,5.359-5.359c2.955,0,5.358,2.404,5.358,5.359S39.256,26.733,36.301,26.733"
          />
          <path
            fill="#2C3381"
            d="M114.085,28.997c1.097,0.594,2.339,0.891,3.729,0.891c1.327,0,2.525-0.292,3.592-0.876   c0.581-0.318,1.093-0.699,1.54-1.14v0.405c0,0.462,0.141,0.834,0.422,1.117c0.283,0.281,0.655,0.422,1.118,0.422   c0.442,0,0.811-0.141,1.102-0.422c0.292-0.283,0.438-0.655,0.438-1.117V14.481c0-0.442-0.146-0.811-0.438-1.102   c-0.291-0.292-0.659-0.438-1.102-0.438c-0.463,0-0.835,0.146-1.118,0.438c-0.281,0.291-0.422,0.659-0.422,1.102v8.765   c0,0.744-0.201,1.404-0.604,1.977c-0.401,0.574-0.945,1.031-1.629,1.374c-0.686,0.342-1.45,0.514-2.296,0.514   c-0.925,0-1.745-0.177-2.46-0.528c-0.715-0.353-1.282-0.891-1.706-1.616c-0.422-0.724-0.634-1.66-0.634-2.807v-7.678   c0-0.442-0.146-0.811-0.438-1.102c-0.291-0.292-0.659-0.438-1.102-0.438c-0.441,0-0.811,0.146-1.103,0.438   c-0.292,0.291-0.438,0.659-0.438,1.102v7.678c0,1.67,0.317,3.079,0.952,4.226C112.121,27.533,112.987,28.403,114.085,28.997"
          />
        </g>
      </svg> -->
      <svg
        class="double-logo"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="44"
        viewBox="0 0 250 74.35"
        style="enable-background: new 0 0 250 74.35"
        xml:space="preserve"
      >
        <g>
          <path
            :fill="fill"
            d="M203.54,50.22h-1.84c-1.08,0-1.96-0.59-2.64-1.76s-1.03-2.67-1.03-4.48V5.58c0-1.07-0.34-1.95-1.03-2.64
		c-0.69-0.68-1.57-1.03-2.64-1.03c-1.08,0-1.96,0.34-2.64,1.03c-0.68,0.69-1.03,1.57-1.03,2.64v38.4c0,2.64,0.46,4.98,1.39,7
		c0.93,2.03,2.23,3.63,3.89,4.81c1.66,1.17,3.57,1.76,5.72,1.76h0.15c1.46,0,2.66-0.34,3.59-1.03c0.93-0.69,1.39-1.57,1.39-2.64
		c0-1.07-0.31-1.95-0.92-2.64C205.32,50.57,204.52,50.22,203.54,50.22z"
          />
          <path
            :fill="fill"
            d="M36.67,1.93c-1.08,0-1.97,0.34-2.68,1.03c-0.71,0.69-1.06,1.59-1.06,2.71v17.86
		c-1.66-2.05-3.72-3.68-6.16-4.88c-2.45-1.2-5.11-1.8-8-1.8c-3.57,0-6.77,0.89-9.61,2.68c-2.84,1.79-5.07,4.21-6.71,7.26
		C0.82,29.85,0,33.31,0,37.17s0.89,7.33,2.68,10.41c1.79,3.08,4.2,5.52,7.26,7.3c3.06,1.79,6.49,2.68,10.3,2.68
		s7.24-0.89,10.27-2.68c3.03-1.78,5.44-4.22,7.22-7.3c1.78-3.08,2.68-6.55,2.68-10.41V5.67c0-1.12-0.34-2.03-1.03-2.71
		C38.7,2.27,37.8,1.93,36.67,1.93z M31.54,44.21c-1.12,2.1-2.67,3.75-4.62,4.95c-1.96,1.2-4.18,1.8-6.68,1.8
		c-2.45,0-4.66-0.6-6.64-1.8c-1.98-1.2-3.55-2.85-4.7-4.95c-1.15-2.1-1.72-4.45-1.72-7.04c0-2.64,0.58-4.99,1.72-7.04
		c1.15-2.05,2.71-3.68,4.7-4.88c1.98-1.2,4.19-1.8,6.64-1.8c2.49,0,4.72,0.6,6.68,1.8c1.95,1.2,3.5,2.82,4.62,4.88
		c1.13,2.05,1.69,4.4,1.69,7.04C33.23,39.76,32.66,42.11,31.54,44.21z"
          />
          <path
            :fill="fill"
            d="M143.43,2.83c-0.68,0.69-1.03,1.59-1.03,2.71v31.61c0,3.86,0.89,7.33,2.68,10.41c1.79,3.08,4.19,5.52,7.22,7.3
		c3.03,1.79,6.46,2.68,10.27,2.68c3.81,0,7.25-0.89,10.3-2.68c3.06-1.78,5.48-4.22,7.26-7.3c1.79-3.08,2.68-6.55,2.68-10.41
		s-0.82-7.32-2.46-10.38c-1.64-3.06-3.88-5.48-6.71-7.26c-2.84-1.79-6.04-2.68-9.61-2.68c-2.88,0-5.55,0.6-8,1.8
		c-2.45,1.2-4.5,2.83-6.16,4.88V5.54c0-1.12-0.35-2.03-1.06-2.71c-0.71-0.68-1.6-1.03-2.68-1.03C145.02,1.8,144.12,2.14,143.43,2.83
		z M149.59,37.16c0-2.64,0.56-4.99,1.69-7.04c1.12-2.05,2.67-3.68,4.62-4.88c1.96-1.2,4.18-1.8,6.68-1.8c2.45,0,4.66,0.6,6.64,1.8
		c1.98,1.2,3.55,2.82,4.7,4.88c1.15,2.05,1.72,4.4,1.72,7.04c0,2.59-0.58,4.94-1.72,7.04c-1.15,2.1-2.71,3.75-4.7,4.95
		c-1.98,1.2-4.19,1.8-6.64,1.8c-2.49,0-4.72-0.6-6.68-1.8c-1.95-1.2-3.5-2.85-4.62-4.95C150.15,42.09,149.59,39.75,149.59,37.16z"
          />
          <path
            :fill="fill"
            d="M68.73,16.56c-11.32,0-20.5,9.18-20.5,20.5c0,11.32,9.18,20.5,20.5,20.5s20.5-9.18,20.5-20.5
		C89.23,25.74,80.05,16.56,68.73,16.56z M68.73,50.08c-7.18,0-13.02-5.84-13.02-13.02c0-7.18,5.84-13.02,13.02-13.02
		s13.02,5.84,13.02,13.02C81.75,44.24,75.91,50.08,68.73,50.08z"
          />
          <path
            :fill="fill"
            d="M105.41,55.41c2.67,1.44,5.68,2.16,9.06,2.16c3.23,0,6.14-0.71,8.73-2.13c1.41-0.77,2.65-1.7,3.74-2.77v0.98
		c0,1.12,0.34,2.03,1.03,2.71c0.69,0.68,1.59,1.03,2.71,1.03c1.08,0,1.97-0.34,2.68-1.03c0.71-0.69,1.06-1.59,1.06-2.71V20.15
		c0-1.08-0.35-1.97-1.06-2.68c-0.71-0.71-1.6-1.06-2.68-1.06c-1.12,0-2.03,0.35-2.71,1.06c-0.68,0.71-1.03,1.6-1.03,2.68v21.29
		c0,1.81-0.49,3.41-1.47,4.8c-0.98,1.39-2.3,2.5-3.96,3.34c-1.66,0.83-3.52,1.25-5.57,1.25c-2.25,0-4.24-0.43-5.98-1.28
		c-1.74-0.85-3.12-2.16-4.14-3.92c-1.03-1.76-1.54-4.03-1.54-6.82V20.15c0-1.08-0.35-1.97-1.06-2.68c-0.71-0.71-1.6-1.06-2.68-1.06
		c-1.07,0-1.97,0.35-2.68,1.06c-0.71,0.71-1.06,1.6-1.06,2.68V38.8c0,4.06,0.77,7.48,2.31,10.27
		C100.64,51.86,102.74,53.97,105.41,55.41z"
          />
          <path
            :fill="theme === 'grayscale' ? fill : '#e9266c'"
            d="M85.49,65.06H51.97c-2.07,0-3.74,1.67-3.74,3.74v0c0,2.07,1.67,3.74,3.74,3.74h33.52
		c2.07,0,3.74-1.67,3.74-3.74v0C89.23,66.74,87.56,65.06,85.49,65.06z"
          />
          <path
            :fill="fill"
            d="M249.79,34.13c-1.42-9.93-9.98-17.59-20.29-17.59c-10.31,0-18.87,7.66-20.29,17.59
		c-0.14,0.95-0.21,1.92-0.21,2.91c0,0.99,0.07,1.96,0.21,2.91c1.42,9.93,9.98,17.59,20.29,17.59c5.27,0,10.28-2,14.11-5.62
		c0.43-0.4,0.83-0.82,1.22-1.26c1.83-2.06,0.68-5.34-2.04-5.83h0c-1.15-0.21-2.33,0.16-3.16,1c-0.41,0.41-0.79,0.79-0.93,0.92
		c-2.49,2.37-5.76,3.67-9.2,3.67c-6.38,0-11.72-4.48-13.06-10.46h17.61h8.51h4.53c1.6,0,2.91-1.29,2.91-2.88c0-0.01,0-0.02,0-0.03
		C250,36.06,249.93,35.08,249.79,34.13z M216.44,34.13c1.33-5.98,6.68-10.46,13.06-10.46c6.38,0,11.72,4.48,13.06,10.46H216.44z"
          />
        </g>
      </svg>
    </span>
  </div>
</template>
