<script setup>
import { useState, defineProps } from 'vuex-composition-helpers';
const { isMultiEntity } = useState('publicOrgSettings', ['isMultiEntity']);

defineProps(['gateway']);
</script>

<template>
  <div v-if="isMultiEntity" class="flex items-start my-2 rounded-lg p-3 text-xs gap-2"
    style="background: rgba(9, 27, 39, 0.05); color: var(--black-01)">
    <app-icon name="info" type="svg" width="12" height="12" class="mt-0.5 text-black fill-current" />
    <p>
      Your donation will be made to {{ gateway.entityName }} Tax ID {{ gateway.entityTaxId }}. You'll receive a
      tax-deductible receipt recognized in
      {{ gateway.country }}.
    </p>
  </div>
</template>
