export class Storage {
  static #nullStorage = {
    getItem() {},
    setItem() {},
    removeItem() {}
  };

  static #cookieBasedStorage = {
    getItem(key, defaultValue) {
      // https://stackoverflow.com/a/64472572
      return Object.fromEntries(document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)))[key] ?? defaultValue;
    },
    setItem(key, value) {
      document.cookie = `${key}=${value}`;
    },
    removeItem(key) {
      document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
    }
  };

  static #storage = this.#storageAvailable(localStorage)
    ? localStorage
    : this.#storageAvailable(this.#cookieBasedStorage)
    ? this.#cookieBasedStorage
    : this.#nullStorage;

  static #storageAvailable(storage) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
    try {
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }

  static getItem(key, defaultValue) {
    return this.#storage.getItem(key) ?? defaultValue;
  }

  static setItem(key, value) {
    this.#storage.setItem(key, value);
  }

  static removeItem(key) {
    this.#storage.removeItem(key);
  }
}
