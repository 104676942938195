<script>
const colorValidator = (colorValue) => {
  const isHexColor = colorValue[0] === '#' && colorValue.length === 7;
  const isCustomCssProp = colorValue.indexOf('var(--') > -1; // TODO Regex recipe
  return isHexColor || isCustomCssProp;
};
// https://codepen.io/regis_011/pen/Yzzgdog
export default {
  name: 'AppProgressbar',
  props: {
    value: {
      type: Number,
      default: 0
    },
    progressColor: {
      type: String,
      default: '#ffffff',
      validator: (v) => colorValidator(v)
    },
    backgroundColor: {
      type: String,
      default: '#00000033'
    }
  },
  data: () => ({
    barWidth: 0
  }),
  mounted() {
    // necessary for animtang the bar
    setTimeout(() => {
      this.barWidth = this.value;
    }, 10);
  }
};
</script>

<template>
  <div class="app-progressbar" :style="{ background: backgroundColor }">
    <div :style="{ background: progressColor, width: `${barWidth}%` }" class="bar"></div>
  </div>
</template>
