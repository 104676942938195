/*
  |--------------------------------------------------------------------------
  | Nudges logic
  |--------------------------------------------------------------------------
  | - If the widget is open, we don't show any nudges.
  | - If the widget is closed: A recent donation has the first priority.
  | - If there is no recent donation, we show a CTA nudge:
  |   - The 1'st nudge shows up after 10 seconds
  |   - The 2'nd & 3'rd nudges will show up only if user hasn't made a donation:
  |   - The 2'nd nudge shows up after 60 seconds, or after 60 seconds from the widget closure
  |   - The 3'rd nudge shows up after 120 seconds, or after 60 seconds from the widget closure
  | - No nudge is shown twice
  | - Right now when a nudge's text is empty we handle it as inactive but the timer will continue to run
  |   seconds for a potential following nudge. so if nudge 1 (required) and nudge 3 are active, there will
  |   be a 120 second pause between them.
*/

import store from '@/store';
import { Storage } from '@/utils';

const SHOW_TOASTER_SECONDS = 10;
const CHECK_RECENT_DONATION_SECONDS = 60;

function hasUserDonatedRecently(numOfHours) {
  const recentDonationTimeStamp = Storage.getItem('Double.recentDonationTimeStamp');
  return recentDonationTimeStamp && Date.now() - recentDonationTimeStamp < 1000 * 60 * 60 * numOfHours;
}

function secondsToMs(seconds) {
  return seconds * 1000;
}

export default {
  data: () => ({
    forceClose: false,
    ctaMessage: null,
    nudgeToShowIndex: 0,
    ctaTimer: 0,
    timerInterval: null,
    toasterType: null
  }),

  computed: {
    showToaster() {
      return this.toasterType && !this.isWidgetOpen && !this.forceClose;
    }
  },

  watch: {
    // Little hack to make sure that toaster will always rerender when it's content changes
    async toasterType() {
      this.forceClose = true;
      await this.$nextTick();
      this.forceClose = false;
    },
    isWidgetOpen(value) {
      if (value) {
        this.widgetWasOpen = true;
        clearInterval(this.timerInterval);
      } else {
        this.ctaTimer = 0;
        if (this.widgetWasOpen) {
          this.runCtaTimer(); // start nudging again if user closed the widget without donation
          if (hasUserDonatedRecently(24)) {
            clearInterval(this.timerInterval);
          }
        }
      }
    },
    nudgeToShowIndex(value) {
      if (value > this.publicOrgSettings.ctaNudges.length - 1) {
        clearInterval(this.timerInterval);
      }
    }
  },

  methods: {
    nudgeToDisplay(seconds) {
      // first message
      if (this.nudgeToShowIndex === 0 && seconds === 11) {
        this.ctaTimer = 0;
        return this.publicOrgSettings.ctaNudges[0];
      }
      // second & third message
      if (seconds === 60) {
        this.ctaTimer = 0;
        return this.publicOrgSettings.ctaNudges[this.nudgeToShowIndex];
      }

      return null;
    },
    runCtaTimer() {
      if (!this.publicOrgSettings.showCtaNudges) return;
      this.timerInterval = setInterval(() => {
        this.ctaTimer += 1;
        this.displayCtaNudge(this.nudgeToDisplay(this.ctaTimer));
      }, secondsToMs(1));
    },
    displayCtaNudge(nudge) {
      if (nudge && nudge.text) {
        this.ctaMessage = nudge.text;
        this.toasterType = 'cta';
        setTimeout(() => {
          this.toasterType = null;
          this.nudgeToShowIndex += 1; // the following nudge
        }, secondsToMs(SHOW_TOASTER_SECONDS));
      }
    },
    showDonorWall() {
      setInterval(async () => {
        const donation = await store.dispatch('donorWall/fetchMostRecentDonation', { minutes: 1 });
        if (donation) {
          this.recentDonation = donation;
          this.toasterType = 'donation';
          setTimeout(() => {
            this.toasterType = null;
          }, secondsToMs(SHOW_TOASTER_SECONDS));
        }
      }, secondsToMs(CHECK_RECENT_DONATION_SECONDS)); // Check every minute for a new donation
    }
  },

  mounted() {
    this.runCtaTimer();
    if (hasUserDonatedRecently(24)) {
      clearInterval(this.timerInterval);
    }
  }
};
