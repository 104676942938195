<script>
import Vue from 'vue';
import store from '@/store';
import VueI18n from 'vue-i18n';

import { loadScript } from '@/utils';
import localization from '@/services/localization';

import nunito from '!!raw-loader!@/assets/fonts/nunito.css';
import staticFormCss from '!!raw-loader!sass-loader!./widget.scss';
import css from '!!raw-loader!sass-loader!@/assets/css/checkout/index.scss';

Vue.use(VueI18n);
const i18n = new VueI18n(localization);
var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

export default {
  render(h) {
    return h('iframe', {
      on: { load: this.renderChildren },
      attrs: {
        src: 'about:blank',
        frameborder: 0,
        title: 'Donation Form',
        width: '100%'
        // Height is set dynamically
      },
      style: {
        width: '100%', // todo: optimize for mobile
        maxWidth: '470px'
      }
    });
  },

  watch: {
    css(newValue, oldValue) {
      // Handles case css was not loaded when component was mounted
      if (!oldValue && newValue) {
        this.applyCss();
      }
    }
  },

  methods: {
    renderChildren() {
      // Form is temporarily disabled
      const children = this.$slots.default;
      const body = this.$el.contentDocument.body;
      const div = document.createElement('DIV');

      body.appendChild(div);

      const iframeHead = this.$el.contentDocument.head;
      loadScript('https://unpkg.com/ionicons@5.0.0/dist/ionicons/ionicons.js', {
        target: iframeHead,
        type: 'module'
      });

      const FormIframe = new Vue({
        name: 'FormIframe',
        store,
        i18n,

        // freezing to prevent unnecessary reactifiation of vNodes
        data: { children: Object.freeze(children) },
        render(h) {
          return h('div', this.children);
        }
      });

      FormIframe.$mount(div); // mount into iframe
      this.FormIframe = FormIframe; // cache instance for later updates

      this.applyCss();
      this.setIframeHeight();
    },
    applyCss() {
      const iframe = this.$el.contentDocument;
      const styleElement = document.createElement('style');
      styleElement.textContent = css + staticFormCss + nunito;
      iframe.head.appendChild(styleElement);
    },
    setIframeHeight() {
      // This function needs to be called whenever the iframe size changes
      const iframe = this.$el.contentDocument;
      if (iframe) {
        // reset in case it was set to a bigger value
        this.$el.height = '0px';
        // Set min height to 320px if mutation observer is not triggered (Firefox)
        const height = Math.max(iframe.body.scrollHeight, 320);
        this.$el.height = height + 'px';
      }
    },

    createMutationObserver() {
      const target = this.$el.contentDocument.body;

      const config = {
        attributes: true,
        attributeOldValue: false,
        characterData: true,
        characterDataOldValue: false,
        childList: true,
        subtree: true
      };

      const observer = new MutationObserver(() => {
        this.setIframeHeight();
      });

      observer.observe(target, config);

      // Not sure if this is triggered
      this.$el.addEventListener('DOMSubtreeModified', () => {
        this.setIframeHeight();
      });
    }
  },

  beforeUpdate() {
    if (!this.FormIframe) {
      return;
    }
    // freezing to prevent unnecessary reactifiation of vNodes
    this.FormIframe.children = Object.freeze(this.$slots.default);
  },

  mounted() {
    if (MutationObserver) {
      this.createMutationObserver();
    }
  }
};
</script>
