import request from '@/services/request';

export const state = {
  id: null,
  userId: null,
  firstName: '',
  lastName: '',
  goal: 0,
  raised: 0,
  message: '',
  hideInfo: false,
  avatarUrl: null
};

export const mutations = {
  SET_USER_ID(state, user) {
    state.id = user.id;
  },
  SET_FIRST_NAME(state, user) {
    state.firstName = user.firstName;
  },
  SET_LAST_NAME(state, user) {
    state.lastName = user.lastName;
  },
  SET_GOAL(state, fundraiserInfo) {
    state.goal = fundraiserInfo.goal;
  },
  SET_RAISED(state, raised) {
    state.raised = raised;
  },
  SET_MESSAGE(state, fundraiserInfo) {
    state.message = fundraiserInfo.message;
  },
  SET_AVATAR_URL(state, user) {
    state.avatarUrl = user.avatarUrl;
  },
  SET_HIDE_INFO(state, fundraiserInfo) {
    state.hideInfo = fundraiserInfo.hideInfo;
  }
};

export const getters = {
  fundraiserFullName(state) {
    return `${state.firstName} ${state.lastName}`;
  }
};

export const actions = {
  async fetchFundraiser({ commit, rootState }, { fundraiserSlug }) {
    const orgId = rootState.orgId;
    const { data, error } = await request.get(`/public/fundraiser/${orgId}/${fundraiserSlug}`);

    if (error) {
      throw new Error(data);
    }

    const fundraiser = data.fundraiser;
    const userOrgId = fundraiser.id; // this id is used for associating donations with fundraisers
    commit('donation/SET_FUNDRAISER_ID', userOrgId, { root: true });
    commit('SET_RAISED', fundraiser.chargeTotal);
    commit('SET_USER_ID', fundraiser.user);
    commit('SET_FIRST_NAME', fundraiser.user);
    commit('SET_LAST_NAME', fundraiser.user);
    commit('SET_GOAL', fundraiser.fundraiserInfo);
    commit('SET_MESSAGE', fundraiser.fundraiserInfo);
    commit('SET_AVATAR_URL', fundraiser.user);
    commit('SET_HIDE_INFO', fundraiser.fundraiserInfo);

    return data;
  }
};
