import { loadScript } from '@/utils';
// import store from '@/store';

let growObject = null;
let eventCallbacks = {
  onSuccess: () => {},
  onFailure: () => {},
  onError: () => {},
  onTimeout: () => {},
  onWalletChange: () => {}
};

const IFRAME_SELECTOR = 'iframe[name="double-checkout"]';

export default {
  setEventCallbacks(callbacks) {
    eventCallbacks = { ...eventCallbacks, ...callbacks };
  },

  async getGrowObject() {
    if (growObject) {
      return growObject;
    }

    const widgetIframe = document.querySelector(IFRAME_SELECTOR);
    const iframeHead = widgetIframe.contentDocument.head;
    // Load on parent website
    loadScript('https://meshulam.co.il/_media/js/apple_pay_sdk/sdk.min.js', { target: document.head });
    // Load also in iframe (not sure atm which one is needed)
    loadScript('https://meshulam.co.il/_media/js/apple_pay_sdk/sdk.min.js', { target: iframeHead });

    // Load in iframe
    return loadScript('https://cdn.meshulam.co.il/sdk/gs.min.js', {
      target: iframeHead,
      isAsync: true
    }).then(() => {
      console.log(process.env.NODE_ENV);
      let config = {
        environment: process.env.NODE_ENV === 'development' ? 'DEV' : 'PRODUCTION',
        version: 1,
        events: {
          onSuccess: (response) => {
            console.log('success', response);
            eventCallbacks.onSuccess(response);
          },
          onFailure: (response) => {
            console.log('failure', response);
            eventCallbacks.onFailure(response);
          },
          onError: (response) => {
            console.log('error', response);
            eventCallbacks.onError(response);
          },
          onTimeout: (response) => {
            console.log('timeout', response);
            eventCallbacks.onTimeout(response);
          },
          onWalletChange: (state) => {
            console.log('wallet change', state);
            eventCallbacks.onWalletChange(state);
          }
        }
      };
      growObject = {
        init: () => widgetIframe.contentWindow.growPayment.init(config),
        renderPaymentOptions: widgetIframe.contentWindow.growPayment.renderPaymentOptions
      };
      // const cardknoxData = store.state.publicOrgSettings.cardknoxData;
      // widgetIframe.contentWindow.setAccount(cardknoxData.iFieldsKey, SOFTWARE_NAME, '1.0.0');

      return growObject;
    });
  }
};
