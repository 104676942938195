<script>
export default {
  name: 'FormLocale',
  data() {
    return {
      model: {}
    };
  },
  methods: {}
};
</script>

<template>
  <div class="form-locale"></div>
</template>
