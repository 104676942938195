<script>
export default {
  name: 'AppCheckbox',
  props: ['value'],
  methods: {
    checkChanged(e) {
      this.$emit('input', e.target.checked);
    }
  }
};
</script>

<template>
  <label class="app-checkbox">
    <input type="checkbox" :checked="value" @change="checkChanged" />
    <span class="icon">
      <ion-icon name="checkbox" class="checkbox"></ion-icon>
      <ion-icon class="square-outline" name="square-outline"></ion-icon>
    </span>
    <span class="slot-label">
      <slot></slot>
    </span>
  </label>
</template>
