<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('campaign', {
      campaign: (state) => state
    }),
    donateText() {
      const ctaTerm = this.campaign.settings?.ctaTerm?.toLowerCase();
      if (!ctaTerm) {
        return this.$t('donorWall.cta.donate');
      }
      return this.$t(`donorWall.cta.${ctaTerm}`);
    }
  }
};
</script>

<template>
  <footer class="footer-sticky sticky-donate-button w-full bg-white fixed bottom-0 right-0 left-0 px-6 pt-4">
    <slot name="content" />
    <app-cta-button @click="$emit('donate-clicked')">
      {{ donateText }}
    </app-cta-button>
    <slot name="icons" />
    <slot name="fundraiser-cta" />
    <double-logo :is-link="true" theme="grayscale" class="text-center my-3" />
  </footer>
</template>
