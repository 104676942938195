export default {
  bind(el) {
    const hebrewRegex = /[\u0590-\u05FF]/;
    if (hebrewRegex.test(el.textContent)) {
      el.setAttribute('dir', 'rtl');
    } else {
      el.setAttribute('dir', 'ltr');
    }
  },

  update(el) {
    const hebrewRegex = /[\u0590-\u05FF]/;
    if (hebrewRegex.test(el.textContent)) {
      el.setAttribute('dir', 'rtl');
    } else {
      el.setAttribute('dir', 'ltr');
    }
  }
};
