<script>
/*
  !Read this before messing around with this file!
  Nudges logic:
  - If the widget is open, we don't show any nudges.
  - If the widget is closed: A recent donation has the first priority.
    - If there is no recent donation, we show a CTA nudge:
      - The 1'st nudge shows up after 10 seconds
      - The 2'nd & 3'rd nudges will show up only if user hasn't made a donation:
        - The 2'nd nudge shows up after 60 seconds, or after 60 seconds from the widget closure
        - The 3'rd nudge shows up after 120 seconds, or after 60 seconds from the widget closure
  - No nudge is shown twice
  - Right now when a nudge's text is empty we handle it as inactive but the timer will continue to run
    seconds for a potential following nudge. so if nudge 1 (required) and nudge 3 are active, there will
    be a 120 second pause between them.
  */
import { get } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { getCurrencySymbol } from '@/utils';

export default {
  props: {
    recentDonation: Object,
    type: String,
    ctaMessage: String,
    nudgeToShowIndex: Number
  },

  data: () => ({
    getCurrencySymbol
  }),

  computed: {
    ...mapState('publicOrgSettings', ['settings']),
    recentDonorName() {
      return get(this.recentDonation, 'donor.firstName') || 'Anonymous Donor';
    },
    recentDonorLocation() {
      return get(this.recentDonation, 'donor.country');
    }
  },

  methods: {
    ...mapActions(['setWidgetOpen'])
  }
};
</script>

<template>
  <button @click="setWidgetOpen(true)" class="widget-toaster">
    <div v-if="type === 'cta'">
      <h3 v-if="type === 'cta'" :style="`color: ${settings.themeColor};`" class="toaster-title">
        {{ nudgeToShowIndex === 0 ? 'Hello There!' : 'Donate Now!' }}
      </h3>
      <p v-auto-dir class="toaster-message">{{ ctaMessage }}</p>
    </div>
    <div v-else-if="type === 'donation' && recentDonation">
      <p class="toaster-message">{{ recentDonorName }} {{ !recentDonorLocation ? `from israel` : '' }} has just donated</p>
      <h3 :style="`color: ${settings.themeColor};`" class="toaster-title">{{ getCurrencySymbol(recentDonation.currency) }}{{ recentDonation.amount }}</h3>
    </div>
  </button>
</template>
