<script>
import { get } from 'lodash';
import { mapMutations, mapActions } from 'vuex';

import { widgetViews, handleNumberInput } from '@/utils';
import FormLayout from '@/layouts/FormLayout.vue';
import AppButton from '@/common/AppButton.vue';
import AppAvatar from '@/common/AppAvatar.vue';
import autosize from 'autosize';

export default {
  name: 'FundraiserSignup',
  components: {
    FormLayout,
    AppButton,
    AppAvatar
  },

  data: () => ({
    widgetViews,
    handleNumberInput,
    fundraiserForm: {
      message: ''
    },
    avatar: null,
    isProcessing: false,
    errorMessage: '',
    isFormValid: false,
    isAvatarValid: true
  }),

  computed: {
    textLength() {
      if (!get(this.fundraiserForm, 'message')) {
        return '0/150 Characters';
      }

      return `${this.fundraiserForm.message.length || 0}/150 Characters`;
    }
  },

  watch: {
    fundraiserForm: {
      async handler(newValue) {
        this.SET_FUNDRAISER_SIGNUP(newValue);
        this.isFormValid = !((await this.$refs.form.hasValidationErrors()) && (this.avatar ? this.isAvatarValid : true));
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations(['SET_WIDGET_VIEW']),
    ...mapMutations('fundraiserSignup', ['SET_FUNDRAISER_SIGNUP']),
    ...mapActions('fundraiserSignup', ['fundraiserSignup']),
    resize(ref, update = false) {
      const textarea = this.$refs[ref].$el.querySelector('textarea');
      if (update) {
        return autosize.update(textarea);
      }

      autosize(textarea);
    },
    async handleSubmit() {
      if (this.isProcessing) {
        return;
      }
      this.isProcessing = true;
      try {
        await this.fundraiserSignup({ avatar: this.avatar });
        this.SET_WIDGET_VIEW(widgetViews.CONFIRMATION_FUNDRAISER);
      } catch (error) {
        this.errorMessage = error.message;
        console.log(error);
      } finally {
        this.isProcessing = false;
      }
    }
  }
};
</script>

<template>
  <form-layout :title="$t('fundraiser.cta')" show-header>
    <formulate-form @submit="handleSubmit" v-model="fundraiserForm" id="fundraiser-form" class="form slide-up" ref="form">
      <div class="flex flex-col mt-8">
        <h4 class="body-text-extra-bold mb-2">Personal information</h4>
        <div class="flex gap-2.5 items-center">
          <div class="flex-1 flex flex-col gap-2.5">
            <formulate-input
              name="firstName"
              validation="required"
              :validation-name="$t('common.firstName')"
              :placeholder="$t('common.firstName')"
              data-cy="first-name"
            />
            <formulate-input
              name="lastName"
              validation="required"
              :validation-name="$t('common.lastName')"
              :placeholder="$t('common.lastName')"
              data-cy="last-name"
            />
            <!-- <formulate-input
              name="email"
              validation="required|email"
              :validation-name="$t('common.email')"
              :placeholder="$t('common.email')"
              data-cy="last-name"
            /> -->
            <!-- <formulate-input
              name="name"
              validation="required"
              :validation-name="$t('common.fullName')"
              :placeholder="$t('common.fullName')"
              data-cy="first-name"
            /> -->
          </div>
          <app-avatar
            @complete="avatar = $event.file"
            @remove="
              form.avatar = null;
              isAvatarValid = true;
            "
            @validation="isAvatarValid = !$event?.hasErrors || false"
          />
        </div>
        <div class="flex flex-col gap-2.5 mt-8">
          <h4 class="body-text-extra-bold mb-2">Fundraiser information</h4>
          <formulate-input
            name="email"
            validation="required|email"
            :validation-name="$t('common.email')"
            :placeholder="$t('common.email')"
            data-cy="last-name"
          />
          <formulate-input
            @keydown="handleNumberInput"
            type="number"
            name="goal"
            validation="^required|number"
            validation-name="Goal"
            :placeholder="$t('fundraiser.goal')"
            data-cy="goal"
          />
          <formulate-input
            @input.native="resize('textarea')"
            @focusin.native="resize('textarea', true)"
            @focusout.native="resize('textarea', true)"
            maxlength="150"
            ref="textarea"
            name="message"
            validation="required"
            validation-name="Message"
            :placeholder="$t('fundraiser.message')"
            type="textarea"
            :help="textLength"
            data-cy="message"
          />
        </div>
      </div>

      <div v-if="errorMessage" class="error-message" data-cy="error-message">{{ errorMessage }}</div>
    </formulate-form>

    <template #footer>
      <div class="buttons">
        <app-button type="submit" form="fundraiser-form" :disabled="!isFormValid" class="primary flex justify-center items-center gap-1 w-full" data-cy="apply">
          <template v-if="isProcessing">
            <h3>{{ $t('common.wait') }}</h3>
            <app-loader />
          </template>
          <h3 v-else>
            {{ $t('fundraiser.cta') }}
          </h3>
        </app-button>
      </div>
    </template>
  </form-layout>
</template>
