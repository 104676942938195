<script setup>
import { computed, defineProps, defineEmits, ref, watch } from 'vue';

import CardknoxService from '@/services/cardknox';

const props = defineProps({
  ifieldId: {
    type: String,
    required: true,
    validator: (value) => ['card-number', 'cvv', 'ach'].includes(value)
  },
  label: String,
  makeDirty: Boolean
});

const emit = defineEmits(['state-changed', 'ready']);

const cardknox = ref(null);
const ready = ref(false);
const invalid = ref(false);
const error = computed(() => {
  if (empty.value || !touched.value) {
    return '';
  }

  return invalid.value ? 'Invalid card number' : '';
});

const touched = ref(null);
const focused = ref(false);
const empty = ref(true);
const ccIcon = ref(null);

const hiddenFieldName = computed(
  () =>
    ({
      'card-number': 'xCardNum',
      cvv: 'xCVV'
    }[props.ifieldId])
);

const elementClass = computed(
  () =>
    ({
      'card-number': 'cardNumber'
    }[props.ifieldId])
);

watch(
  () => props.makeDirty,
  (val) => {
    if (val) {
      touched.value = true;
    }
  }
);

const createIFields = async () => {
  cardknox.value = await CardknoxService.getCardknoxObject();

  cardknox.value.setIfieldStyle(props.ifieldId, {
    'font-family': '"Nunito", Arial, sans-serif',
    fontWeight: 400,
    lineHeight: '40px',
    color: '#b1b1bf;',
    border: 'none',
    background: 'transparent',
    fontSize: '16px',
    margin: '-1px', // a trick to hide the focus style
    width: '100%',
    fontSmoothing: 'antialiased'
  });
  cardknox.value.enableAutoFormatting();

  cardknox.value.addIfieldCallback('focus', (data) => {
    if (data.triggeredByIfield !== props.ifieldId) {
      return;
    }
    focused.value = true;
  });
  cardknox.value.addIfieldCallback('blur', (data) => {
    if (data.triggeredByIfield !== props.ifieldId || !focused.value) {
      return;
    }

    touched.value = true;
    focused.value = false;
  });

  cardknox.value.addIfieldKeyPressCallback(function (data) {
    if (data.triggeredByIfield !== props.ifieldId) {
      return;
    }

    // todo: refactor
    if (props.ifieldId === 'card-number') {
      invalid.value = !data.cardNumberIsValid;
      empty.value = data.cardNumberIsEmpty;
      ccIcon.value = ccIcons[data.issuer];
      if (data.cardNumberIsValid) {
        emit('state-changed', 'complete');
      }
      if (data.cardNumberIsEmpty) {
        emit('state-changed', 'empty');
      }
      if (!data.cardNumberIsValid) {
        emit('state-changed', 'error');
      }
    } else if (props.ifieldId === 'cvv') {
      invalid.value = !data.cvvIsValid;
      empty.value = data.cvvIsEmpty;

      if (data.cvvIsValid) {
        emit('state-changed', 'complete');
      }

      if (data.cvvIsEmpty) {
        emit('state-changed', 'empty');
      }

      if (!data.cvvIsValid) {
        emit('state-changed', 'error');
      }
    } else if (props.ifieldId === 'ach') {
      invalid.value = !data.achIsValid;
      empty.value = data.achIsEmpty;

      if (data.achIsValid) {
        emit('state-changed', 'complete');
      }

      if (data.achIsEmpty) {
        emit('state-changed', 'empty');
      }

      if (!data.achIsValid) {
        emit('state-changed', 'error');
      }
    }
  });
};

const onIframeLoaded = () => {
  ready.value = true;
  emit('ready', ready.value);
};

const ccIcons = {
  amex: 'https://api.iconify.design/fa6-brands:cc-diners-club.svg',
  diners: 'https://api.iconify.design/fa6-brands:cc-diners-club.svg',
  jcb: 'https://api.iconify.design/fa6-brands:cc-jcb.svg',
  visa: 'https://api.iconify.design/fa6-brands:cc-visa.svg',
  mastercard: 'https://api.iconify.design/fa6-brands:cc-mastercard.svg',
  discover: 'https://api.iconify.design/fa6-brands:cc-discover.svg'
};

createIFields();
</script>

<template>
  <div class="cc-element cardknox-ifield" style="background: transparent !important">
    <content-loader v-show="!ready" />
    <div v-show="ready" class="cc-element-input" :class="{ empty, touched, focused, invalid }">
      <div class="flex items-center">
        <div v-if="ifieldId === 'card-number'" class="cc-icon">
          <img v-if="ccIcon" :src="ccIcon" />
          <ion-icon v-else name="card-outline" class="text-primary"></ion-icon>
        </div>
        <!-- Inline styles based on Stripe's element -->
        <iframe
          v-show="cardknox"
          :data-ifields-id="ifieldId"
          data-ifields-placeholder=""
          src="https://cdn.cardknox.com/ifields/2.15.2405.1601/ifield.htm"
          style="
            border: 0px !important;
            margin: 0px !important;
            padding: 0px !important;
            padding-right: 34px !important;
            width: 1px !important;
            min-width: 100% !important;
            overflow: hidden !important;
            display: block !important;
            user-select: none !important;
            transform: translate(0px) !important;
            color-scheme: light only !important;
            height: 40px;
          "
          @load="onIframeLoaded"
          scrolling="no"
        ></iframe>
        <input :data-ifields-id="`${ifieldId}-token`" :name="hiddenFieldName" type="hidden" />
      </div>
    </div>
    <template v-if="ready">
      <label for="example-card-number" class="label" :class="elementClass" @click="cardknox && cardknox.focusIfield(ifieldId)">{{ label }}</label>

      <ul class="cc-element-input-errors">
        <li class="cc-element-input-error" v-if="error">{{ error }}</li>
        <li class="cc-element-input-error-required" v-else-if="touched">{{ label }} {{ $t('payment.isRequired') }}</li>
      </ul>
    </template>
  </div>
</template>
