<script setup>
import { ref, watch, computed, defineProps, defineEmits } from 'vue';
import {
  validateCardExpiry,
  restrictNumeric,
  formatExpiry,
  formatForwardSlashAndSpace,
  formatForwardExpiry,
  formatBackExpiry,
  reFormatExpiry
} from '@/utils/card-exp-utils.js';

const props = defineProps({
  makeDirty: Boolean
});

const emit = defineEmits(['validate']);

const value = ref('');

const touched = ref(false);
const focused = ref(false);

const invalid = ref(false);

const error = computed(() => {
  if (!value.value.length || !touched.value) {
    return '';
  }

  return invalid.value ? 'Invalid expiry date' : '';
});

watch(value, (val) => {
  const { isValid, value } = validateCardExpiry(val);
  invalid.value = !isValid;

  if (!invalid.value) {
    emit('validate', { value, isValid });
  }
});

watch(
  () => props.makeDirty,
  (val) => {
    if (val) {
      touched.value = true;
    }
  }
);
</script>

<template>
  <div class="cc-element" style="background: transparent !important">
    <div class="cc-element-input" :class="{ empty: !value.length, touched, focused, invalid }">
      <input
        v-model="value"
        type="text"
        class="w-full bg-transparent border-none h-10 outline-none placeholder-gray"
        placeholder="MM / YY"
        @keypress="
          restrictNumeric($event);
          formatExpiry($event);
          formatForwardSlashAndSpace($event);
          formatForwardExpiry($event);
        "
        @keydown="formatBackExpiry($event)"
        @change="reFormatExpiry($event)"
        @input="reFormatExpiry($event)"
        @blur="
          reFormatExpiry($event);
          touched = true;
          focused = false;
        "
      />
    </div>
    <ul class="cc-element-input-errors">
      <li class="cc-element-input-error" v-if="error">{{ error }}</li>
      <li class="cc-element-input-error-required" v-else-if="touched">Card expiry is required</li>
    </ul>
  </div>
</template>
