<script setup>
import { defineProps, ref } from 'vue';

import { useState } from 'vuex-composition-helpers';
import LottieAnimation from 'lottie-web-vue';

import heartData from '@/assets/heart-lottie.json';

defineProps({
  label: String,
  selected: Boolean,
  showHeart: Boolean,
  frequencyPromoted: String
});

const hideHeartSvg = ref(false);

const { frequency } = useState('donation', ['frequency']);
</script>

<template>
  <button class="rounded-lg h-full flex-1 cursor-pointer" :class="[frequency?.value, { selected }]" :selected="selected">
    <h3 :class="{ 'text-primary-text': selected }" class="button-text w-full absolute bold text-center flex items-center justify-center">
      <div v-if="showHeart">
        <lottie-animation v-if="selected" class="lottie" :animation-data="heartData" :loop="0.5" ref="lottie" @complete="hideHeartSvg = false" />
        <svg v-else class="heart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M256,448a32,32,0,0,1-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63,114.52,98.46,64,159.08,64c44.08,0,74.61,24.83,92.39,45.51a6,6,0,0,0,9.06,0C278.31,88.81,308.84,64,352.92,64,413.54,64,463.37,114.52,464,176.64c.54,54.21-18.63,104.26-58.61,153-18.77,22.87-52.8,59.45-131.39,112.8A32,32,0,0,1,256,448Z"
          />
        </svg>
      </div>
      {{ label }}
    </h3>
  </button>
</template>
