<script>
import { mapState } from 'vuex';

export default {
  name: 'StandWithIsraelFooter',
  data: () => ({
    orgs: [
      {
        id: '4855a760-69c9-11ee-998c-45fcef47a03b',
        name: 'Daily Giving',
        taxId: '83-0829905',
        url: 'https://dailygiving.org'
      },
      {
        id: '5d936520-6c23-11ee-998c-45fcef47a03b',
        name: 'United Hatzalah',
        taxId: '11-3533002',
        url: 'https://israelrescue.org'
      }
    ]
  }),
  computed: {
    ...mapState(['orgId']),
    org() {
      const org = this.orgs.find((org) => org.id === this.orgId);
      if (!org) return this.orgs[0];
      return org;
    }
  }
};
</script>

<template>
  <p class="stand-with-israel-footer">
    Funds are distributed to Israel emergency organizations through <a :href="org.url" target="_blank">{{ org.name }}</a> Tax ID: {{ org.taxId }}
  </p>
</template>
