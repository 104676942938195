<script>
// Class options list:
// flex-row | selected | primary | focused | disabled | inactive | no-border | col-2 | col-3

export default {
  name: 'AppButton',
  props: {
    selected: Boolean,
    focused: Boolean,
    disabled: Boolean
  },
  methods: {}
};
</script>

<template>
  <button v-bind="$attrs" v-on="$listeners" class="p-2.5 app-button" :class="{ selected, focused, disabled }">
    <slot />
  </button>
</template>
