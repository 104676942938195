<script>
import dayjs from 'dayjs';
import AnimatedNumber from 'animated-number-vue';
import { mapMutations, mapState } from 'vuex';

import { getCurrencySymbol, widgetViews } from '@/utils';
import AppProgressbar from '@/common/AppProgressbar.vue';

export default {
  name: 'GoalMeter',
  components: {
    AnimatedNumber,
    AppProgressbar
  },
  data() {
    return {
      progressDuration: 1400,
      widgetViews
    };
  },
  computed: {
    ...mapState('donation', ['orgDefaultCurrency']),
    ...mapState('campaign', ['id', 'settings', 'chargeTotal', 'donorCount']),
    goalMeter() {
      return this.settings.goalMeter;
    },
    targetTotal() {
      if (this.goalMeter.type === 'donors') {
        return this.donorCount;
      }

      if (this.goalMeter.type === 'units') {
        return this.fulfilledUnits;
      }

      return this.chargeTotal;
    },

    fulfilledUnits() {
      return Math.floor(this.chargeTotal / this.goalMeter.unitCost);
    },

    numOfDaysLeft() {
      const endDate = this.goalMeter.endDate;
      const daysLeft = dayjs(dayjs(endDate).toISOString()).diff(dayjs(), 'days');

      return daysLeft;
    },
    progressPercentage() {
      if (this.goalMeter.type === 'donors') {
        return (100 / this.goalMeter.donorsTarget) * this.donorCount;
      }

      if (this.goalMeter.type === 'units') {
        return (100 / this.goalMeter.unitsTarget) * this.fulfilledUnits;
      }

      const percentage = (100 / this.goalMeter.amountTarget) * this.chargeTotal;
      return Math.min(percentage, 100);
    },
    currencySymbol() {
      const symbol = getCurrencySymbol(this.orgDefaultCurrency);
      return symbol;
    }
  },
  methods: {
    ...mapMutations(['SET_WIDGET_VIEW']),
    formatAnimatedNumber(value) {
      return value.toLocaleString();
    }
  },

  mounted() {
    setTimeout(() => {
      this.progressDuration = 500;
    }, 3000);
  }
};
</script>

<template>
  <section class="goal-meter my-4">
    <div class="stats">
      <div class="main">
        <div class="goal-meter-info raised">
          <div
            @click="settings.showDonorWall && goalMeter.type === 'donors' ? SET_WIDGET_VIEW(widgetViews.DONOR_WALL) : null"
            :class="{ 'cursor-pointer underline': settings.showDonorWall && goalMeter.type === 'donors' }"
            class="body-text-extra-bold"
          >
            <span v-if="goalMeter.type === 'amount'">{{ currencySymbol }}</span>
            <span class="font-bold font-nunito">
              <animated-number :value="targetTotal" :duration="progressDuration" :round="true" :formatValue="formatAnimatedNumber" />
            </span>
            <span class="font-nunito-sans font-medium">
              <template v-if="goalMeter.type === 'amount'"> {{ $t('goalMeter.raised') }}</template>
              <template v-if="goalMeter.type === 'donors'"> {{ $t('goalMeter.donors') }}</template>
              <template v-if="goalMeter.type === 'units'"> {{ goalMeter.unitName }}</template>
            </span>
          </div>

          <div class="flex gap-2 items-center subtext-regular">
            <div>
              <template v-if="goalMeter.type === 'amount'">
                <span> {{ $t('goalMeter.outOf') }} {{ currencySymbol }}</span>
                <span class="font-bold">{{ goalMeter.amountTarget | number }}</span>
              </template>
              <template v-if="goalMeter.type === 'donors'">
                <span> {{ $t('goalMeter.outOf') }}</span>
                <span class="font-bold"> {{ goalMeter.donorsTarget | number }} {{ $t('goalMeter.donors') }}</span>
              </template>
              <template v-if="goalMeter.type === 'units'">
                <span> {{ $t('goalMeter.outOf') }}</span>
                <span class="font-bold"> {{ goalMeter.unitsTarget | number }} {{ goalMeter.unitName }}</span>
              </template>
            </div>
            <div v-if="goalMeter.endDate" class="vl"></div>
            <div v-if="goalMeter.endDate">
              <span>{{ Math.max(numOfDaysLeft, 0) }}</span> days left
            </div>

            <template v-if="goalMeter.type === 'amount'">
              <div class="vl"></div>
              <span
                @click="settings.showDonorWall ? SET_WIDGET_VIEW(widgetViews.DONOR_WALL) : null"
                :class="{ 'underline cursor-pointer': settings.showDonorWall }"
              >
                <animated-number :value="donorCount" :duration="1400" :round="true" :formatValue="formatAnimatedNumber" />
                {{ $t('goalMeter.donors') }}
              </span>
            </template>
            <template v-if="goalMeter.type === 'units'">
              <div class="vl"></div>
              <span>
                <span>{{ currencySymbol }}</span>
                <animated-number :value="chargeTotal" :duration="1400" :round="true" :formatValue="formatAnimatedNumber" />
                {{ $t('goalMeter.raised') }}
              </span>
            </template>
          </div>
        </div>
      </div>
      <div class="progress-bar">
        <app-progressbar :value="progressPercentage" />
      </div>
    </div>
  </section>
</template>
