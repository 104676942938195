<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('donation', ['frequency']),
    frequencyFormatted() {
      const frequencies = {
        weekly: this.$t('payment.week'),
        monthly: this.$t('payment.month'),
        yearly: this.$t('payment.year')
      };
      return frequencies[this.frequency];
    }
  }
};
</script>

<script setup>
import { defineProps } from 'vue';
import { useGetters } from 'vuex-composition-helpers';

defineProps({
  amount: {
    type: Number,
    default: 0
  },
  showFrequency: {
    // Will show selected frequency if true && isRecurring
    type: Boolean,
    default: false
  },
  showFrequencySuffix: {
    type: Boolean,
    default: false
  }
});

const { currencySymbol, isRecurring } = useGetters('donation', ['currencySymbol', 'isRecurring']);
</script>

<template>
  <span class="font-nunito">
    <span class="font-normal">{{ currencySymbol }}</span>
    <span class="font-bold">{{ amount | currency }}</span>
    <template v-if="isRecurring">
      <span v-if="showFrequencySuffix"> {{ $t(`frequency.${frequency}`) }}</span>
      <span v-else-if="showFrequency && !showFrequencySuffix" style="font-size: 0.8em">{{ ' ' }}/{{ frequencyFormatted }}</span>
    </template>
  </span>
</template>
