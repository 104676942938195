import { computed } from 'vue';
import store from '@/store';
import { GATEWAYS } from '@/utils';

export const availableGateways = computed(() => store?.state.publicOrgSettings?.gateways);
export const currencies = computed(() => {
  const gateways = availableGateways.value || [];

  if (gateways.some((gateway) => gateway.provider === GATEWAYS.STRIPE)) {
    return [
      { value: 'usd', label: 'USD' },
      { value: 'eur', label: 'EUR' },
      { value: 'ils', label: 'ILS' },
      { value: 'gbp', label: 'GBP' },
      { value: 'cad', label: 'CAD' },
      { value: 'aud', label: 'AUD' }
    ];
  }

  const hasGrow = gateways.some((gateway) => gateway.provider === GATEWAYS.GROW);
  const hasCardknox = gateways.some((gateway) => gateway.provider === GATEWAYS.CARDKNOX);

  if (hasGrow && !hasCardknox) {
    return [{ value: 'ils', label: 'ILS' }];
  }

  if (hasCardknox && !hasGrow) {
    return [{ value: 'usd', label: 'USD' }];
  }

  if (hasCardknox && hasGrow) {
    return [
      { value: 'usd', label: 'USD' },
      { value: 'ils', label: 'ILS' }
    ];
  }

  throw new Error('Invalid gateways');
});
