<script setup>
import { defineProps } from 'vue';
import { useMutations } from 'vuex-composition-helpers';

import { widgetViews } from '@/utils';

defineProps({
  hideHeader: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  staticForm: {
    type: Boolean,
    default: false
  }
});

const { SET_WIDGET_VIEW } = useMutations(['SET_WIDGET_VIEW', 'SET_PAGE_TRANSITION']);
</script>

<template>
  <div class="h-full flex flex-col flex-1 w-full">
    <div class="form-layout flex-1">
      <div class="px-6 my-6">
        <div @click="SET_WIDGET_VIEW(widgetViews.DONATION_FORM)" v-if="title" class="form-title flex gap-1 items-center cursor-pointer">
          <ion-icon name="chevron-back-outline" height="10px" />
          <h3 class="bold">
            {{ title }}
          </h3>
        </div>
        <slot />
      </div>
    </div>
    <div class="px-6">
      <slot name="footer" />
      <double-logo v-if="!staticForm" :is-link="true" theme="grayscale" class="my-5 text-center" />
    </div>
  </div>
</template>
