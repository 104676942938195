export default function (dtd) {
  if (!dtd?.enabled || !dtd?.publicKey) {
    return;
  }
  const iframe = document.querySelector('iframe[name="double-checkout"]');
  const iframeHead = iframe.contentWindow.document.head;
  // load javascript
  const script = document.createElement('script');
  script.src = 'https://doublethedonation.com/api/js/ddplugin.js';
  iframeHead.appendChild(script);

  window.DDCONF = { API_KEY: dtd?.publicKey };

  // load css (seems unnecessary as the script loads the css :( )
  const link = document.createElement('link');
  link.src = 'https://doublethedonation.com/api/css/ddplugin.css';
  link.rel = 'stylesheet';
  iframeHead.appendChild(link);
}
