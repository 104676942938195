import { mapState, mapMutations, mapActions } from 'vuex';

const BAER_MIRYAM_ORGID = '9b8b2390-70fc-11ed-8adb-a1f40ae636e4';

export default {
  computed: {
    ...mapState(['orgId'])
  },
  methods: {
    ...mapActions(['setWidgetOpen']),
    ...mapMutations('donation', ['SET_FREQUENCY']),
    handleCustomOrgAdjustments() {
      if (this.orgId === BAER_MIRYAM_ORGID) {
        setTimeout(() => {
          const donateButton = document.querySelector('[aria-label="Donate Now"]');
          if (!donateButton) {
            return; // button not on all pages
          }
          donateButton.addEventListener('click', () => {
            this.setWidgetOpen(true);
            this.SET_FREQUENCY('monthly');
          });
        }, 100);
      }
    }
  }
};
