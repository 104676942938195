import { computed } from 'vue';
import store from '@/store';
import { GATEWAYS } from '@/utils';

const gateway = computed(() => store.state.donation.CCGateway);
export default computed(() => {
  if (gateway.value === GATEWAYS.CARDKNOX) {
    return [{ value: 'usd', label: 'USD' }];
  }

  return [
    { value: 'usd', label: 'USD' },
    { value: 'eur', label: 'EUR' },
    { value: 'gbp', label: 'GBP' },
    { value: 'cad', label: 'CAD' },
    { value: 'aud', label: 'AUD' }
  ];
});
