<script>
import svgList from '@/assets/svg-list';

export default {
  name: 'AppIcon',
  props: {
    src: String,
    name: String,
    width: String,
    height: String,
    viewBox: String,
    innerStyle: String,
    type: {
      type: String,
      validator(value) {
        return ['img', 'svg', 'font'].includes(value);
      }
    }
  },
  computed: {
    svg() {
      return svgList[this.name] || {};
    }
  }
};
</script>

<template>
  <span class="app-icon" :class="type + '-icon'">
    <ion-icon v-if="type === 'font'" :class="name" :name="name"></ion-icon>
    <img v-if="type === 'img'" :src="require('@/' + src)" />
    <svg
      v-if="type === 'svg'"
      v-html="svg.content"
      xmlns="http://www.w3.org/2000/svg"
      :width="width || svg.width"
      :height="height || svg.height"
      :viewBox="svg.viewBox"
      :style="innerStyle"
    />
  </span>
</template>
