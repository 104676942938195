import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import { widgetViews, DEFAULT_CAMPAIGN_SLUG, unparam, sleep, removeCampaignFromUrl, setCampaignInUrl } from '@/utils';
import { getSentryScope } from '@/services/sentry';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  state: {
    orgId: null,
    isWidgetOpen: false,
    widgetView: widgetViews.DONATION_FORM,
    slideDirection: '', // Don't transition on first load
    chariotLoaded: false,
    dtdInfo: {},
    successfullyDonated: false,
    telLibraryLoaded: false,
    datePickerLibraryLoaded: false
  },
  mutations: {
    SET_ORG_ID(state, value) {
      state.orgId = value;
    },
    SET_WIDGET_VIEW(state, view) {
      if (!Object.values(widgetViews).includes(view)) {
        throw new Error(`Unknown widget view: ${view}`);
      }
      const slideDirection = view.level > state.widgetView.level ? 'slide-right' : 'slide-left';
      state.slideDirection = slideDirection;
      state.widgetView = view;
    },

    SET_WIDGET_OPEN(state, value) {
      state.isWidgetOpen = value;
    },

    SET_SUCCESSFULLY_DONATED(state, value) {
      state.successfullyDonated = value;
    },
    SET_CHARIOT_LOADED(state, newValue) {
      state.chariotLoaded = newValue;
    },
    SET_DTD_INFO(state, newValue) {
      state.dtdInfo = newValue;
    },
    SET_TEL_LIBRARY_LOADED(state, newValue) {
      state.telLibraryLoaded = newValue;
    },
    SET_DATE_PICKER_LIBRARY_LOADED(state, newValue) {
      state.datePickerLibraryLoaded = newValue;
    }
  },
  actions: {
    async setWidgetOpen({ commit, dispatch }, value) {
      commit('SET_WIDGET_OPEN', value);
      const { campaign } = unparam(window.location.search);
      if (!campaign) {
        setCampaignInUrl(DEFAULT_CAMPAIGN_SLUG, value);
      }

      const campaignSlug = campaign || DEFAULT_CAMPAIGN_SLUG;

      if (value) {
        const campaign = await dispatch('campaign/fetchCampaign', { campaignSlug });
        commit('donation/SET_CAMPAIGN_ID', campaign.id);
      } else {
        await sleep(500);
        commit('SET_WIDGET_VIEW', widgetViews.DONATION_FORM);
        commit('campaign/RESET_CAMPAIGN');
        commit('SET_CHARIOT_LOADED', false);
        removeCampaignFromUrl();
      }
    }
  },
  plugins: [
    (store) => {
      const sentryScope = getSentryScope();
      store.subscribe((mutation) => {
        sentryScope.addBreadcrumb({
          category: 'mutation',
          message: mutation.type,
          data: {
            payload: mutation.payload
          }
        });
      });
      store.subscribeAction((action) => {
        sentryScope.addBreadcrumb({
          category: 'action',
          message: action.type,
          data: {
            payload: action.payload
          }
        });
      });
    }
  ]
});

export default store;
